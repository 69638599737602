import React from 'react'
import {Dialog, DialogContent, DialogTitle} from '@material-ui/core'
import CloseIcon from "@material-ui/icons/Close";

const PrivacyDialog = (props) => {
  const { open, setOpen } = props
  return (
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="lg"
        onClose={() => {}}
        aria-labelledby="privacy-dialog">
        <div className="container-fluid pt-3 pb-5" style={{ textAlign: 'center' }}>
          <DialogTitle disableTypography className="dialog-title">
            <CloseIcon className="close-dialog-icon" onClick={() => setOpen(null)}/>
          </DialogTitle>
          <DialogContent>
            <h4 className="game-rules-title mb-4">POLÍTICA DE PRIVACIDAD</h4>
            <p><strong>1. Derecho de información</strong></p>
            <p>La política de privacidad de Euromedice, Ediciones Médicas, S.L. se basa, entre otras normas, en lo dispuesto por el Reglamento (UE) 2016/679 relativo a la protección de los datos personales de las personas físicas (RGPD) y regula el uso de los servicios desarrollados por nuestra compañía.</p>
            <p><strong>2. ¿Quién es el responsable del tratamiento de los datos personales?</strong></p>
            <p>Euromedice, Ediciones Médicas, S.L. Passeig de Gràcia 101; 1-3. 08008 Barcelona</p>
            <p>Correo electrónico: euromedice@euromedice.net</p>
            <p><strong>3. ¿Con qué finalidad trataremos los datos personales?</strong></p>
            <p>Trataremos los datos personales de los usuarios de nuestras aplicaciones con la finalidad de atender las solicitudes de información y/o consultas realizadas a través de la aplicación, tramitar la inscripción y posibilitar la funcionabilidad de la aplicación, remitir comunicaciones sobre servicios y eventos relacionados con nuestra actividad, salvo que se indique lo contrario o se oponga o revoque el consentimiento.</p>
            <p><strong>4. ¿Cuánto tiempo conservaremos los datos personales?</strong></p>
            <p>Conforme a la normativa vigente, los datos personales se conservarán durante un período de seis (6) años y, si fuera necesario, durante diez (10) años según la normativa de prevención de blanqueo de capitales.</p>
            <p>En cualquier caso, Euromedice, Ediciones Médicas, S.L. conservará los datos personales mientras sean necesarios para la prestación o relación contractual, a menos que nos soliciten la supresión. De igual modo, se conservarán los datos personales durante el período de tiempo necesario para dar cumplimiento a las obligaciones legales que en cada caso corresponda según la tipología de los datos.</p>
            <p><strong>5. ¿Cuál es la legitimación para el tratamiento de los datos personales?</strong></p>
            <p>La base legal para el tratamiento de datos es el interés mutuo y el consentimiento del usuario.</p>
            <p><strong>6. ¿A qué destinatarios se comunicarán los datos personales?</strong></p>
            <p>Los datos personales únicamente podrán comunicarse a organizaciones del grupo o vinculadas a Euromedice, Ediciones Médicas, S.L. en el ámbito de la gestión de sus servicios, así como a aquellas Administraciones Públicas competentes cuando lo exija la normativa vigente.</p>
            <p>Los empleados de Euromedice, Ediciones Médicas, S.L. que tienen derechos de acceso autorizado de acuerdo con la estructura de seguridad interna de la empresa podrán acceder a los datos personales con las finalidades descritas en esta política de protección de datos. Todos los empleados de Euromedice, Ediciones Médicas, S.L. han sido formados e informados de sus responsabilidades al respecto y tienen firmado un acuerdo de confidencialidad. Igualmente, pueden acceder a los datos personales organizaciones terceras cuya intervención es requerida y necesaria para la gestión adecuada de la prestación del servicio.</p>
            <p>Euromedice, Ediciones Médicas, S.L. ha establecido contrato o acuerdos contractuales y medidas de seguridad con terceros para garantizar el adecuado nivel de seguridad y de protección de los datos personales.</p>
            <p><strong>7. ¿Cuáles son tus derechos en relación con los datos personales?</strong></p>
            <p>Ante Euromedice, Ediciones Médicas, S.L. puedes ejercer tus derechos de acceso, rectificación, supresión, limitación del tratamiento, portabilidad de los datos, oposición y a no ser objeto de decisiones individuales automatizadas, incluida la elaboración de perfiles. De igual modo, en los tratamientos de los datos cuya legitimación se base en tu consentimiento tienes el derecho a retirar dicho consentimiento en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.</p>
            <p><strong>8. ¿Cuál es la información personal almacenada?</strong></p>
            <p>Se guardan los datos básicos de identificación y relación para el envío de propuestas, información o creación de facturas, como por ejemplo nombre y apellidos, domicilio, NIF/CIF o número de cuenta.</p>
            <p><strong>9. ¿Cuál es la política de almacenamiento de datos/disponibilidad/ backups y su ubicación?</strong></p>
            <p>No se elimina información a menos que sea solicitada la supresión por parte del usuario y que esta supresión sea conforme (ver punto 3). Siempre está disponible, a pesar de que pueda estar bloqueada para envíos informativos, de marketing, comercial si se han ejercitado los derechos de supresión, limitación al tratamiento o bien oposición por parte del usuario.</p>
            <p>Se realizan copias de seguridad de los servidores que contienen datos, debidamente controlados y custodiados.</p>
            <p><strong>10. ¿Cuál es la política de privacidad y seguridad de la información y su acceso?</strong></p>
            <p>El acceso a la base de datos está protegido mediante usuario y contraseña.</p>
            <p>En el caso de acceso remoto autorizado, este se realiza mediante protocolo VPN.</p>
            <p><strong>11. ¿Cuál es la política de respuesta frente incidentes de seguridad y análisis de la cuenta?</strong></p>
            <p>Euromedice, Ediciones Médicas, S.L. ha adoptado medidas técnicas y organizativas de protección apropiadas y estas medidas se han aplicado a los datos afectados por la potencial violación de la seguridad de los datos personales. No es posible el acceso a datos personales a las personas que no estén autorizadas para acceder a ellos. Euromedice, Ediciones Médicas, S.L. ha realizado y mantenido un análisis de los riesgos de vulnerabilidad de los datos personales y de su impacto, si fuera el caso, sobre la seguridad y las intimidades de los datos de los usuarios.</p>
            <p><strong>12. ¿Cuál es la política de borrado frente bajas del servicio?</strong></p>
            <p>Euromedice, Ediciones Médicas, S.L. desactiva en sus bases de datos de envíos de información las cuentas de usuarios que deciden darse de baja y que hayan ejercido su derecho de supresión. En este caso, los datos del usuario quedarán bloqueados y mantenidos el tiempo requerido por normativa y se utilizarán exclusivamente para el cumplimiento de las obligaciones legales.</p>
            <p><strong>13. ¿Quién es nuestro responsable de seguridad en materia de protección de datos?</strong></p>
            <p>De acuerdo a la legislación vigente, Euromedice, Ediciones Médicas, S.L. no tiene obligatoriedad legal de designar un DPD (delegado de protección de datos).</p>
            <p>Independientemente, Euromedice, Ediciones Médicas, S.L. ha designado una persona responsable de seguridad de los datos para que vele por el correcto funcionamiento de esta política de privacidad y para asegurar que se cumplen todos los requisitos en relación con los datos personales de usuarios internos y externos a la organización.</p>
            <p>Puedes contactar con nuestro responsable de seguridad de datos en euromedice@euromedice.net.</p>
            <p>En caso de que Euromedice, Ediciones Médicas, S.L. identifique una violación de seguridad de datos personales que comprometa a uno o más de sus usuarios, estos serán notificados con la mayor brevedad posible al respecto. Si el riesgo y vulneración fuesen importantes, se notificaría igualmente a la autoridad competente.</p>
            <p><strong>14. Consentimiento del usuario</strong></p>
            <p>El envío de los datos personales mediante el uso de formularios electrónicos o en papel de Euromedice, Ediciones Médicas, S.L. o, en su caso, mensajes de correo electrónico u otro medio análogo supone el consentimiento expreso del usuario al tratamiento de sus datos personales incluidos en los medios de comunicación relacionados con Euromedice, Ediciones Médicas, S.L. No obstante, podrás revocar el presente consentimiento en cualquier momento a través de los derechos descritos anteriormente en el punto 7 de la presente política de privacidad y protección de datos.</p>
            <p><strong>15. Política de privacidad del patrocinador</strong></p>
            <a href="https://www.astellas.com/es/politica-de-privacidad" rel="noreferrer" target="_blank">https://www.astellas.com/es/politica-de-privacidad</a><br/>
            <button className="own-button inside-button mt-4" onClick={() => setOpen(null)}>
              CERRAR
            </button>
          </DialogContent>
        </div>
      </Dialog>
  )
}
export default PrivacyDialog
