/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import logoAstellas from '../assets/logo-astellas.png'
//import logoCFC from '../assets/Acred.svg'
import CopyrightIcon from '@material-ui/icons/Copyright'
import { useLocation, useHistory } from 'react-router-dom'
import PrivacyDialog from "./PrivacyDialog";
import CookiesDialog from "./CookiesDialog";
import SupportDialog from "./SupportDialog";
import ConditionsDialog from "./ConditionsDialog";

export default function Footer() {
  const location = useLocation()
  const [openDialog, setOpenDialog] = useState(null)
  const history = useHistory()

  return (
    <>
      <footer className="footer mt-auto px-3 pb-1">
        {!location.pathname.includes('/error') && <div className="container-fluid">
          <div className='row align-items-center justify-content-between'>
          {!location.pathname.includes('/casos') && <img src={logoAstellas} className="img-fluid" style={{maxWidth: '180px'}} alt="Astellas" />}
          {/*!location.pathname.includes('/casos') && <img src={logoCFC} className="img-fluid col" style={{maxWidth: '220px'}} alt="CFC" />*/}
          </div>
          <div className='row align-items-center'>
          <div className="privacy-footer mt-3 col">
            <p className="footer-text"><CopyrightIcon style={{ fontSize: 'x-small' }}/>
              {(new Date().getFullYear()) + ' EUROMEDICE. Todos los derechos reservados. '}<a onClick={() => setOpenDialog(1)}>Política de privacidad</a> ·
              <a onClick={() => setOpenDialog(2)}> Política de cookies</a> ·
              <a onClick={() => setOpenDialog(3)}> Condiciones de uso</a> ·
              <a onClick={() => setOpenDialog(4)}> Soporte técnico</a> ·
              <a onClick={() => history.push('/consultas')}> Consultas al tutor</a>
            </p>
          </div>
        </div>
        </div>}
      </footer>
      <PrivacyDialog
        open={openDialog === 1}
        setOpen={setOpenDialog}
      />
      <CookiesDialog
        open={openDialog === 2}
        setOpen={setOpenDialog}
      />
      <ConditionsDialog
        open={openDialog === 3}
        setOpen={setOpenDialog}
      />
      <SupportDialog
        open={openDialog === 4}
        setOpen={setOpenDialog}
      />
    </>
  )
}
