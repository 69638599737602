import React, { useState, useEffect } from 'react'
import bg from '../../assets/bg-game-container-1.png'
import trueUnchecked from '../../assets/V_2.png'
import trueChecked from '../../assets/V_1.png'
import falseUnchecked from '../../assets/F_2.png'
import falseChecked from '../../assets/F_1.png'
import Timer from '../../components/Timer'
import TrueOrFalseAllAtOnceAnswerDialog from '../../components/TrueOrFalseAllAtOnceAnswerDialog'
import NotAllAnswersSelectedDialog from '../../components/NotAllAnswersSelectedDialog'

export default function TrueOrFalseContainerAllAtOnce(props) {
  const {
    game,
    handleNext,
    points,
    setPoints
  } = props
  const [selectedAnswers, setSelectedAnswers] = useState([])
  const [attempt, setAttempt] = useState(0)
  const [answers, setAnswers] = useState(null)
  const [errors, setErrors] = useState(null)
  const [seconds, setSeconds] = useState(null)
  const [openAnswerDialog, setOpenAnswerDialog] = useState(false)
  const [openNotAllSelected, setOpenNotAllSelected] = useState(false)
  const [noTimeLeft, setNoTimeLeft] = useState(false)

  useEffect(() => {
    if (!game || !game.answers || !game.answers.length) return
    const options = game._id === '64ad42e55771115194fb6ea9' ? game.answers : 
      game.answers
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)
    setAnswers(options)
    setAttempt(0)
    setSelectedAnswers([])
  }, [game])

  useEffect(() => {
    if (!game.timer || seconds === 0) return
    if (!seconds) setSeconds(game.seconds)
    setTimeout(() => {
      if (!seconds) setSeconds(game.seconds - 1)
      else setSeconds(seconds - 1)
    }, 1000);
  })

  useEffect(() => {
    if (seconds === 0) setNoTimeLeft(true)
  }, [seconds])

  function handleClick(answer, solution) {
    let _answers = [...selectedAnswers]
    const index = _answers.findIndex(item => item.text === answer.text)
    if (index >= 0) {
      _answers[index] = {...answer, checked: solution}
      setSelectedAnswers(_answers)
    }
    else setSelectedAnswers(selectedAnswers.concat({...answer, checked: solution}))
  }

  function handleButton() {
    let _attempt = attempt + 1
    setAttempt(_attempt)
    setOpenAnswerDialog(true)
    let _errors = 0
    selectedAnswers.forEach(answer => {
      if ((answer.checked && answer.solution === "incorrecta") || (!answer.checked && answer.solution === "correcta")) _errors += 1
    })
    let _points = points
    if (_errors === 0 && !noTimeLeft) {
      _points += _attempt > 1 ? game.totalPoints?.secondTry : game.totalPoints?.firstTry
      setPoints(_points)
    }
    setErrors(_errors)
  }

  function handleNextButton() {
    if ((game.maxAttempts && game.attempts <= attempt) || errors === 0 || noTimeLeft) {
      handleNext()
    } else {
      setSelectedAnswers([])
    }
  }

  if (!game) return <div/>
  return (
    <div className="game-container container-fluid py-5" style={{ background: `url(${bg}) no-repeat`, backgroundSize: 'cover'}} >
      <div className="intermediate-box container p-4">
        {game.timer && seconds !== null &&
            <div className="col-2 offset-5">
              <Timer seconds={seconds} trueOrFalse={true}/>
            </div>}
        {noTimeLeft && <h2 style={{ color: '#EF160C' }}>{'¡Se acabó el tiempo!'}</h2>}
        {noTimeLeft && <h4 style={{ color: 'black' }}>{'He aquí la justificación de cada afirmación:'}</h4>}
        {game.question !== '' && <h2>{game.question}</h2>}
        {game.description !== '' && <div dangerouslySetInnerHTML={{ __html: game.description }} />}
        <div className="row mt-1">
          {answers ? answers.map((item, index) => (<>
            <div key={index} className={"col-12 py-2 d-inline-flex align-items-center"}>
              <button key={'button-' + index} className={"true-or-false-answer"} style={noTimeLeft ? { border: item.solution === 'correcta' ? '1px solid #49d489' : '1px solid #EF160C'} : {}}>
                <div dangerouslySetInnerHTML={{ __html: item.text }} />
              </button>
              {noTimeLeft ? 
                <img className="true-false-icon mx-2" src={item.solution === 'correcta' ? trueChecked : falseChecked} alt="icon-true-false"/>
              : <>
                <img className="true-false-icon mx-2" src={selectedAnswers.find(answer => answer.text === item.text && answer.checked) ? trueChecked : trueUnchecked} 
                        onClick={() => handleClick(item, true)} alt="icon-true"/>
                <img className="true-false-icon" src={selectedAnswers.find(answer => answer.text === item.text && !answer.checked) ? falseChecked : falseUnchecked} 
                        onClick={() => !noTimeLeft && handleClick(item, false)} alt="icon-false"/>
              </>}
            </div>
            {noTimeLeft && <div key={'justification' + index} className={"col-12 py-2 d-inline-flex align-items-center"}>
              <div dangerouslySetInnerHTML={{ __html: item.justification }} />
            </div>}
            </>
          )) : <></>}
          <div className="row mt-4">
            <div className="col-12">
              <button className="own-button inside-button" 
              onClick={() => noTimeLeft ? handleNextButton() : (answers?.length === selectedAnswers.length ? handleButton() 
                : setOpenNotAllSelected(true))}>
                CONFIRMAR
              </button>
            </div>
          </div>
        </div> 
      </div>
      <TrueOrFalseAllAtOnceAnswerDialog
        open={openAnswerDialog}
        onClose={() => {
          setOpenAnswerDialog(false)
          handleNextButton()
        }}
        answers={answers}
        errors={errors}
        firstAttempt={attempt === 1}
        points={attempt > 1 ? game.totalPoints?.secondTry : game.totalPoints?.firstTry}
        attempt={attempt}
        maxAttempts={game && game.maxAttempts && game.attempts ? game.attempts : null}
        id={game._id}
      />
      <NotAllAnswersSelectedDialog
        open={openNotAllSelected}
        onClose={() => setOpenNotAllSelected(false)}
      />
    </div>
  )
}
