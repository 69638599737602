import { API, authClient } from '../../utils/index'

// Get all questions fmc of specified clinical case
export const getQuestionsFMC = (clinicalCaseId) => {
    return authClient().get(`${API}/fmc/all/${clinicalCaseId}`)
}

// Get question fmc by id
export const getQuestionFMCById = (id) => {
    return authClient().get(`${API}/fmc/${id}`)
}

// Update question fmc
export const updateQuestionFMC = async (id, question) => {
    return authClient().put(`${API}/fmc/${id}`, question)
}

// Save question fmc
export const postQuestionFMC = async (question) => {
    return authClient().post(`${API}/fmc`, question)
}

// Delete question fmc
export const deleteQuestionFMC = (id) => {
    return authClient().delete(`${API}/fmc/${id}`)
}

