import React, { useState } from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import WrongAnswer from "../assets/wrong-answer.png"
import CorrectAnswer from "../assets/correct-answer.png"
import { checkClinicalCaseCode } from "../../api/clinicalCases/index"

const CheckCodeDialog = (props) => {
  const { open, setOpen, clinicalCaseId, /*clinicalCaseCode,*/ onClose } = props
  const [loading, setLoading] = useState(false)
  const [firstCheck, setFirstCheck] = useState(true)
  const [correctCode, setCorrectCode] = useState(false)

  const handleConfirm = async (event) => {
    event.preventDefault()
    setLoading(true)
    const formData = new FormData(event.target)
    const jsonData = Object.fromEntries(formData.entries())
    try {
      setFirstCheck(false)
      const data = await checkClinicalCaseCode(clinicalCaseId, jsonData)
      if (data.status === 200) {
        setCorrectCode(true)
        setLoading(false)
        setTimeout(() => onClose(), 2000)
      }
    } catch (err) {
      setCorrectCode(false)
      setLoading(false)
    }
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      onClose={() => setOpen(false)}
      aria-labelledby="info-dialog">
      <div className="container-fluid pt-3 pb-5 text-center">
        <DialogContent>
          <h4 className="mb-4">Introduzca código</h4>
          <form onSubmit={handleConfirm}>
            <div className="mb-2"  style={{ maxWidth: '100%' }}>
              <input type="text" id="code" name="code" placeholder={'********************'} required />
            </div>
            {!correctCode && <div className="text-center">
              <button type="submit" className="own-button inside-button my-4" disabled={loading}>
                <span className="align-middle">CONFIRMAR</span>
              </button>
            </div>}
          </form>
          {/*(!loading || !correctCode) && <h4>{"Código caso: " + clinicalCaseCode}</h4>*/}
          {correctCode ? 
            <>
              <img className="code-icon my-2"
                src={CorrectAnswer}
                alt={'code-icon'} />
              <h4 style={{ color: '#49d489' }}>Código correcto</h4>
            </>
          : !firstCheck ?
            <>
              <img className="code-icon my-2"
                src={WrongAnswer}
                alt={'code-icon'} />
              <h4 style={{ color: '#EF160C' }}>Código incorrecto</h4>
            </>
          : <></>}
        </DialogContent>
      </div>
    </Dialog>
  )
}
export default CheckCodeDialog
