import {API, authClient} from '../../utils/index'
import {uploadFilesGetLinks} from "../files"

// Get all games of specified scenario
export const getGames = (scenarioId) => {
    return authClient().get(`${API}/games/all/${scenarioId}`)
}

// Get all game types
export const getGameTypes = () => {
    return authClient().get(`${API}/game-types`)
}

// Get game by id
export const getGameById = (id) => {
    return authClient().get(`${API}/games/${id}`)
}

// Update game
export const updateGame = async (id, game, files) => {
    if (files) {
        game.filesURLs = await uploadFilesGetLinks(files, 'games')
    }
    return authClient().put(`${API}/games/${id}`, game)
}

// Save game
export const postGame = async (game, files) => {
    if (files) {
        game.filesURLs = await uploadFilesGetLinks(files, 'games')
    }
    return authClient().post(`${API}/games`, game)
}

// Delete game
export const deleteGame = (id) => {
    return authClient().delete(`${API}/games/${id}`)
}

