import React from 'react'
import { Dialog } from '@material-ui/core'
import pausePlay from '../assets/pause.png'
import Timer from './Timer'

export default function PauseDialog(props) {
  const { open, setOpen, seconds } = props
  return (
    <Dialog
      open={open}
      fullScreen
      onClose={() => {}}
      aria-labelledby="pause-dialog">
      <div className="container">
        <div className="row">
          <div className="col-2 offset-5">
            <Timer pauseDialog={true} seconds={seconds}/>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <img src={pausePlay} className="pause pause-big my-3" alt="pause" onClick={() => setOpen(false)}/>
            <p className="pause-text pause-text-big">Reanudar la actividad</p>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
