import React from 'react'

export function ErrorPage() {
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
      >
        <h1
          className="font-size-sm-100 font-weight-boldest text-dark-75 mt-15 text-white"
          style={{ fontSize: '150px' }}>
          404
        </h1>
        <p className="font-size-h3 font-weight-light text-white">Ups! Ha ocurrido algun error</p>
      </div>
    </div>
  )
}
