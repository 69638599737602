import React, { useState, useEffect } from 'react'
import NavBar from "../components/NavBar"
import { login, register } from '../../api/auth/index'
import { useHistory } from "react-router-dom"
import { useDispatch } from 'react-redux'
import { authActions } from '../redux/actions/authActions'
import { alertError, alertRegister } from '../../utils/logger'
import { getSpecialties } from '../../api/specialties/index'
//import { useRouter } from '../../hooks/useRouter'

const provinces = [
  'A Coruña',
  'Álava',
  'Albacete',
  'Alicante',
  'Almería',
  'Asturias',
  'Ávila',
  'Badajoz',
  'Baleares',
  'Barcelona',
  'Burgos',
  'Cáceres',
  'Cádiz',
  'Cantabria',
  'Castellón',
  'Ciudad Real',
  'Córdoba',
  'Cuenca',
  'Gerona',
  'Granada',
  'Guadalajara',
  'Gipuzkoa',
  'Huelva',
  'Huesca',
  'Jaén',
  'La Rioja',
  'Las Palmas',
  'León',
  'Lérida',
  'Lugo',
  'Madrid',
  'Málaga',
  'Murcia',
  'Navarra',
  'Ourense',
  'Palencia',
  'Pontevedra',
  'Salamanca',
  'Segovia',
  'Sevilla',
  'Soria',
  'Tarragona',
  'Santa Cruz de Tenerife',
  'Teruel',
  'Toledo',
  'Valencia',
  'Valladolid',
  'Vizcaya',
  'Zamora',
  'Zaragoza'
]

export default function Auth() {
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [specialties, setSpecialties] = useState([])

  //const { query } = useRouter()

  useEffect(() => {
    getSpecialties().then(res => {
      if (res.status === 200) {
        setSpecialties(res.data)
      } else console.log('No se han podido obtener las especialidades.')
    })
  }, [])

  const handleRegister = async (event) => {
    event.preventDefault()
    setLoading(true)
    const formData = new FormData(event.target)
    let jsonData = Object.fromEntries(formData.entries())
    if (jsonData.province === 'default') {
      alertError({ error: null, customMessage: 'Por favor, selecciona una provincia.' })
      setLoading(false)
      return
    }
    if (jsonData.specialty === 'default') {
      alertError({ error: null, customMessage: 'Por favor, selecciona una especialidad.' })
      setLoading(false)
      return
    }
    if (jsonData.password !== jsonData.repeatPassword) {
      alertError({ error: null, customMessage: 'Las contraseñas no coinciden.' })
      setLoading(false)
      return
    } else {
      try {
        delete jsonData.repeatPassword
        jsonData.role = 'player'
        await register(jsonData)
        alertRegister()
        setLoading(false)
      } catch (err) {
        alertError({ error: err, customMessage: 'No se ha podido crear la cuenta.' })
        setLoading(false)
      }
    }
  }

  const handleLogin = async (event) => {
    event.preventDefault()
    setLoading(true)
    const formData = new FormData(event.target)
    const jsonData = Object.fromEntries(formData.entries())
    try {
      const data = await login(jsonData)
      if (data.status === 200) {
        dispatch(authActions.loginActionSuccess(data.data))
        history.push('/casos')
        setLoading(false)
      }
    } catch (err) {
      dispatch(authActions.loginActionFailure())
      alertError({ error: err, customMessage: 'No se ha podido iniciar la sesión.' })
      setLoading(false)
    }
  }

  const handleForgotPassword = () => {
    history.push('contraseña-olvidada')
  }

  return (
    <>
      <NavBar loginPage={true}/>
      <div className="container my-5">
        <div className="row">
          <div className="col-12 col-md-3 offset-md-2">
            <h5 className="auth-title text-start">YA ESTOY REGISTRADO</h5>
            <form onSubmit={handleLogin}>
              <div className="mb-2">
                <input type="email" className="form-control input-form" id="EmailLogin" name="email" placeholder={'E-mail'} required />
              </div>
              <div className="mb-2">
                <input type="password" className="form-control input-form" id="PasswordLogin" name="password" /*pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                       title="La contraseña debe tener al menos 8 caracteres o más, contener como mínimo un número, una letra mayúscula y una minúscula."*/ placeholder={'Contraseña'} required />
              </div>
              <div className="text-end">
                <button type="submit" className="btn btn-primary mt-4 auth-button">
                  <span className="align-middle">Acceder</span>
                </button>
              </div>
              <div className="text-recover my-4" onClick={handleForgotPassword}>He olvidado mi contraseña</div>
            </form>
          </div>
          <div className="col-12 col-md-3 offset-md-1">
            <h5 className="auth-title text-start">NUEVO REGISTRO</h5>
            <form onSubmit={handleRegister}>
              <div className="mb-2">
                <input type="text" className="form-control input-form" id="Name" name="name" placeholder={'Nombre*'} required />
              </div>
              <div className="mb-2">
                <input type="text" className="form-control input-form" id="FirstSurname" name="firstSurname" placeholder={'Primer apellido*'} required />
              </div>
              <div className="mb-2">
                <input type="text" className="form-control input-form" id="SecondSurname" name="secondSurname" placeholder={'Segundo apellido*'} required />
              </div>
              <div className="mb-2">
                <select className="form-select input-form input-select" name="province" required>
                  <option value={'default'}>Provincia (Selecciona una província)</option>
                  {provinces.map((option) => (
                    <option value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-2">
                <input type="text" className="form-control input-form" id="CollegiateNumber" name="collegiateNumber" placeholder={'Número de colegiado*'} required />
              </div>
              <div className="mb-2">
                <select className="form-select input-form input-select" name="specialty" required>
                  <option value={'default'}>Especialidad (Selecciona una especialidad)</option>
                  {specialties.map((option) => (
                    <option value={option.name}>
                      {option.name}
                    </option>
                  ))}style
                </select>
              </div>
              <div className="mb-2">
                <input type="email" className="form-control input-form" id="Email" name="email" placeholder={'E-mail*'} required />
              </div>
              <div className="mb-2">
                <input type="password" className="form-control input-form" id="Password" name="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  title="La contraseña debe tener al menos 8 caracteres o más, contener como mínimo un número, una letra mayúscula y una minúscula." placeholder={'Contraseña'} required />
              </div>
              <div className="mb-2">
                <input type="password" className="form-control input-form" id="RepeatPassword" name="repeatPassword" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  title="Las contraseñas deben coincidir." placeholder={'Repetir contraseña'} required />
              </div>
              <div className="text-end">
                <button type="submit" className="btn btn-primary my-4 auth-button" disabled={loading}>
                  < span className="align-middle">Registrarse</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div >
    </>
  )
}
