import React, { useState, useEffect } from 'react'
import laptopBg from '../../assets/laptop.svg'
import bg from '../../assets/bg-game-container-1.png'
import trueUnchecked from '../../assets/V_2.png'
import trueChecked from '../../assets/V_1.png'
import falseUnchecked from '../../assets/F_2.png'
import falseChecked from '../../assets/F_1.png'

export default function TrueOrFalseContainer(props) {
  const {
    game,
    handleNext
  } = props
  const [selectedAnswers, setSelectedAnswers] = useState([])
  const [showAnswer, setShowAnswer] = useState(false)
  const [attempt, setAttempt] = useState(0)
  const [answers, setAnswers] = useState(null)
  const [errors, setErrors] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (!game || !game.answers || !game.answers.length) return
    const options = game.answers
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)
    setAnswers(options)
    setAttempt(0)
    setSelectedAnswers([])
  }, [game])

  function handleClick(answer, solution) {
    let _answers = [...selectedAnswers]
    const index = _answers.findIndex(item => item.text === answer.text)
    if (index >= 0) {
      _answers[index] = {...answer, checked: solution}
      setSelectedAnswers(_answers)
    }
    else setSelectedAnswers(selectedAnswers.concat({...answer, checked: solution}))
  }

  function handleButton() {
    setAttempt(attempt + 1)
    setShowAnswer(true)
    let _errors = 0
    let trueAnswers = 0
    let falseAnswers = []
    let attemptsLeft = game.maxAttempts ? game.attempts - (attempt + 1) : 0
    selectedAnswers.forEach(answer => {
      if ((answer.checked && answer.solution === "incorrecta") || (!answer.checked && answer.solution === "correcta")) _errors += 1
      if (answer.solution === 'correcta') trueAnswers += 1
      if (answer.solution === 'incorrecta') falseAnswers.push(answer.text)
    })
    setErrors(_errors)
    let _errorMessage = ''
    if (_errors > 0) {
      _errorMessage = `<p style="color: #ffffff; font-size: 18px; font-family: courier, monospace !important">Recuerde que ${trueAnswers} afirmaciones son verdaderas y ${falseAnswers.length} son falsas.</p><p style="color: #ffffff; font-size: 18px; font-family: courier, monospace !important">${attemptsLeft === 0 ? 'No le quedan más intentos.' : `Inténtelo de nuevo. Le queda${attemptsLeft > 1 ? 'n' : ''} ${attemptsLeft} intento${attemptsLeft > 1 ? 's' : ''}.</p>`}`
      if (!attemptsLeft) {
        _errorMessage += `<p style="color: #ffffff; font-size: 18px; font-family: courier, monospace !important">Las ${falseAnswers.length} afirmaciones falsas son:</p>`
        falseAnswers.forEach(item => _errorMessage += `<p style="color: #ffffff; font-size: 18px; font-family: courier, monospace !important"><strong>${item}</strong></p>`)
        _errorMessage += `<p style="color: #ffffff; font-size: 18px; font-family: courier, monospace !important">Las demás afirmaciones son verdaderas.</p>`
        _errorMessage += `<p style="color: #ffffff; font-size: 18px; font-family: courier, monospace !important">Aunque no haya superado la prueba,</p>`
        _errorMessage += `<p style="color: #ffffff; font-size: 18px; font-family: courier, monospace !important">puede acceder al ordenador <strong>Uro-az02.</strong></p>`
      }
    }
    setErrorMessage(_errorMessage)
  }

  function handleNextButton() {
    if ((game.maxAttempts && game.attempts <= attempt) || errors === 0) {
      handleNext(/*errors > 0*/)
    } else {
      setErrors(0)
      setShowAnswer(false)
      setSelectedAnswers([])
    }
  }

  if (!game) return <div/>
  return (
    <div className="game-container container-fluid py-5" style={{ background: `url(${bg}) no-repeat`, backgroundSize: 'cover'}} >
      <div className="intermediate-box container p-4" style={game.computerScreen ? { backgroundImage: `url('${laptopBg}')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', border: 'none', backgroundColor: 'transparent', boxShadow: 'none'} : {}}>
        {!showAnswer ? <div className="row mt-1">
          {answers ? answers.map((item, index) => (
            <div key={index} className={"col-8 offset-2 py-2 d-inline-flex align-items-center"}>
              <button key={'button-' + index} className={"true-or-false-answer"}>
                <div dangerouslySetInnerHTML={{ __html: item.text }} />
              </button>
              <img className="true-false-icon mx-2" src={selectedAnswers.find(answer => answer.text === item.text && answer.checked) ? trueChecked : trueUnchecked} 
                      onClick={() => handleClick(item, true)} alt="icon-true"/>
              <img className="true-false-icon" src={selectedAnswers.find(answer => answer.text === item.text && !answer.checked) ? falseChecked : falseUnchecked} 
                      onClick={() => handleClick(item, false)} alt="icon-false"/>
            </div>
          )) : <></>}
          {answers?.length === selectedAnswers.length && <div className="row mt-2" onClick={handleButton}>
            <div dangerouslySetInnerHTML={{ __html: '<p style="color: white; font-size: 18px; font-family: courier, monospace !important">Pulse para continuar...</p>' }} />
          </div>}
        </div> 
        : <div className='row'>
            {errors === 0 ? <div>
              <div dangerouslySetInnerHTML={{ __html: '<p style="color: #33ff00; font-size: 18px; font-family: courier, monospace !important">Correcto.</p>' }} />
              {game.description && <div style={{ fontSize: '18px', fontFamily: 'courier, monospace !important' }} dangerouslySetInnerHTML={{ __html: game.description }} />}
            </div> : <div>
              <div dangerouslySetInnerHTML={{ __html: `<p style="color: #EF160C; font-size: 18px; font-family: courier, monospace !important">Hay ${errors} error${errors === 1 ? '' : 'es'}.</p>` }} />
              <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
            </div>}
            <div onClick={handleNextButton}>
              <div dangerouslySetInnerHTML={{ __html: '<p style="color: white; font-size: 18px; font-family: courier, monospace !important">Pulse para continuar...</p>' }} />
            </div>
        </div>}
      </div>
    </div>
  )
}
