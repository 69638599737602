import { API, authClient } from '../../utils/index'
import { uploadSingleFile } from "../files";

// Get all clinical cases
export const getClinicalCases = () => {
    return authClient().get(`${API}/clinical-cases`)
}

// Get clinical case by id
export const getClinicalCaseById = (id) => {
    return authClient().get(`${API}/clinical-cases/${id}`)
}

// Update clinical case
export const updateClinicalCase = async (id, clinicalCase, video) => {
    if (video) {
        const response = await uploadSingleFile(video, 'clinicalCases')
        clinicalCase.videoURL = response.data.fileURL
    }
    return authClient().put(`${API}/clinical-cases/${id}`, clinicalCase)
}

// Save clinical case
export const postClinicalCase = async (clinicalCase, video) => {
    const response = await uploadSingleFile(video, 'clinicalCases')
    clinicalCase.videoURL = response.data.fileURL
    return authClient().post(`${API}/clinical-cases`, clinicalCase)
}

// Delete clinical case
export const deleteClinicalCase = (id) => {
    return authClient().delete(`${API}/clinical-cases/${id}`)
}

// Check code clinical case
export const checkClinicalCaseCode = (id, code) => {
    return authClient().post(`${API}/clinical-cases/check-code/${id}`, code)
}