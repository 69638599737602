import React from 'react'
import { Dialog, DialogContent, DialogTitle, Checkbox, FormControlLabel } from '@material-ui/core'
import CloseIcon from "@material-ui/icons/Close";

const GameRules = (props) => {
  const { open, onClose, icon, title, gameRules, showCheckbox, checked, setChecked } = props
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="md"
      onClose={() => { }}
      aria-labelledby="rules-dialog">
      <div className="container-fluid pt-3 pb-5" style={{ textAlign: 'center' }}>
        <DialogTitle disableTypography className="dialog-title">
          <CloseIcon className="close-dialog-icon" onClick={() => onClose()} />
        </DialogTitle>
        <DialogContent>
          <img className="mb-4 game-rules-dialog-icon" src={icon} alt="game-rules" />
          <h4 className="game-rules-title mb-4">{title}</h4>
          {gameRules ?
            <>
              <p>Es una <strong>plataforma de formación sobre</strong> los síntomas del tracto urinario inferior (STUI) presentado a través de <strong>seis cursos. Cada uno de ellos consta de un caso clínico interactivo</strong> que el médico debe resolver.</p>
              <p>Los cursos están planteados en un formato <strong>dinámico y visual</strong> para proporcionar una experiencia inmersiva y de alto valor científico. Además, en cada curso hay un <strong>marco teórico y su test de evaluación</strong> correspondiente, que será necesario aprobar para acceder al certificado con los créditos obtenidos.</p>
              <p><strong>Metodología de aprendizaje:</strong></p>
              <p><span style={{ color: "#EF160C" }}><strong>1) </strong></span><strong>Elige el curso. </strong>Desde la pantalla de selección, haz clic en la viñeta del curso que quieras trabajar. En la parte inferior derecha de cada viñeta están indicados los colectivos específicos para los que se recomienda ese curso en concreto.</p>
              <p><span style={{ color: "#EF160C" }}><strong>2) </strong></span><strong>Visualiza el vídeo inicial del caso interactivo. </strong>Te dará pistas sobre el problema al que se enfrenta el paciente. Cuando finalice, pulsa el botón COMENZAR.</p>
              <p><span style={{ color: "#EF160C" }}><strong>3) </strong></span><strong>Sigue el caso hasta el final. </strong>Recopila toda la información que se va proporcionando y utiliza tus habilidades y conocimientos para contestar correctamente todas las preguntas. Presta especial atención a las justificaciones de las respuestas, ya que te guiarán hacia la resolución del «misterio». </p>
              <p><span style={{ color: "#EF160C" }}><strong>4) </strong></span><strong>Estudia el marco teórico. </strong>Una vez completado el caso interactivo, accede al marco teórico y léelo con atención: aquí encontrarás la base científica en la que se sustenta el caso. </p>
              <p><span style={{ color: "#EF160C" }}><strong>5) </strong></span><strong>Rellena la encuesta de valoración. </strong>Tu opinión es muy importante para que podamos mejorar esta y otras acciones formativas futuras.</p>
              <p><span style={{ color: "#EF160C" }}><strong>6) </strong></span><strong>Realiza el test de acreditación. </strong>Para acceder a él, es imprescindible que llegues al final del caso y que hayas leído el marco teórico. El test consta de 20 preguntas; deberás responder correctamente como mínimo el 80 % de ellas para obtener la acreditación correspondiente.</p>
              <p><span style={{ color: "#EF160C" }}><strong>7) </strong></span><strong>Descarga el certificado </strong>con los créditos obtenidos (cuando estén disponibles).</p>
              {showCheckbox && <FormControlLabel
                control={<Checkbox checked={checked} onChange={() => setChecked(!checked)} name="checkNotShowAgain" />}
                label="No volver a mostrar"
              />}
              <br />
            </>
            : <>
              <p>Para esta plataforma se ha solicitado acreditación a la <strong>Comisión de Formación Continuada de las Profesiones Sanitarias de la Comunidad de Madrid</strong>. Cada curso se acredita de forma independiente.</p>
              <p>Para acceder al test de acreditación, es imprescindible que primero hayas completado el caso clínico interactivo, hayas leído el marco teórico y hayas rellenado el cuestionario de valoración. Cumplidos estos requisitos, automáticamente se activará el botón <strong>Acreditación</strong> del curso elegido para que puedas realizar el test.</p>
              <p>Los test de acreditación constan, cada uno de ellos, de 20 preguntas con 4 respuestas posibles, de las que solo una es correcta. Para superar el test y obtener los créditos de un curso, es imprescindible que respondas correctamente el 80 % de las preguntas planteadas como mínimo. Dispones de dos intentos para ello.</p>
              <p>Una vez superado el test, podrás descargar el certificado con los créditos obtenidos haciendo clic en el botón <strong>Certificado</strong> del curso correspondiente.</p>
            </>}
          <button className="own-button inside-button my-2" onClick={() => onClose()}>
            CERRAR
          </button>
        </DialogContent>
      </div>
    </Dialog>
  )
}
export default GameRules
