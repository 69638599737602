import React, {useEffect, useState} from 'react'
import {useHistory, useParams, useLocation} from 'react-router-dom'
import GameNavbar from "../components/GameNavBar"
import {shallowEqual, useSelector} from "react-redux";
import {getRanking} from "../../api/ranking";
import {getUsers} from "../../api/user";

export function getFullTime(time) {
  const hours = Math.floor(time / 3600)
  const minutes = Math.floor(time % 3600 / 60)
  const seconds = Math.floor(time % 3600 % 60)
  return (hours > 0 ? hours + "h " : "") +
    (minutes > 0 ? (minutes < 10 ? "0" + minutes : minutes) + "' " : "00' ") +
    (seconds < 10 ? "0" + seconds + '"' : seconds + '"')
}


function order(a, b) {
  if (a.points > b.points) return -1
  if (a.points < b.points) return 1
  if (a.time < b.time) return -1
  if (a.time > b.time) return 1
  return 0
}

function getData(ranking, users) {
  let data = []
  const sortedRanking = ranking.sort(order)
  for (let i = 0; i < sortedRanking.length; ++i) {
    const elem = {}
    const user = users.find(item => item._id === sortedRanking[i].user)
    elem.name = user ? user.name + ' ' + user.firstSurname + ' ' + user.secondSurname : ''
    elem.time = getFullTime(sortedRanking[i].time)
    elem.points = sortedRanking[i].points
    data = data.concat(elem)
  }
  return data
}

export default function Ranking() {
  const history = useHistory()
  const [ranking, setRanking] = useState(null)
  const [showFMC, setShowFMC] = useState(true)
  const location = useLocation()
  const user = useSelector((store) => store.authenticationWebMistuiery?.user, shallowEqual)
  const [seconds, setSeconds] = useState(0)
  //const [userPoints, setUserPoints] = useState(0)
  const id = useParams().id

  useEffect(() => {
    getUsers().then(response => {
      if (response.status === 200) {
        getRanking(id).then(res => {
          if (res.status === 200) {
            setRanking(getData(res.data, response.data))
          } else {
            console.log('Could not get any ranking')
          }
        })
      } else console.log('Could not get users')
    })
  }, [id])


  useEffect(() => {
    if (!user) return
    if (!location && !location.state && user.clinicalCasesCompleted && user.clinicalCasesCompleted.includes(id))
      setShowFMC(false)
    if (user.progress && user.progress[id]) {
      if (user.progress[id].seconds) setSeconds(user.progress[id].seconds)
      //if (user.progress[id].points !== undefined) setUserPoints(user.progress[id].points)
    }
  }, [id, user])

  useEffect(() => {
    if (location && location.state) {
      setShowFMC(true)
    } else setShowFMC(false)
  }, [location])

  return (
    <>
      <GameNavbar seconds={seconds} setSeconds={setSeconds} stopTimer={true}/>
      <div className="container-fluid py-5">
        {/*showFMC ? <div className="row">
          <div className="col-12 col-md-8 offset-md-2">
            <hr style={{ color: 'white', height: '2px', opacity: '1' }}/>
            <div className="row">
              <div className="col-6 text-start offset-md-1">
                <p className="text-white" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{(user.name + ' ' + user.firstSurname + ' ' + user.secondSurname)}</p>
              </div>
              <div className="col-2 text-start">
                <p className="text-white" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{getFullTime(seconds)}</p>
              </div>
              <div className="col-3 text-start">
                <p className="text-white" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{userPoints + ' puntos'}</p>
              </div>
            </div>
            <hr style={{ color: 'white', height: '2px', opacity: '1' }}/>
          </div>
        </div> : <></>*/}
        <div className="row">
          <div className="col-12">
            <h1 className="title-ranking">RANKING</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2">
            {ranking?./*slice(0,19).*/map((item, index) => (
              <>
                <hr style={{ color: 'white', height: '2px', opacity: '1' }}/>
                <div className="row">
                  <div className="col-6 text-start offset-md-1">
                    <p className="text-white" style={index <= 2 ? { fontSize: '1.5rem', fontWeight: 'bold' } : { fontSize: '1.2rem'}}>{(index + 1) + '.º ' + item.name}</p>
                  </div>
                  <div className="col-2 text-start">
                    <p className="text-white" style={index <= 2 ? { fontSize: '1.5rem', fontWeight: 'bold'} : { fontSize: '1.2rem'}}>{item.time}</p>
                  </div>
                  <div className="col-3 text-start">
                    <p className="text-white" style={index <= 2 ? { fontSize: '1.5rem', fontWeight: 'bold'} : { fontSize: '1.2rem'}}>{item.points + ' puntos'}</p>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <button className="own-button inside-button" onClick={() => !showFMC ? history.push('/casos') : history.push('/casos/' + id + '/formacion')}>
              {!showFMC ? 'VOLVER AL MENÚ PRINCIPAL' : 'CONTINÚA LA FORMACIÓN'}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
