import React, { useState, useEffect } from 'react'
import { alertError } from "../../../utils/logger"
import laptopBg from '../../assets/laptop.svg'

export default function GameContainerComputerScreen(props) {
  const {
    game,
    handleNext,
    points,
    setPoints
  } = props
  const [selectedAnswers, setSelectedAnswers] = useState(null)
  const [openAnswerDialog, setOpenAnswerDialog] = useState(false)
  const [answerMessage, setAnswerMessage] = useState('')
  const [attempt, setAttempt] = useState(0)
  const [answers, setAnswers] = useState(null)

  useEffect(() => {
    if (!game || !game.answers || !game.answers.length) return
    const options = game.answers
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)
    setAnswers(options)
    setAttempt(0)
  }, [game])

  function handleClick(answer) {
    if (!answer) {
      alertError({ error: null, customMessage: 'Por favor, selecciona una respuesta.' })
      return
    }
    let addPoints = attempt === 0 ? points + answer.firstTry : points + answer.secondTry
    setSelectedAnswers(answer)
    setPoints(addPoints)
    const newAttempt = attempt + 1
    setAttempt(newAttempt)
    setOpenAnswerDialog(true)
    setAnswerMessage(answer.solution === 'correcta' ? "<h2 style='color: #33ff00; font-family:courier, monospace !important;'><strong>¡Correcto!</strong></h2>" 
    : "<h2 style='color: #33ff00; font-family:courier, monospace !important;'><strong>No es correcto.</strong></h2>" 
    + `<p style='color: #33ff00; font-family:courier, monospace !important;'>Has perdido ${Math.abs(answer.firstTry)} puntos.</p>`
    + "<p style='color: #33ff00; font-family:courier, monospace !important;'>Vuelve a intentarlo.</p>")
  }

  function handleButton() {
    setOpenAnswerDialog(false)
    setAnswerMessage('')
    if ((game.maxAttempts && game.attempts <= attempt) || selectedAnswers.solution === 'correcta') {
      handleNext()
      setSelectedAnswers(null)
    }
  }

  if (!game) return <div/>
  return (
    <div className="game-container container-fluid py-5">
      <div className="intermediate-box container p-4" 
        style={{ backgroundImage: `url('${laptopBg}')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', border: 'none', backgroundColor: 'transparent', boxShadow: 'none'}}
        onClick={() => openAnswerDialog && handleButton()}
        >
        {game.description !== '' && <div className="px-2" dangerouslySetInnerHTML={{ __html: game.description }} />}
        {openAnswerDialog ? 
          <div className='row'>
            <div dangerouslySetInnerHTML={{ __html: answerMessage }} />
            <div className="col-8 offset-2 py-2 d-inline-flex align-items-center">
              <div className="html mt-3" dangerouslySetInnerHTML={{ __html: selectedAnswers?.justification }} />
            </div>
          </div>
          : <div className={"row"} >
            <div className={"col-8 offset-2 py-2 d-inline-flex align-items-center"}>
            <div dangerouslySetInnerHTML={{ __html: game.question }}/>
            </div>
          {answers ? answers.map((item, index) => (
            <div key={index} className={"col-8 offset-2 py-2 d-flex align-items-center justify-content-center game-answers-computer-screen"} style={{ cursor: 'pointer' }}  onClick={() => handleClick(item)}>
              <div dangerouslySetInnerHTML={{ __html: item.text }}/>
            </div>
          )) : <></>}
          </div>
        }
      </div>
    </div>
  )
}
