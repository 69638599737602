import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import GameNavbar from "../components/GameNavBar"
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {shallowEqual, useSelector, useDispatch} from "react-redux";
import {alertError} from "../../utils/logger";
import {postReview} from "../../api/reviews";
import {updateUserAcreditationProgress} from "../../api/user";
import { authActions } from '../redux/actions/authActions';

export default function FMC() {
  const clinicalCaseId = useParams().id
  const history = useHistory()
  const [answers, setAnswers] = useState({})
  const user = useSelector((store) => store.authenticationWebMistuiery?.user, shallowEqual)
  const [buttonBlocked, setButtonBlocked] = useState(false)
  const dispatch = useDispatch()

  function handleClick() {
    /*if (buttonBlocked) {
      history.push('/casos/' + clinicalCaseId)
      return
    }*/
    if (Object.keys(answers).length !== 8) {
      alertError({ error: null, customMessage: 'Por favor, contesta todas las preguntas.' })
      return
    }
    const review = {
      clinicalCase: clinicalCaseId,
      user: user._id,
      answers
    }
    postReview(review).then(res => {
      if (res.status === 201) {
        if (buttonBlocked) history.push('/casos/' + clinicalCaseId)
        else history.push('/casos/' + clinicalCaseId + '/test-acreditacion')
      } else console.log('Could not save review')
    })
    if (!user) return
    let userAcreditation = user?.acreditation ? user?.acreditation : {}
    if (!userAcreditation[clinicalCaseId]) {
      userAcreditation = {...userAcreditation, [clinicalCaseId]: {}}
    }
    userAcreditation[clinicalCaseId].satisfactionTestDone = true
    userAcreditation[clinicalCaseId].acreditationTest = userAcreditation[clinicalCaseId].acreditationTest ? userAcreditation[clinicalCaseId].acreditationTest : null
    userAcreditation[clinicalCaseId].downloadDiploma = userAcreditation[clinicalCaseId].downloadDiploma ? userAcreditation[clinicalCaseId].downloadDiploma : false
    updateUserAcreditationProgress(user.email, userAcreditation).then(res => {
      if (res.status === 200) {
        const newUser = user
        newUser.acreditation = userAcreditation
        console.log('User acreditation progress updated')
        dispatch(authActions.refreshUserData(newUser))
      } else console.log('Could not update user acreditation progress')
    })
  }

  useEffect(() => {
    if (!user) return
    // DIPLOMA DEL CASO 6 SOLO DISPONIBLE PARA USUARIOS DEL DOMINIO ASTELLAS / OWIUS / VIVACTIS / MISTUIERY
    if (clinicalCaseId === '61a49b7a2aa0fd09eb3882e7' && !user.email.includes('@astellas.com') && !user.email.includes('@vivactis.') && !user.email.includes('@owius.com') && !user.email.includes('@mistuiery.com')) {
      setButtonBlocked(true)
      return
    }
    setButtonBlocked(user.acreditation && user.acreditation[clinicalCaseId] && user.acreditation[clinicalCaseId].acreditationTest && user.acreditation[clinicalCaseId].acreditationTest.done)
  }, [user, clinicalCaseId])

  const handleChangeAnswer = (index) => (event) => {
    setAnswers({...answers, [index]: event.target.value })
  }

  return (
    <>
      <GameNavbar stopTimer={true} hideTimer={true}/>
      <div className="game-container container-fluid py-5">
        <div className="row pb-3">
          <div className="col-12">
            <h3 className="fmc-text">Test de valoración</h3>
            <h5 className="fmc-text">Por favor, valora de 1 (nada satisfactorio) a 5 (muy satisfactorio) los siguientes aspectos:</h5>
          </div>
        </div>
        <div className="row text-start px-5">
          <div className="col-md-4 offset-md-3 col-12">
            <h5 className="py-1">1. TEMAS TRATADOS</h5>
          </div>
          <div className="col-md-4 col-12">
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="gender"
                name="radio-buttons-group"
                onChange={handleChangeAnswer('question1')}
              >
                <FormControlLabel value="1" control={<Radio />} label="1" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="3" control={<Radio />} label="3" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="5" control={<Radio />} label="5" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className="row text-start px-5">
          <div className="col-md-4 offset-md-3 col-12">
            <h5 className="py-1">2. CALIDAD DE LOS CONTENIDOS</h5>
          </div>
          <div className="col-md-4 col-12">
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="gender"
                name="radio-buttons-group"
                onChange={handleChangeAnswer('question2')}
              >
                <FormControlLabel value="1" control={<Radio />} label="1" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="3" control={<Radio />} label="3" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="5" control={<Radio />} label="5" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className="row text-start px-5">
          <div className="col-md-4 offset-md-3 col-12">
            <h5 className="py-1">3. ESTRUCTURA DE LA HERRAMIENTA</h5>
          </div>
          <div className="col-md-4 col-12">
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="gender"
                name="radio-buttons-group"
                onChange={handleChangeAnswer('question3')}
              >
                <FormControlLabel value="1" control={<Radio />} label="1" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="3" control={<Radio />} label="3" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="5" control={<Radio />} label="5" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className="row text-start px-5">
          <div className="col-md-4 offset-md-3 col-12">
            <h5 className="py-1">4. METODOLOGÍA EMPLEADA</h5>
          </div>
          <div className="col-md-4 col-12">
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="gender"
                name="radio-buttons-group"
                onChange={handleChangeAnswer('question4')}
              >
                <FormControlLabel value="1" control={<Radio />} label="1" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="3" control={<Radio />} label="3" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="5" control={<Radio />} label="5" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className="row text-start px-5">
          <div className="col-md-4 offset-md-3 col-12">
            <h5 className="py-1">5. UTILIDAD EN LA PRÁCTICA CLÍNICA PROFESIONAL</h5>
          </div>
          <div className="col-md-4 col-12">
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="gender"
                name="radio-buttons-group"
                onChange={handleChangeAnswer('question5')}
              >
                <FormControlLabel value="1" control={<Radio />} label="1" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="3" control={<Radio />} label="3" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="5" control={<Radio />} label="5" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className="row text-start px-5">
          <div className="col-md-4 offset-md-3 col-12">
            <h5 className="py-1">6. ORGANIZACIÓN</h5>
          </div>
          <div className="col-md-4 col-12">
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="gender"
                name="radio-buttons-group"
                onChange={handleChangeAnswer('question6')}
              >
                <FormControlLabel value="1" control={<Radio />} label="1" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="3" control={<Radio />} label="3" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="5" control={<Radio />} label="5" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className="row text-start px-5">
          <div className="col-md-4 offset-md-3 col-12">
            {/*TODO: SHOW DOCTOR'S NAME*/}
            <h5 className="py-1">7. DOCENTES</h5>
          </div>
          <div className="col-md-4 col-12">
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="gender"
                name="radio-buttons-group"
                onChange={handleChangeAnswer('question7')}
              >
                <FormControlLabel value="1" control={<Radio />} label="1" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="3" control={<Radio />} label="3" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="5" control={<Radio />} label="5" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className="row text-start px-5">
          <div className="col-md-4 offset-md-3 col-12">
            <h5 className="py-1">8. POR ÚLTIMO, ¿CUÁL ES TU VALORACIÓN GENERAL?</h5>
          </div>
          <div className="col-md-4 col-12">
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="gender"
                name="radio-buttons-group"
                onChange={handleChangeAnswer('question8')}
              >
                <FormControlLabel value="1" control={<Radio />} label="1" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="3" control={<Radio />} label="3" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="5" control={<Radio />} label="5" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <button className="fmc-button" onClick={handleClick}>
              {buttonBlocked ? 'Guardar y volver al menú del caso >' : 'Test de acreditación >'}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}