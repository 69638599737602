import { API, authClient } from '../../utils/index'
import {uploadSingleFile} from "../files";

// Get all doctors
export const getDoctors = () => {
    return authClient().get(`${API}/doctors`)
}

// Get doctor by id
export const getDoctorById = (id) => {
    return authClient().get(`${API}/doctors/${id}`)
}

// Update doctor
export const updateDoctor = async (id, doctor, image) => {
    if (image) {
        const response = await uploadSingleFile(image, 'doctors')
        doctor.imageURL = response.data.fileURL
    }
    return authClient().put(`${API}/doctors/${id}`, doctor)
}

// Save doctor
export const postDoctor = async (doctor, image) => {
    const response = await uploadSingleFile(image, 'doctors')
    doctor.imageURL = response.data.fileURL
    return authClient().post(`${API}/doctors`, doctor)
}

// Delete doctor
export const deleteDoctor = (id) => {
    return authClient().delete(`${API}/doctors/${id}`)
}

