import { userConstants } from "../constants"

export const authActions = {
  loginActionSuccess,
  loginActionFailure,
  logoutAction,
  refreshUserData,
  getCredentials
}

// LOGIN

function loginActionFailure ()  {
  return { 
    type: userConstants.LOGIN_FAILURE
  } 
}
function loginActionSuccess (user)  {
  return {
    type: userConstants.LOGIN_SUCCESS,
    user
  }
}

// LOGOUT

function logoutAction ()  {
  return {
    type: userConstants.LOGOUT
  }
}

function refreshUserData(user) {
  return {
    type: userConstants.REFRESH_USER,
    user
  }
}

// TO DO --> GET CREDENTIALS VIA COOKIES

function getCredentials () {
  console.log('get credentials via cookies action')
}