import React from 'react'
import { Dialog } from '@material-ui/core'
import correctAnswerIcon from "../assets/correct-answer.png"
import notValidAnswer from "../assets/not-valid-answer.png"
import bg from '../assets/background.png'


export default function TrueOrFalseAllAtOnceAnswerDialog(props) {
  const { open, onClose, answers, errors, firstAttempt, points, maxAttempts, attempt, id } = props

  return (
    <Dialog
      style={{ background: `url(${bg}) no-repeat`, backgroundSize: 'cover' }}
      open={open}
      fullWidth={true}
      maxWidth="md"
      onClose={() => { }}
      aria-labelledby="answer-dialog">
      <div className="container-fluid text-center px-5 py-4">
        <div className="row">
          <div className="col-12">
            {errors === 0 ? (
              <>
                <img className="top-icon mb-2"
                  src={correctAnswerIcon}
                  alt={'top-icon'} />
                <h3 style={{ color: '#49d489' }}>{id === '64ad42e55771115194fb6ea9' ? 'Ha acertado todas las respuestas.' : `¡Correcto!`}</h3>
                {firstAttempt && id !== '64ad42e55771115194fb6ea9' && <h2 style={{ color: '#004e7d', fontWeigth: 'bolder' }}>{'Ha acertado todas las afirmaciones a la primera.'}</h2>}
                {id === '64ad42e55771115194fb6ea9' ? 
                <h2 style={{ color: '#004e7d', fontWeigth: 'bolder' }}>{'Es usted un '}<i>{'crack.'}</i></h2>
                : <h2 style={{ color: '#004e7d', fontWeigth: 'bolder' }}>{'Los ' + points + ' puntos son tuyos.'}</h2>}
              </>
            ) : (
              <>
                <img className="top-icon mb-4"
                  src={notValidAnswer}
                  alt={'top-icon'} />
                <h3 style={{ color: '#004e7d' }}>{`Has acertado ${answers?.length - errors} afirmaciones.`}</h3>
                <h3 style={{ color: '#004e7d' }}>{maxAttempts ? (attempt < maxAttempts ? `Te quedan ${maxAttempts - attempt} intento${maxAttempts - attempt === 1 ? '' : 's'}.` : 'Ya no puedes intentarlo de nuevo.') : `¡Inténtalo de nuevo!`}</h3>
              </>
            )} 
            <div className="row py-3 text-center">
              <div className="col-12">
                <button className="own-button inside-button" onClick={() => onClose()}>
                  CONTINUAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
