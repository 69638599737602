import React, { useState, useEffect } from 'react'
import bg from '../../assets/bg-game-container-1.png'
import trueUnchecked from '../../assets/V_2.png'
import trueChecked from '../../assets/V_1.png'
import falseUnchecked from '../../assets/F_2.png'
import falseChecked from '../../assets/F_1.png'
import TrueOrFalseAnswerDialog from '../../components/TrueOrFalseAnswerDialog'
import { alertError } from "../../../utils/logger"

export default function TrueOrFalseContainerFeedback(props) {
  const {
    game,
    handleNext,
    points,
    setPoints
  } = props
  const [selectedAnswers, setSelectedAnswers] = useState([])
  const [answersChecked, setAnswersChecked] = useState([])
  const [openAnswerDialog, setOpenAnswerDialog] = useState(false)
  const [answers, setAnswers] = useState(null)
  const [currentAnswer, setCurrentAnswer] = useState(null)

  useEffect(() => {
    if (!game || !game.answers || !game.answers.length) return
    const options = game.answers
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)
    setAnswers(options)
    setSelectedAnswers([])
    setAnswersChecked([])
  }, [game])

  function handleClick(answer, solution) {
    if (answersChecked.find(item => answer.text === item.text)) return
    let _answers = [...selectedAnswers]
    const index = _answers.findIndex(item => item.text === answer.text)
    if (index >= 0) {
      _answers[index] = {...answer, checked: solution}
      setSelectedAnswers(_answers)
    }
    else setSelectedAnswers(selectedAnswers.concat({...answer, checked: solution}))
  }

  function handleButton(answer) {
    const _answer = selectedAnswers.find(item => answer.text === item.text)
    if (!_answer) {
      alertError({ error: null, customMessage: 'Por favor, selecciona una respuesta.' })
      return
    }
    const checked = _answer?.checked
    const correctAnswer = (answer?.solution === 'correcta' && checked) || (answer?.solution === 'incorrecta' && !checked)
    setPoints(correctAnswer ? points + answer?.firstTry : points)
    setCurrentAnswer({answer, correct: correctAnswer})
    setAnswersChecked(answersChecked.concat({...answer, correct: correctAnswer}))
    setOpenAnswerDialog(true)
  }

  if (!game) return <div/>
  return (
    <div className="game-container container-fluid py-5" style={{ background: `url(${bg}) no-repeat`, backgroundSize: 'cover'}} >
      <div className="intermediate-box container p-4">
        <div className="row mt-1">
          {answers ? answers.map((item, index) => (
            <div key={index} className={"col-12 py-2 d-inline-flex align-items-center"}>
              <button key={'button-' + index} className={"true-or-false-answer mx-2"}>
                <div dangerouslySetInnerHTML={{ __html: item.text }} />
              </button>
              <span style={{ width: '100px'}}>
                <img className="true-false-icon" src={selectedAnswers.find(answer => answer.text === item.text && answer.checked) ? trueChecked : trueUnchecked} 
                        onClick={() => handleClick(item, true)} alt="icon-true"/>
                <img className="true-false-icon mx-2" src={selectedAnswers.find(answer => answer.text === item.text && !answer.checked) ? falseChecked : falseUnchecked} 
                        onClick={() => handleClick(item, false)} alt="icon-false"/>
              </span>
              <button
                className="true-false-button"
                style={{ color: selectedAnswers.find(answer => answer.text === item.text) ? '#004e7d' : 'grey', cursor: answersChecked.find(answer => answer.text === item.text) && 'default' }} 
                disabled={answersChecked.find(answer => answer.text === item.text)}
                onClick={() => handleButton(item)}>
                {answersChecked.find(answer => answer.text === item.text) ? (answersChecked.find(answer => answer.text === item.text)?.correct ? 'Correcto' : 'Incorrecto') : 'Confirmar'}
              </button>
            </div>
          )) : <></>}
          {answers?.length === answersChecked.length && <div className="row py-3 text-center">
              <div className="col-12">
                <button className="own-button inside-button" onClick={() => handleNext()}>
                 CONTINUAR
                </button>
              </div>
            </div>}
        </div> 
      </div>
      <TrueOrFalseAnswerDialog
        open={openAnswerDialog}
        onClose={() => setOpenAnswerDialog(false)}
        answer={currentAnswer?.answer}
        correctAnswer={currentAnswer?.correct}
      />
    </div>
  )
}
