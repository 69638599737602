import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import GameNavbar from "../components/GameNavBar"
import { getQuestionsFMC } from "../../api/fmc";

function getData(questions) {
  const data = []
  for (const question of questions) {
    const elem = {}
    elem.question = question.question
    const filter = question.answers.filter(item => item.correct === true)
    elem.correctAnswer = filter[0]?.text
    data.push(elem)
  }
  return data
}

export default function FMCQuestionsSolution() {
  const clinicalCaseId = useParams().id
  const history = useHistory()
  const [questions, setQuestions] = useState([])

  useEffect(() => {
    getQuestionsFMC(clinicalCaseId).then(res => {
      if (res.status === 200) {
        setQuestions(getData(res.data))
      } else console.log('Could not get questions.')
    })
  }, [clinicalCaseId])

  return (
    <>
      <GameNavbar stopTimer={true} hideTimer={true} hideProgress={true}/>
      <div className="game-container container-fluid py-5">
        <div className="container">
          <h3 className="fmc-text">Test de acreditación - Respuestas correctas</h3>
          <div className="row mt-4">
            {questions ? questions.map((item, index) => (
              <p><strong>{index + 1}</strong>{'. ' + item.question} <strong>{' Respuesta: '}</strong><div dangerouslySetInnerHTML={{ __html: item.correctAnswer }} /></p>
            )) : <></>}
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <button className="fmc-button" onClick={() => history.push('/casos')}>
                {'Volver al menú de casos >'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}