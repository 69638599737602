import React from 'react'
import { Dialog } from '@material-ui/core'
import wrongAnswer from "../assets/wrong-answer.png"
import correctAnswerIcon from "../assets/correct-answer.png"

function getAnswerResponse(answer, attempt, correct) {
  if (!answer) return ''
  const points = attempt === 0 ? (answer.firstTry ? Math.abs(answer.firstTry) : answer.firstTry) : (answer.secondTry ? Math.abs(answer.secondTry) : answer.secondTry)
  if (correct) {
    return attempt === 0 ? 'Has ganado ' + points + ' puntos.' : (points === 0 ? 'Como no has acertado a la primera, no ganas puntos.' : 'Como no has acertado a la primera, solo ganas ' + points + ' puntos.')
  } else return '¡Inténtalo de nuevo!'
}

export default function LinkConceptsDialog(props) {
  const { open, answer, attempt, onClose, correctAnswer } = props
  const text = getAnswerResponse(answer, attempt, correctAnswer)

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="md"
      onClose={() => {}}
      aria-labelledby="answer-dialog">
      <div className="container-fluid text-center p-5">
        <div className="row">
          <div className="col-12">
            {correctAnswer ? (
              <>
                <img className="top-icon mb-4"
                     src={correctAnswerIcon}
                     alt={'top-icon'} />
                <h4 style={{ color: '#49d489'}}>{'¡Correcto!'}</h4>
              </>
            ) : (
              <>
                <img className="top-icon mb-4"
                     src={wrongAnswer}
                     alt={'top-icon'} />
                <h4 style={{ color: '#EF160C'}}>{'Conexión incorrecta.'}</h4>
              </>
            )}
            <h4 style={{ color: '#004e7d'}} className="mt-5">{text}</h4>
            <div className="row py-5 text-center">
              <div className="col-12">
                <button className="own-button inside-button" onClick={() => onClose()}>
                  {correctAnswer ? 'CONTINUAR' : 'VOLVER A INTENTAR'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
