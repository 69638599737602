import React, {useState} from 'react'
import NavBar from "../components/NavBar"
import {useHistory, useParams} from "react-router-dom"
import {alertError, alertSuccess} from "../../utils/logger";
import {resetPassword} from "../../api/user";

export default function ResetPassword() {
  const history = useHistory()
  const id = useParams().id
  const [loading, setLoading] = useState(false)

  async function handleResetPassword(event) {
    event.preventDefault()
    setLoading(true)
    const formData = new FormData(event.target)
    let jsonData = Object.fromEntries(formData.entries())
    if (jsonData.password !== jsonData.repeatPassword)  {
      alertError({ error: null, customMessage: 'Las contraseñas no coinciden.' })
      setLoading(false)
    } else {
      try {
        delete jsonData.repeatPassword
        await resetPassword(jsonData, id)
        alertSuccess({ title: 'Listo!', customMessage: 'La contraseña se ha cambiado correctamente.' })
        history.push('/identificacion')
        setLoading(false)
      } catch (err) {
        alertError({ error: err, customMessage: 'No se ha podido cambiar la contraseña.' })
        setLoading(false)
      }
    }
  }


  return (
    <>
      <NavBar/>
      <div className="container my-5">
        <div className="row">
          <div className="col-12">
            <h3 className="text-white">REESTABLECER CONTRASEÑA</h3>
            <form onSubmit={handleResetPassword}>
              <div className="mb-2">
                <input type="password" className="form-control input-form" id="Password" name="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                       title="La contraseña debe tener al menos 8 caracteres o más, contener como mínimo un número, una letra mayúscula y una minúscula." placeholder={'Contraseña'} required />
              </div>
              <div className="mb-2">
                <input type="password" className="form-control input-form" id="RepeatPassword" name="repeatPassword" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                       title="Las contraseñas deben coincidir." placeholder={'Repetir contraseña'} required />
              </div>
              <div className="text-end">
                <button type="submit" className="btn btn-primary mt-4 auth-button" disabled={loading}>
                  <span className="align-middle">Aceptar</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
