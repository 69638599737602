import React from 'react'
import bg from '../../assets/bg-game-container-1.png'
import laptopBg from '../../assets/laptop.svg'
import VerticalStepper from '../../components/Stepper'

export default function IntermediateScreenInteractive(props) {
  const {
    game,
    handleNext
  } = props

  if (!game) return <div/>
  return (
    <div className="game-container py-5" style={{ background: `url(${bg}) no-repeat`, backgroundSize: 'cover'}}>
      <div className={`intermediate-box container p-4`}
        style={game.computerScreen ? { backgroundImage: `url('${laptopBg}')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', border: 'none', backgroundColor: 'transparent', boxShadow: 'none'} : {}}>
        <div className="row">
          <div className={game.computerScreen ? "col-8 offset-2" : "col-12"}>
            {game.description !== '' && <div className="intermediate-screen" dangerouslySetInnerHTML={{ __html: game.description }} />}
          </div>
          <VerticalStepper answers={game?.answers}/>
        </div>
      </div> 
      <div className="container">
        {game.button && <div className="row mt-4">
          <div className="col-12">
            <button className="own-button inside-button" onClick={handleNext}>
              CONTINUAR
            </button>
          </div>
        </div>}
      </div>
    </div>
  )
}
