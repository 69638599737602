import React, { useState, useEffect } from 'react'
import AnswerDialog from "../../components/AnswerDialog"
import HintDialog from "../../components/HintDialog"
import { alertError } from "../../../utils/logger"
import InfiltratedAnswerDialog from "../../components/InfiltratedAnswerDialog";

export default function GameContainer(props) {
  const {
    game,
    handleNext,
    points,
    setPoints,
    infiltrated,
    twoOptions
  } = props
  const [selectedAnswers, setSelectedAnswers] = useState(null)
  const [previousAnswers, setPreviousAnswers] = useState([])
  const [openHintDialog, setOpenHintDialog] = useState(false)
  const [openAnswerDialog, setOpenAnswerDialog] = useState(false)
  const [openInfiltratedAnswerDialog, setOpenInfiltratedAnswerDialog] = useState(false)
  const [attempt, setAttempt] = useState(0)
  const [answers, setAnswers] = useState(null)
  const [hintAccepted, setHintAccepted] = useState(false)

  useEffect(() => {
    if (!game || !game.answers || !game.answers.length) return
    const options = game.answers
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)
    setAnswers(twoOptions ? game.answers : options)
    setAttempt(0)
  }, [game])

  function handleClick(answer) {
    setSelectedAnswers(answer)
  }

  function handleButton() {
    if (!selectedAnswers) {
      alertError({ error: null, customMessage: 'Por favor, selecciona una respuesta.' })
      return
    }
    let addPoints = attempt === 0 ? points + selectedAnswers.firstTry : points + selectedAnswers.secondTry
    setPoints(addPoints)
    if (infiltrated) {
      setPreviousAnswers(previousAnswers.concat(selectedAnswers))
      setOpenInfiltratedAnswerDialog(true)
    } else {
      setAttempt(attempt + 1)
      setOpenAnswerDialog(true)
    }
  }

  if (!game) return <div/>
  return (
    <div className="game-container container-fluid py-5">
      <div className="container">
        <h2>{game.hintAvailable && hintAccepted && game.hint?.question ? game.hint?.question : game.question}</h2>
        {game.description !== '' && !hintAccepted && <div className="px-2" dangerouslySetInnerHTML={{ __html: game.description }} />}
        {game.hintAvailable && hintAccepted && game.hint?.description && <div className="px-2" dangerouslySetInnerHTML={{ __html: game.hint?.description }} />}
        <div className={"row mt-4" + (twoOptions ? " justify-content-center mb-7" : "")} >
        {answers ? answers.map((item, index) => (
          <div key={index} className={twoOptions ? "col-12 col-md-5 py-2" : (answers.length < 9 ? "col-12 py-2" : "col-12 col-md-4 py-2")}> {/*si length < 9 --> col-12; si length >= 9 --> col-4*/}
            <button style={{ width: twoOptions && '70%' }} key={'button-' + index} className={previousAnswers.includes(item) ? "game-answer-disabled" : (selectedAnswers?.text === item.text ? "game-answer game-answer-active" : "game-answer")}
                    onClick={() => handleClick(item)} disabled={previousAnswers.includes(item)}>
              <div dangerouslySetInnerHTML={{ __html: item.text }} />
            </button>
          </div>
        )) : <></>}
        {twoOptions && <br/>}
        {game.hintAvailable && <div className={answers?.length < 9 ? "col-12 py-2" : "col-12 col-md-4 py-2"}> {/*si length < 9 --> col-12; si length >= 9 --> col-4*/}
            <button 
              key={'button-hint'} 
              className={(hintAccepted || attempt > 0) ? "game-answer-disabled" : "game-answer"}
              onClick={() => {
                setSelectedAnswers(null)
                setOpenHintDialog(true)
              }}
              disabled={hintAccepted || attempt > 0}>
              <div dangerouslySetInnerHTML={{ __html: game.hint?.answer }} />
            </button>
          </div>}
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <button className="own-button inside-button" onClick={() => handleButton()}>
              CONFIRMAR
            </button>
          </div>
        </div>
      </div>
      <HintDialog
        open={openHintDialog}
        setOpen={setOpenHintDialog}
        onConfirm={() => {
          setHintAccepted(true)
          let _answers = [...game.answers]
          setPreviousAnswers(_answers.filter((_, index) => game.hint?.discardedAnswers?.includes(index)))
          if (game.hint?.points) setPoints(points + parseInt(game.hint?.points))
        }}
        hint={game.hint}
      />
      <AnswerDialog
        open={openAnswerDialog}
        onClose={() => {
          setOpenAnswerDialog(false)
          if ((game.maxAttempts && game.attempts <= attempt) || selectedAnswers.solution === 'correcta' || hintAccepted) {
            handleNext()
            setSelectedAnswers(null)
          }
        }}
        maxAttempts={game && game.maxAttempts && game.attempts ? game.attempts : null}
        correctAnswer={answers ? answers.find(item => item.solution === 'correcta')?.text : null}
        answer={selectedAnswers}
        attempt={attempt}
        hintAccepted={hintAccepted}
        hintAvailable={game.hintAvailable}
        superquestion={game.name === 'Superpregunta'}
        game={game}
      />
      <InfiltratedAnswerDialog
        open={openInfiltratedAnswerDialog}
        onClose={() => {
          if (selectedAnswers.solution === 'incorrecta') {
            setPoints(selectedAnswers.firstTry)
            handleNext(true)
          } else if (previousAnswers.length === (answers.length - 1)) {
            handleNext()
          }
          setSelectedAnswers(null)
          setOpenInfiltratedAnswerDialog(false)
        }}
        answer={selectedAnswers}
      />
    </div>
  )
}
