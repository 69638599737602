import React, { useState } from 'react'
import logoEuromedice from '../assets/logo_euromedice.png'
import homepage from '../assets/homepage.png'
import { useHistory } from "react-router-dom"
import logoutIcon from '../assets/logout.png'
import homeIcon from '../assets/home.png'
import { logout } from '../../api/auth/index'
import ConfirmDialog from './ConfirmDialog'
import { useDispatch } from 'react-redux'
import { authActions } from '../redux/actions/authActions'
import { alertError } from '../../utils/logger'

export default function Navbar(props) {
  const { home, clinicalCase, casesPage, loginPage } = props
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  async function doLogout() {

    try {
      const data = await logout()
      if (data.status === 200) {
        dispatch(authActions.logoutAction())
        history.push('/identificacion')
      }
    } catch (err) {
      alertError({ error: err, customMessage: 'No se ha podido cerrar la sesión.' })
    }
  }

  return (
    <nav>
      <div className="container-fluid d-flex justify-content-between p-3 pb-1">
          {!home ? <div className="col-4 col-md-6 text-start"><img src={homepage} className="img-fluid" alt="Homepage"/></div>: <div/>}
          <div className="col-4 col-md-6 text-end">
            {!clinicalCase && <img src={logoEuromedice} className="img-fluid" alt="Euromedice" />}
            {!home && !loginPage && <div>
              {!casesPage && <img src={homeIcon} className="pause d-block d-md-none m-auto p-1" alt="menu" title="Volver al menú de casos" onClick={() => history.push('/casos')} />}
              <img src={logoutIcon} className="pause d-block d-md-none m-auto p-1" alt="logout" title="Cerrar sesión" onClick={() => setOpenConfirmation(true)} />
              <div className="py-2 d-none d-md-block">
                {!casesPage && <img src={homeIcon} className="pause m-auto" alt="menu" title="Volver al menú de casos" onClick={() => history.push('/casos')} />}
                <img src={logoutIcon} className="pause m-auto" alt="logout" title="Cerrar sesión" onClick={() => setOpenConfirmation(true)} />
              </div>
            </div>}
          </div>
      </div>
      <ConfirmDialog
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        onConfirm={doLogout}
      />
    </nav>
  )
}
