import React, { useEffect, useState } from 'react'
import { getFileType, SERVER_URL } from "../../../utils"
import topIcon from '../../assets/top-icon.png'
import audioIcon from '../../assets/audio-icon.png'
import pauseAudioIcon from '../../assets/pause-audio.png'
import replayAudioIcon from '../../assets/replay-audio.png'
import downloadFile from '../../assets/download-icon.png'
import Timer from '../../components/Timer'
import bg from '../../assets/bg-game-container-1.png'
import pdfSupport from 'browser-pdf-support'
import laptopBg from '../../assets/laptop.svg'

export default function IntermediateScreen(props) {
  const {
    game,
    handleNext
  } = props

  const [fileType, setFileType] = useState('unknown')
  const [seconds, setSeconds] = useState(null)
  const [audioEnded, setAudioEnded] = useState(false)
  const [audioPlaying, setAudioPlaying] = useState(false)

  useEffect(() => {
    if (!game.timer || seconds === 0) return
    if (!seconds) setSeconds(game.seconds)
    setTimeout(() => {
      if (!seconds) setSeconds(game.seconds - 1)
      else setSeconds(seconds - 1)
    }, 1000);
  })

  /*document.getElementById('play')?.addEventListener('click', function (e) {
    e.preventDefault();
    document.getElementById('audio').play();
  });*/

  document.getElementById('audio')?.addEventListener('ended', function (e) {
    e.preventDefault();
    setAudioEnded(true)
    setAudioPlaying(false)
    document.getElementById('audio').currentTime = 0
  });

  function playAudio() {
    if (audioEnded) setAudioEnded(false)
    if (audioPlaying) {
      setAudioPlaying(false)
      document.getElementById('audio').pause();
    } else {
      document.getElementById('audio').play();
      setAudioPlaying(true)
    }
  }

  /*useEffect(() => {
    if (fileType === 'audio') playAudio()
  }, [fileType])*/

  useEffect(() => {
    if (!game) return
    setFileType(getFileType(game.filesURLs[0]))
  }, [game])

  useEffect(() => {
    if (game?.timer && game?.computerScreen && seconds === 0) handleNext()
  }, [game?.timer, game?.computerScreen, seconds])

  if (!game) return <div/>
  return (
    <div 
      className="game-container py-5" 
      style={game.fullPage && game.backgroundURL ? 
        { backgroundImage: `url('${SERVER_URL + '/' + game.backgroundURL}')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover !important'} 
        : { background: `url(${bg}) no-repeat`, backgroundSize: 'cover'}} onClick={() => game.computerScreen && handleNext()
      }>
      {game.fullPage && game.title && game.title !== '' && (
        <div className='row'>
          <h2 className={game.icon ? "offset-1 col-10 px-2" : "py-3 col-12 px-2"} style={{ color: game.noWhiteBg ? '#EF160C' : 'black' }}>{game.title}</h2>
          {game.icon && <img className="col-1 align-center" style={{ width: 'auto', height: '40px' }}
          src={game.iconURL && game.iconURL !== '' ? SERVER_URL + '/' + game.iconURL : topIcon}
          alt={'top-icon'} />}
        </div>
      )}
      {(game._id === '64ac34fd5771115194fb6545' || game._id === '64ac3d995771115194fb6760' || game._id === '64ac3da95771115194fb6768' || game._id === '64ac3f2d5771115194fb6792') && game.title && game.title !== '' && !game.fullPage && (
        <div className='html-title pb-2'>
          <h2 className={"py-3 col-12"} style={{ color: '#EF160C' }}>{game.title}</h2>
        </div>
      )}
      <div className={`${game.fullPage ? '' : 'intermediate-box'} ${game._id === '6368c2b46c6cb04d774beffd' ? 'align-text-top' : ''} container p-4`}
        style={game.computerScreen ? 
        { backgroundImage: `url('${laptopBg}')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', border: 'none', backgroundColor: 'transparent', boxShadow: 'none'} 
        : (!game.fullPage && game.backgroundURL ? 
        { backgroundImage: `url('${SERVER_URL + '/' + game.backgroundURL}')`, 
          backgroundRepeat: 'no-repeat', backgroundSize: 'cover', border: 'none', backgroundColor: 'transparent', boxShadow: 'none'} 
        : { })}>
        <div className="row" style={game.fullPage ? { background: game.noWhiteBg ? 'none' : 'white', borderRadius: '30px', padding: !game.noWhiteBg && '10px', marginTop: !game.noWhiteBg && '150px' } : {}}>
          <div className={game._id === '64ac3d995771115194fb6760' || game._id === '64ac3f2d5771115194fb6792' || game._id === '64ad3cc15771115194fb6e42' || game._id === '64b7c33294761de3b2ba621a' || game._id === '64b7c36094761de3b2ba6222' || game._id === '64b7c38a94761de3b2ba6237' ? "col-6" 
          : (game._id === '64ac43265771115194fb67f8' || game._id === '64ac43a15771115194fb680c' ? "col-6 offset-6"
          : (game.computerScreen ? "col-8 offset-2" : "col-12"))} 
          style={game._id === '64ac41915771115194fb67d9' ? { display: 'flex', alignItems: 'center', gap: '20px' } : {}}>
            {game.icon && !game.title && <img className={game._id === '64ac41915771115194fb67d9' ? 'mb-4' : 'top-icon-img mb-4'}
              style={game._id === '64ac41915771115194fb67d9' ? { maxHeight: '350px', width: 'auto' } : {}}
              src={game.iconURL && game.iconURL !== '' ? SERVER_URL + '/' + game.iconURL : topIcon}
              alt={'top-icon'} />}
            {game.timer && seconds !== null &&
              <div className={game.noWhiteBg ? "mb-4" : "col-2 offset-5"}>
                <Timer seconds={seconds} intermediateScreen={true} circularTimer={game.noWhiteBg}/>
              </div>}
            {game._id !== '64ac34fd5771115194fb6545' && game._id !== '64ac3d995771115194fb6760' && game._id !== '64ac3da95771115194fb6768' && game._id !== '64ac3f2d5771115194fb6792' && game.title && game.title !== '' && !game.fullPage && (
              <div className='row'>
                <h2 className={game.icon ? "offset-1 col-10" : "py-3 col-12"} style={{ color: '#EF160C' }}>{game.title}</h2>
                {game.icon && <img className="col-1 align-center" style={{ width: 'auto', height: '40px' }}
                src={game.iconURL && game.iconURL !== '' ? SERVER_URL + '/' + game.iconURL : topIcon}
                alt={'top-icon'} />}
              </div>
            )}
            {game.description !== '' && <div className={game.fullPage && !game.noWhiteBg ? "row-full-page intermediate-screen" : "intermediate-screen"} dangerouslySetInnerHTML={{ __html: game.description }} />}
            {game.timer && seconds === 0 ?
              <></>
              : fileType === 'image' ? (
                <img
                className="img-fluid py-3"
                //style={!game.button ? { cursor: 'pointer'} : {}}
                src={SERVER_URL + '/' + game.filesURLs[0]}
                //onClick={() => !game.button ? handleNext() : {}}
                alt={'intermediate-screen'} />
            ) : fileType === 'video' ? (
              <div className="embed-responsive embed-responsive-21by9">
                <video className="embed-responsive-item" allowFullScreen controls>
                  <source src={SERVER_URL + '/' + game.filesURLs[0]} type="video/mp4" />
                </video>
              </div>
            ) : fileType === 'audio' ? (
              <>
                <audio id="audio" src={SERVER_URL + '/' + game.filesURLs[0]}/>
                <div className='row align-center pb-3'>
                  <p className="offset-7 col-3" style={{ color: '#004e7d' }}>{audioEnded ? 'Volver a escuchar.' : (audioPlaying ? 'Haz clic en el icono para pausar el audio.' : 'Haz clic en el icono para escuchar el audio.') }</p>
                  <img
                    className="text-end pb-3 top-icon col-2"
                    onClick={() => playAudio()}
                    src={audioPlaying ? pauseAudioIcon : (audioEnded ? replayAudioIcon : audioIcon)}
                    alt={'audio-icon'} />
                </div>
                <div className='row justify-content-around'>
                  <img
                    className="img-fluid col-md-3 p-3"
                    style={{ marginTop: '-70px', height: 'fit-content' }}
                    src={game.iconURL && game.iconURL !== '' ? SERVER_URL + '/' + game.iconURL : audioIcon}
                    alt={'audio-icon'} />
                  <hr class={"hr-vertical-small" + ((game._id === '6368ef626c6cb04d774bf1df' || game._id === '6368ecbd6c6cb04d774bf1a8') ? " smaller" : "") + (game._id === '64ac3a245771115194fb669f' ? " bigger" : "")}/>
                  <div className={"audio-transcription-playing col-md-7"} dangerouslySetInnerHTML={{ __html: game?.audioTranscription }} />
                </div>
              </>
            ) : fileType === 'file' ? (
              <>
                {pdfSupport() ? <embed className="game-file" src={SERVER_URL + '/' + game.filesURLs[0]} type="application/pdf"/> :               
                  <a target="_blank" rel="noreferrer" href={SERVER_URL + '/' + game.filesURLs[0]}><img
                  src={downloadFile}
                  alt={'file-icon'} /></a>}
                <a target="_blank" rel="noreferrer" href={SERVER_URL + '/' + game.filesURLs[0]}><img
                  className="game-file-mobile"
                  src={downloadFile}
                  alt={'file-icon'} /></a>
              </>
            ) : <></>}
            {game.text && game.text !== '' && <div className="mt-2 intermediate-screen" dangerouslySetInnerHTML={{ __html: game.text }} />}
          </div>
        </div>
      </div> 
      <div className="container">
        {game.button && <div className="row mt-4">
          <div className="col-12">
            <button className="own-button inside-button" onClick={handleNext}>
              CONTINUAR
            </button>
          </div>
        </div>}
      </div>
    </div>
  )
}
