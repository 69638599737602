import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    stepIconRoot: {
        fill: '#36b6de'
    },
    stepIconRootHidden: {
        display: 'none'
    },
    step: {
        border: '#36b6de 2px solid',
        padding: '10px'
    },
    titleStep: {
        backgroundColor: '#36b6de',
        padding: '10px'
    }
}));

export default function VerticalStepper({ answers }) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
            {answers.map((answer, index) => (
                <Step key={answer.text} className={index !== 0 ? classes.step : classes.titleStep}>
                    <StepLabel StepIconProps={{ classes: { root: index !== 0 ? classes.stepIconRoot : classes.stepIconRootHidden }, icon: index }} onClick={() => setActiveStep(index)}>
                        <h5 style={{ fontWeight: 'bold' }}>{answer.text}</h5>
                    </StepLabel>
                    <StepContent>
                        <Typography>
                            <div dangerouslySetInnerHTML={{ __html: answer.description }} />
                        </Typography>
                    </StepContent>
                </Step>
            ))}
            </Stepper>
        </div>
    );
}
