import React from 'react'
import { Dialog } from '@material-ui/core'
import trueChecked from '../assets/V_1.png'
import falseChecked from '../assets/F_1.png'
import bg from '../assets/background.png'


export default function NotAllAnswersSelectedDialog(props) {
  const { open, onClose } = props

  return (
    <Dialog
      style={{ background: `url(${bg}) no-repeat`, backgroundSize: 'cover' }}
      open={open}
      fullWidth={true}
      maxWidth="md"
      onClose={() => { }}
      aria-labelledby="answer-dialog">
      <div className="container-fluid text-center px-5 py-4">
        <div className="row">
          <div className="col-12">
            <p style={{ fontSize: '1.5rem' }}>Tienes que asignar <img className="true-false-icon mx-2" src={trueChecked} alt="icon-true-false"/> o <img className="true-false-icon mx-2" src={falseChecked} alt="icon-true-false"/> a todas las afirmaciones antes de pulsar este botón <button className="own-button inside-button-display " disabled>CONFIRMAR</button>.</p>
            <div className="row py-3 text-center">
              <div className="col-12">
                <button className="own-button inside-button" onClick={() => onClose()}>
                  CONTINUAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
