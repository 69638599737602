import React, { useEffect, useState } from 'react'
import homepage from '../assets/homepage.png'
import pausePlay from '../assets/pause.png'
import logoutIcon from '../assets/logout.png'
import homeIcon from '../assets/home.png'
import i1 from '../assets/1.png'
import i2 from '../assets/2.png'
import i3 from '../assets/3.png'
import i4 from '../assets/4.png'
import i5 from '../assets/5.png'
import i2off from '../assets/2-off.png'
import i3off from '../assets/3-off.png'
import i4off from '../assets/4-off.png'
import i5off from '../assets/5-off.png'
import i1on from '../assets/1-on.png'
import i2on from '../assets/2-on.png'
import i3on from '../assets/3-on.png'
import i4on from '../assets/4-on.png'
import i5on from '../assets/5-on.png'
import PauseDialog from './PauseDialog'
import Timer from './Timer'
import { useParams } from "react-router-dom"
import { getScenarios } from "../../api/scenarios"
import { shallowEqual, useSelector } from "react-redux"
import { logout } from '../../api/auth/index'
import { useHistory } from "react-router-dom"
import { useDispatch } from 'react-redux'
import { authActions } from '../redux/actions/authActions'
import { alertError } from '../../utils/logger'
import ConfirmDialog from './ConfirmDialog'

function getData(scenarios, progress) {
  let data = []
  for (let i = 0; i < scenarios.length; ++i) {
    const elem = {}
    elem.blocked = !progress || !progress.scenariosCompleted ? i !== 0 : progress.scenariosCompleted.length < i
    elem.name = scenarios[i].name
    elem.id = scenarios[i]._id
    data = data.concat(elem)
  }
  return data
}

export default function GameNavbar(props) {
  const { home, seconds, setSeconds, stopTimer, hideTimer, hideProgress } = props
  const [pause, setPause] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [scenarios, setScenarios] = useState([])
  const id = useParams().id
  const user = useSelector((store) => store.authenticationWebMistuiery?.user, shallowEqual)
  const scenarioId = useParams()?.scenarioId
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (pause || stopTimer || hideTimer) return
    setTimeout(() => {
      if (seconds >= 3600) return
      setSeconds(seconds + 1)
    }, 1000);
  })

  useEffect(() => {
    if (!id) return
    getScenarios(id).then(res => {
      if (res.status === 200) {
        setScenarios(getData(res.data, user?.progress ? user.progress[id] : null))
      } else {
        console.log('Could not get any scenario')
      }
    })
  }, [id, user])


  useEffect(() => {
    if (!user || hideTimer) return
    if (user.progress && user.progress[id]) {
      if (user.progress[id].seconds) setSeconds(user.progress[id].seconds)
    }
  }, [id, user])

  async function doLogout() {
    try {
      const data = await logout()
      if (data.status === 200) {
        dispatch(authActions.logoutAction())
        history.push('/identificacion')
      }
    } catch (err) {
      alertError({ error: err, customMessage: 'No se ha podido cerrar la sesión.' })
    }
  }


  return (
    <nav>
      <div className={`container-fluid d-flex ${home && hideProgress && hideTimer ? 'justify-content-end': 'justify-content-between'} p-3 pb-1`}>
        {!home && <div><img src={homepage} className="img-fluid" alt="Homepage"/></div>}
        {!hideProgress && <div className="col-0 col-md-8 d-flex justify-content-around text-white px-5">
          <div className="col d-none d-md-block step-col"><img src={scenarioId && scenarioId === scenarios[0]?.id ? i1 : i1on} alt="step" className="img-fluid step" /><p>{scenarios[0]?.name}</p></div>
          <div className="col d-none d-md-block step-col"><img src={scenarioId && scenarioId === scenarios[1]?.id ? i2 : (scenarios[1]?.blocked ? i2off : i2on)} alt="step" className="img-fluid step" /><p>{scenarios[1]?.name}</p></div>
          <div className="col d-none d-md-block step-col"><img src={scenarioId && scenarioId === scenarios[2]?.id ? i3 : (scenarios[2]?.blocked ? i3off : i3on)} alt="step" className="img-fluid step" /><p>{scenarios[2]?.name}</p></div>
          <div className="col d-none d-md-block step-col"><img src={scenarioId && scenarioId === scenarios[3]?.id ? i4 : (scenarios[3]?.blocked ? i4off : i4on)} alt="step" className="img-fluid step" /><p>{scenarios[3]?.name}</p></div>
          <div className="col d-none d-md-block step-col"><img src={scenarioId && scenarioId === scenarios[4]?.id ? i5 : (scenarios[4]?.blocked ? i5off : i5on)} alt="step" className="img-fluid step" /><p>{scenarios[4]?.name}</p></div>
        </div>}
        <div>
          {!hideTimer && <Timer seconds={seconds} />}
          <img src={homeIcon} className="pause d-block d-md-none m-auto p-1" alt="menu" title="Volver al menú de casos" onClick={() => history.push('/casos')} />
          {!hideTimer && <img src={pausePlay} className="pause d-block d-md-none m-auto p-1" alt="pause" title="Pausar la actividad" onClick={() => setPause(true)} />}
          <img src={logoutIcon} className="pause d-block d-md-none m-auto p-1" alt="logout" title="Cerrar sesión" onClick={() => setOpenConfirmation(true)} />
          <div className="py-2 d-none d-md-block">
            <img src={homeIcon} className="pause m-auto" alt="menu" title="Volver al menú de casos" onClick={() => history.push('/casos')} />
            {!hideTimer && <img src={pausePlay} className="pause m-auto" alt="pause" title="Pausar la actividad" onClick={() => setPause(true)} />}
            <img src={logoutIcon} className="pause m-auto" alt="logout" title="Cerrar sesión" onClick={() => setOpenConfirmation(true)} />
          </div>
        </div>
        <PauseDialog
          open={pause}
          setOpen={setPause}
          seconds={seconds}
        />
        <ConfirmDialog
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          onConfirm={doLogout}
        />
      </div>
    </nav>
  )
}
