import React, { useState, useEffect } from 'react'
import DraggableMultiColumns from '../../components/draggable/DraggableMultiColumns';
import DragAndDropCompleted from '../../components/draggable/DragAndDropCompleted'
import LinkConceptsDialog from "../../components/LinkConceptsDialog"
import bg from '../../assets/bg-game-container-3.png'

export default function LinkImagesAndConcepts(props) {
  const {
    game,
    handleNext,
    points,
    setPoints
  } = props
  const [selectedAnswer, setSelectedAnswer] = useState(null)
  const [openAnswerDialog, setOpenAnswerDialog] = useState(false)
  const [correctAnswer, setCorrectAnswer] = useState(false)
  const [openGameCompleted, setOpenGameCompleted] = useState(false)
  const [attempt, setAttempt] = useState({})
  const [answers, setAnswers] = useState(null)
  const [options, setOptions] = useState(null)

  useEffect(() => {
    if (!game || !game.answers || !game.answers.length) return
    let newOptions = game.answers
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => ({ ...value, matched: false }))
    setAnswers(newOptions)
    newOptions = game.answers
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => ({ ...value, matched: false }))
    setOptions(newOptions)
    setAttempt({})
  }, [game])

  function handleMatch({ source, destination }) {
    const newAttempt = attempt[source.match] === undefined ? 0 : attempt[source.match] + 1
    setSelectedAnswer(source)
    if (source.match === destination.match) {
      setCorrectAnswer(true)
      const addPoints = newAttempt === 0 ? points + source.firstTry : points + source.secondTry
      setPoints(addPoints)
      let _answers = answers
      const index = _answers.findIndex(item => item.match === source.match)
      _answers[index] = {..._answers[index], matched: true }
      setAnswers(_answers)
      let _options = options
      const index2 = _options.findIndex(item => item.match === source.match)
      _options[index2] = {..._options[index2], matched: true }
      setOptions(_options)
    }
    setAttempt({...attempt, [source.match]: newAttempt })
    setOpenAnswerDialog(true)
  }

  if (!game) return <div/>
  return (
    <div className="game-container container-fluid py-5" style={{ backgroundImage: `url('${bg}')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover !important'}}>
      <div className="container">
        {game.question && <h2>{game.question}</h2>}
        {game.description !== '' && <div dangerouslySetInnerHTML={{ __html: game.description }} />}
        <DraggableMultiColumns
          answers={answers}
          options={options}
          handleMatch={handleMatch}
        />
      </div>
      <LinkConceptsDialog
        open={openAnswerDialog}
        onClose={() => {
          if (!answers.find(item => !item.matched)) {
            setOpenGameCompleted(true)
          }
          setSelectedAnswer(null)
          setCorrectAnswer(false)
          setOpenAnswerDialog(false)
        }}
        answer={selectedAnswer}
        attempt={attempt[selectedAnswer?.match]}
        correctAnswer={correctAnswer}
      />
      <DragAndDropCompleted
        onClose={handleNext}
        open={openGameCompleted}
        points={points}
      />
    </div>
  )
}
