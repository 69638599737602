import React from 'react'
import {Dialog, DialogContent, DialogTitle} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const SupportDialog = (props) => {
  const { open, setOpen } = props
  return (
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="lg"
        onClose={() => {}}
        aria-labelledby="support-dialog">
        <div className="container-fluid pt-3 pb-5" style={{ textAlign: 'center' }}>
          <DialogTitle disableTypography className="dialog-title">
            <CloseIcon className="close-dialog-icon" onClick={() => setOpen(null)}/>
          </DialogTitle>
          <DialogContent>
            <h4 className="game-rules-title mb-4">SOPORTE TÉCNICO</h4>
            <p>Si tienes cualquier duda, incidencia o comentario, por favor envia un correo electrónico a <a href="mailto:info@mistuiery.com">info@mistuiery.com</a></p>
            <button className="own-button inside-button mt-4" onClick={() => setOpen(null)}>
              CERRAR
            </button>
          </DialogContent>
        </div>
      </Dialog>
  )
}
export default SupportDialog
