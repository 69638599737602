import { API, authClient } from '../../utils/index'

export const login = (credentials) => {
  return authClient().post(`${API}/user/signin`, credentials)
}

export const logout = () => {
  return authClient().get(`${API}/user/logout`)
}

export const getCredentials = () => {
  return authClient().get(`${API}/user/credentials`)
}

export const refreshTokens = () => {
  return authClient().get(`${API}/user/refresh`)
}

export const register = (user) => {
  return authClient().post(`${API}/user/signup`, user)
}