import React from 'react'
import { Dialog } from '@material-ui/core'
import testApproved from "../assets/fmc-test-approved.png"
import tryAgain from "../assets/fmc-test.png"
import testFailed from "../assets/fmc-test-failed.png"
import downloadDiploma from "../assets/downloadDiploma.png"
import c1 from '../assets/certificado_c1.pdf'
import c2 from '../assets/certificado_c2.pdf'
import c3 from '../assets/certificado_c3.pdf'
import c4 from '../assets/certificado_c4.pdf'
import c5 from '../assets/certificado_c5.pdf'
import { WEB_URL, modifyPdf } from "../../utils"
import { useDispatch } from 'react-redux'
import { authActions } from "../redux/actions/authActions";

export default function FMCDialog(props) {
  const { open, result, attempt, onClose, seeCorrectAnswers, certificate, clinicalCaseId, user } = props
  const dispatch = useDispatch()

  // FIXME
  async function handleDownloadCertificate() {
    //await modifyPdf(`${SERVER_URL}/${clinicalCase.certificateURL}`)
    // DIPLOMA DEL CASO 6 SOLO DISPONIBLE PARA USUARIOS DEL DOMINIO ASTELLAS / OWIUS / VIVACTIS / MISTUIERY
    if (clinicalCaseId === '61a49b7a2aa0fd09eb3882e7' && !user.email.includes('@astellas.com') && !user.email.includes('@vivactis.') && !user.email.includes('@owius.com') && !user.email.includes('@mistuiery.com')) return 
    const ccase = clinicalCaseId === '615ed2dbe033ca1285e0b89f' ? 1 
    : (clinicalCaseId === '61a0dc581137d44c9265b738' ? 2 
    : (clinicalCaseId ===  '61a499ba2aa0fd09eb3882cd' ? 3 
    : (clinicalCaseId === '61a49af22aa0fd09eb3882da' ? 4 
    : (clinicalCaseId === '61a49b592aa0fd09eb3882e2' ? 5 
    : undefined))))
    if (ccase === undefined) return
    const userUpdated = await modifyPdf(`${WEB_URL}${ccase === 1 ? c1 : (ccase === 2 ? c2 : (ccase === 3 ? c3 : (ccase === 4 ? c4 : c5)))}`, ccase, user, clinicalCaseId)
    if (userUpdated) dispatch(authActions.refreshUserData(userUpdated))
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="md"
      onClose={() => { }}
      aria-labelledby="answer-dialog">
      <div className="container-fluid text-center p-5">
        <div className="row">
          <div className="col-12">
            {result >= 0.8 ? (
              <>
                <img className="top-icon mb-4"
                  src={testApproved}
                  alt={'top-icon'} />
                <h4 style={{ color: '#004e7d' }}>¡Enhorabuena!</h4>
                <h4 style={{ color: '#004e7d' }}>Has superado el test de acreditación.</h4>
                <h4 style={{ color: '#004e7d' }}>Para este proyecto se ha solicitado acreditación a la Comisión de Formación Continuada de las Profesiones Sanitarias de la Comunidad de Madrid.</h4>
                <h4 style={{ color: '#004e7d' }}>{!certificate ? 'El certificado con los créditos obtenidos aún no está disponible. En breve podrás descargarlo.' : 'Haz clic en la imagen para descargar el certificado.'}</h4>
                {certificate && <img className="top-icon mb-4"
                     src={downloadDiploma}
                     style={{ cursor: 'pointer' }}
                     onClick={() => handleDownloadCertificate()}
                     alt={'top-icon'} />}
                <br/>
                <button className="own-button inside-button" onClick={() => seeCorrectAnswers()}>
                  {'Ver respuestas correctas'}
                </button>
              </>
            ) : (attempt === 1 ? (
              <>
                <img className="top-icon mb-4"
                  src={tryAgain}
                  alt={'top-icon'} />
                <h4 style={{ color: '#004e7d' }}>¡Vaya!</h4>
                <h4 style={{ color: '#004e7d' }}>No has superado el test de acreditación</h4>
                <h4>Te queda un intento</h4>
              </>
            ) : (
              <>
                <img className="top-icon mb-4"
                  src={testFailed}
                  alt={'top-icon'} />
                <h4 style={{ color: '#004e7d' }}>No has superado el test de acreditación</h4>
                <button className="own-button inside-button" onClick={() => seeCorrectAnswers()}>
                  {'Ver respuestas correctas'}
                </button>
              </>)
            )}
            <div className="row py-5 text-center">
              <div className="col-12">
                <button className="own-button inside-button" onClick={() => onClose(result > 0.8 || attempt === 2)}>
                  {result > 0.8 || attempt === 2 ? 'Volver al menú principal' : 'Volver a intentar'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
