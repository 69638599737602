// import {all} from "redux-saga/effects";
import { combineReducers } from "redux";

// import * as auth from "../app/modules/Auth/_redux/authRedux";

import { authenticationWebMistuiery } from "./reducers/authReducer";
import { userConstants } from "./constants";

const appReducer = combineReducers({
  authenticationWebMistuiery
})

export const rootReducer = (state, action) => {
  // If logout action is dispatched, we dispatch undefined state so it takes initialStateValue (reset storage)
   if (action.type === userConstants.LOGOUT)
    // storage.removeItem('persist:root')
    return appReducer (undefined, action)

  return appReducer(state, action)
}

export function* rootSaga() {
  // yield all([auth.saga()]);
}
