import React, { useState, useEffect } from 'react'
import LinkConceptsDialog from "../../components/LinkConceptsDialog"
import { alertError } from "../../../utils/logger"

export default function LinkConcepts(props) {
  const {
    game,
    handleNext,
    points,
    setPoints
  } = props
  const [selectedAnswer, setSelectedAnswer] = useState(null)
  const [selectedMatch, setSelectedMatch] = useState(null)
  const [linkedAnswers, setLinkedAnswers] = useState([])
  const [openAnswerDialog, setOpenAnswerDialog] = useState(false)
  const [correctAnswer, setCorrectAnswer] = useState(false)
  const [attempt, setAttempt] = useState({})
  const [answers, setAnswers] = useState(null)
  const [options, setOptions] = useState(null)

  useEffect(() => {
    if (!game || !game.answers || !game.answers.length) return
    let newOptions = game.answers
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)
    setAnswers(newOptions)
    newOptions = game.answers
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)
    setOptions(newOptions)
    setAttempt({})
  }, [game])

  function handleButton() {
    if (!selectedAnswer || !selectedMatch) {
      alertError({ error: null, customMessage: 'Por favor, relaciona una etiqueta de la izquierda con una de la derecha.' })
      return
    }
    const newAttempt = attempt[selectedAnswer.text] === undefined ? 0 : attempt[selectedAnswer.text] + 1
    if (selectedAnswer.match === selectedMatch.match) {
      setCorrectAnswer(true)
      const addPoints = newAttempt === 0 ? points + selectedAnswer.firstTry : points + selectedAnswer.secondTry
      setPoints(addPoints)
      setLinkedAnswers(linkedAnswers.concat(selectedAnswer))
    }
    setAttempt({...attempt, [selectedAnswer.text]: newAttempt })
    setOpenAnswerDialog(true)
  }

  if (!game) return <div/>
  return (
    <div className="game-container container-fluid py-5">
      <div className="container">
        <h2>{game.question}</h2>
        {game.description !== '' && <div dangerouslySetInnerHTML={{ __html: game.description }} />}
        <div className="row mt-4">
          <div className="col-6">
            {answers ? answers.map((item, index) => (
              <div key={'answer'+index} className="col-12 py-2">
                <button key={'button-' + index} className={linkedAnswers.length && linkedAnswers.includes(item) ? "game-answer-disabled" : (selectedAnswer?.text === item.text ? "game-answer game-answer-active" : "game-answer")}
                        onClick={() => setSelectedAnswer(item)} disabled={linkedAnswers.length && linkedAnswers.includes(item)}>
                  <div dangerouslySetInnerHTML={{ __html: item.text }} />
                </button>
              </div>
            )) : <></>}
          </div>
          <div className="col-6">
            {options ? options.map((item, index) => (
              <div key={'solution'+index} className="col-12 py-2">
                <button key={'button-' + index} className={linkedAnswers.length && linkedAnswers.includes(item) ? "game-answer-disabled" : (selectedMatch?.match === item.match ? "game-answer game-answer-active py-2" : "game-answer")}
                        onClick={() => setSelectedMatch(item)} disabled={linkedAnswers.length && linkedAnswers.includes(item)}>
                  <div dangerouslySetInnerHTML={{ __html: item.match }} />
                </button>
              </div>
            )) : <></>}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <button className="own-button inside-button" onClick={() => handleButton()}>
              Confirmar
            </button>
          </div>
        </div>
      </div>
      <LinkConceptsDialog
        open={openAnswerDialog}
        onClose={() => {
          if (linkedAnswers.length === game.answers.length) {
            handleNext()
          }
          setSelectedAnswer(null)
          setSelectedMatch(null)
          setCorrectAnswer(false)
          setOpenAnswerDialog(false)
        }}
        answer={selectedAnswer}
        attempt={attempt[selectedAnswer?.text]}
        correctAnswer={correctAnswer}
      />
    </div>
  )
}
