import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import NavBar from "../components/NavBar"
import { getClinicalCaseById/*, getClinicalCaseCertificate*/ } from "../../api/clinicalCases"
import { SERVER_URL, WEB_URL, modifyPdf } from "../../utils"
import { updateUserProgress } from "../../api/user";
import { shallowEqual, useSelector } from "react-redux";
import acreditationBlocked from '../assets/acreditation-blocked.png'
//import fmcBlocked from '../assets/fmc-blocked.png'
//import satisfactionBlocked from '../assets/satisfaction-blocked.png'
import diplomaBlocked from '../assets/diploma-blocked.png'
import acreditation from '../assets/acreditation-icon.png'
import fmc from '../assets/fmc-icon.png'
import diploma from '../assets/diploma-icon.png'
import satisfaction from '../assets/satisfaction-icon.png'
import ranking from '../assets/ranking.png'
import goals from '../assets/goals.png'
import { alertError } from "../../utils/logger";
import GoalsDialog from "../components/GoalsDialog";
import c1 from '../assets/certificado_c1.pdf'
import c2 from '../assets/certificado_c2.pdf'
import c3 from '../assets/certificado_c3.pdf'
import c4 from '../assets/certificado_c4.pdf'
import c5 from '../assets/certificado_c5.pdf'
import { useDispatch } from 'react-redux'
import { authActions } from "../redux/actions/authActions";

export default function ClinicalCaseVideo() {
  const history = useHistory()
  const dispatch = useDispatch()
  const [clinicalCase, setClinicalCase] = useState(null)
  const id = useParams().id
  const user = useSelector((store) => store.authenticationWebMistuiery?.user, shallowEqual)
  const [formacionBlocked, setFormacionBlocked] = useState(true)
  const [satisfaccionBlocked, setSatisfaccionBlocked] = useState(true)
  const [acreditacionBlocked, setAcreditacionBlocked] = useState(true)
  const [diplomaDownloadBlocked, setDiplomaDownloadBlocked] = useState(true)
  const [openDialog, setOpenDialog] = useState(false)
  const [showContinueButton, setShowContinueButton] = useState(false)

  useEffect(() => {
    getClinicalCaseById(id).then(res => {
      if (res.status === 200) {
        setClinicalCase(res.data)
      } else {
        console.log('Could not get any clinical case')
      }
    })
  }, [id])

  useEffect(() => {
    if (!user) return
    let userProgress = {}
    if (!user.progress) {
      userProgress = {
        [id]: {
          seconds: 0,
          lastGame: '',
          points: 0,
          scenariosCompleted: []
        }
      }
    } else if (user.progress && !user.progress[id]) {
      userProgress = {
        ...user.progress,
        [id]: {
          seconds: 0,
          lastGame: '',
          points: 0,
          scenariosCompleted: []
        }
      }
    }
    updateUserProgress(user.email, userProgress).then(res => {
      if (res.status === 200) {
        /*const newUser = user
        newUser.progress = userProgress
        dispatch(authActions.refreshUserData(newUser))*/
        //console.log('User progress updated')
      } else console.log('Could not update user progress')
    })
  }, [id, user])

  useEffect(() => {
    if (!user || !clinicalCase) return
    // DIPLOMA DEL CASO 6 SOLO DISPONIBLE PARA USUARIOS DEL DOMINIO ASTELLAS / OWIUS / VIVACTIS / MISTUIERY
    if (clinicalCase._id === '61a49b7a2aa0fd09eb3882e7' && !user.email.includes('@astellas.com') && !user.email.includes('@vivactis.') && !user.email.includes('@owius.com') && !user.email.includes('@mistuiery.com')) {
      setAcreditacionBlocked(true)
      setDiplomaDownloadBlocked(true)
      return
    }
    setFormacionBlocked(user && ((user.clinicalCasesCompleted && !user.clinicalCasesCompleted.includes(id)) || !user.clinicalCasesCompleted))
    setSatisfaccionBlocked(user && ((user.clinicalCasesCompleted && !user.clinicalCasesCompleted.includes(id)) || (user.acreditation && user.acreditation[id] && user.acreditation[id].satisfactionTestDone)))
    setAcreditacionBlocked(user && ((user.clinicalCasesCompleted && !user.clinicalCasesCompleted.includes(id)) || (!user.acreditation || !user.acreditation[id]) || (user.acreditation && user.acreditation[id] && (user.acreditation[id].acreditationTest?.done))))
    setDiplomaDownloadBlocked(user && ((user.clinicalCasesCompleted && !user.clinicalCasesCompleted.includes(id)) || (!user.acreditation || !user.acreditation[id]) || (user.acreditation && user.acreditation[id] && !user.acreditation[id].downloadDiploma)))
  }, [id, user, clinicalCase])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!clinicalCase) return
    document.getElementById('intro-video')?.addEventListener('play', (event) => {
      setShowContinueButton(true)
    });
  }, [clinicalCase])

  function handleError(icon) {
    if (!formacionBlocked) {
      if (icon === 'satisfaction-test') alertError({ error: null, customMessage: 'Ya has contestado la encuesta de satisfacción' })
      else if (icon === 'acreditation-test') {
        if (!satisfaccionBlocked) alertError({ error: null, customMessage: 'Primero debes contestar la encuesta de satisfacción' })
        else {
          if (diplomaDownloadBlocked) alertError({ error: null, customMessage: 'Ya has realizado los dos intentos de acreditación' })
          else alertError({ error: null, customMessage: 'Ya has aprobado el test de acreditación' })
        }
      }
      else if (icon === 'diploma') {
        if (!satisfaccionBlocked) {
          alertError({
            error: null, customMessage: 'No has contestado el test de satisfacción, no puedes descargar el certificado.'
          })
        } else if (!acreditacionBlocked || diplomaDownloadBlocked) {
          alertError({
            error: null, customMessage: 'No has superado el test de acreditación, no puedes descargar el certificado.'
          })
        } else if (!clinicalCase.certificateURL) alertError({
          error: null, customMessage: 'Para este proyecto se ha solicitado acreditación a la Comisión de Formación Continuada de las Profesiones Sanitarias de la Comunidad de Madrid.\n' +
          '\n' +
          'El certificado con los créditos obtenidos aún no está disponible. En breve podrás descargarlo.'
        })
      }
      return
    }
    alertError({ error: null, customMessage: 'Aún no puedes acceder aquí!' })
  }

  // FIXME
  async function handleDownloadCertificate() {
    //await modifyPdf(`${SERVER_URL}/${clinicalCase.certificateURL}`)
    // DIPLOMA DEL CASO 6 SOLO DISPONIBLE PARA USUARIOS DEL DOMINIO ASTELLAS / OWIUS / VIVACTIS / MISTUIERY
    if (clinicalCase._id === '61a49b7a2aa0fd09eb3882e7' && !user.email.includes('@astellas.com') && !user.email.includes('@vivactis.') && !user.email.includes('@owius.com') && !user.email.includes('@mistuiery.com')) return
    const ccase = clinicalCase._id === '615ed2dbe033ca1285e0b89f' ? 1 
    : (clinicalCase._id === '61a0dc581137d44c9265b738' ? 2 
    : (clinicalCase._id ===  '61a499ba2aa0fd09eb3882cd' ? 3 
    : (clinicalCase._id === '61a49af22aa0fd09eb3882da' ? 4 
    : (clinicalCase._id === '61a49b592aa0fd09eb3882e2' ? 5 
    : undefined))))
    if (ccase === undefined) return    
    const userUpdated = await modifyPdf(`${WEB_URL}${ccase === 1 ? c1 : (ccase === 2 ? c2 : (ccase === 3 ? c3 : (ccase === 4 ? c4 : c5)))}`, ccase, user, clinicalCase._id)
    if (userUpdated) dispatch(authActions.refreshUserData(userUpdated))
  }

  return (
    <>
      <NavBar clinicalCase={true} />
      <div className="container mt-2">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-4 col-md-2 text-center">
                <img src={ranking} className="fmc-icon" alt="fmc" onClick={() => history.push('/casos/' + id + '/ranking')} />
                <p className="game-rules-text mt-2" style={{ fontStyle: 'italic' }}>Ranking</p>
              </div>
              <div className="col-4 col-md-2 text-center">
                <img src={goals} className="fmc-icon" alt="fmc" onClick={() => setOpenDialog(true)} />
                <p className="game-rules-text mt-2">Objetivos</p>
              </div>
              <div className="col-4 col-md-2 text-center">
                <img src={/*!formacionBlocked ? */fmc /*: fmcBlocked*/} className="fmc-icon" alt="fmc" onClick={() => /*formacionBlocked ? handleError() : */history.push('/casos/' + id + '/formacion')} />
                <p className="game-rules-text mt-2">Marco teórico</p>
              </div>
              <div className="col-4 col-md-2 text-center">
                <img src={/*!satisfaccionBlocked ? */satisfaction /*: satisfactionBlocked*/} className="fmc-icon" alt="satisfaction" onClick={() => /*satisfaccionBlocked ? handleError('satisfaction-test') :*/ history.push('/casos/' + id + '/test-valoracion')} />
                <p className="game-rules-text mt-2">Satisfacción</p>
              </div>
              <div className="col-4 col-md-2 text-center">
                <img src={!acreditacionBlocked ? acreditation : acreditationBlocked} className="fmc-icon" alt="acreditation" onClick={() => acreditacionBlocked ? handleError('acreditation-test') : history.push('/casos/' + id + '/test-acreditacion')} />
                <p className="game-rules-text mt-2">Acreditación</p>
              </div>
              <div className="col-4 col-md-2 text-center">
                <img src={!diplomaDownloadBlocked ? diploma : diplomaBlocked} className="fmc-icon" alt="diploma" onClick={() => (diplomaDownloadBlocked || !clinicalCase.certificateURL) ? handleError('diploma') : handleDownloadCertificate()} />
                <p className="game-rules-text mt-2">Certificado</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="embed-responsive embed-responsive-16by9">
              {clinicalCase && <video id="intro-video" className="preview-video" controls autoPlay>
                <source src={`${SERVER_URL}/${clinicalCase?.videoURL}`} />
              </video>}
            </div>
          </div>
        </div>
        {showContinueButton && <div className="row mt-1">
          <div className="col-12">
            <button className="own-button home-button" onClick={() => history.push('/casos/' + id + '/intro')}>
              COMENZAR
            </button>
          </div>
        </div>}
      </div>
      <GoalsDialog
        open={openDialog}
        setOpen={setOpenDialog}
        goals={clinicalCase?.goals}
      />
    </>
  )
}
