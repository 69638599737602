import React, { useEffect, useState } from 'react'
import bg from '../../assets/bg-game-container-3.png'
import DraggableAlgorithm from '../../components/draggable/algorithm/DraggableAlgorithm'
import AlgorithmAnswerDialog from '../../components/AlgorithmAnswerDialog'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'

export default function AlgorithmPuzzle(props) {
  const {
    game,
    handleNext,
    points,
    setPoints
  } = props

  const [draggedOptions, setDraggedOptions] = useState([])
  const [draggableOptions, setDraggableOptions] = useState([])
  const [groups, setGroups] = useState([])
  const [openAnswerDialog, setOpenAnswerDialog] = useState(false)
  const [allCorrect, setAllCorrect] = useState(true)

  useEffect(() => {
    if (!game || !game.answers) return
    let _options = []
    let _groups = []
    game.answers.forEach(item => {
      _options = _options.concat(item.options.filter(option => !option.fixed).map(option => ({...option, dragged: false })))
      _groups.push(item.group)
    })
    _options = _options
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)
    setDraggableOptions(_options)
    setGroups(_groups)
  }, [game])

  const handleNextButton = () => {
    let _points = 0
    game.answers.forEach(answer => {
      answer.options.forEach(option => {
        const elem = draggedOptions.find(item => item.item === option.text)
        if (elem) {
          const group = elem.group?.split('-group:')
          if (group && group[1] && group[1] === answer.group) _points = _points + parseInt(option.points)
          else setAllCorrect(false)
        }
      })
    })
    setPoints(points + _points)
    setOpenAnswerDialog(true)
  }


  if (!game) return <div/>
  return (
    <div className="game-container py-5" style={{ backgroundImage: `url(${bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover !important', backgroundColor: 'transparent' }}>
      <div className={`container p-4`}>
        <div className="row">
          <div className={game.computerScreen ? "col-8 offset-2" : "col-12"}>
            {game.description !== '' && <div className="intermediate-screen" dangerouslySetInnerHTML={{ __html: game.description }} />}
          </div>
        </div>
        <div className='row'>
          <div className='col-1' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', maxWidth: '5%' }}>
            <ArrowUpwardIcon style={{ color: 'white', height: '3rem', width: '3rem' }}/>
            <ArrowDownwardIcon style={{ color: 'white', height: '3rem', width: '3rem' }}/>
          </div>
          <div className='col-11'>
            <DraggableAlgorithm 
              draggableOptions={draggableOptions} 
              draggedOptions={draggedOptions} 
              setDraggedOptions={setDraggedOptions}
              groups={groups}
              data={game.answers}
            />
          </div>
        </div>
      </div> 
      <div className="container">
        {draggableOptions?.length && draggedOptions?.length && draggableOptions.length === draggedOptions.length ? <div className="row mt-4" style={{ textAlign: 'end'}}>
          <div className="col-12">
            <button className="own-button home-button" onClick={handleNextButton}>
              {'FINALIZAR'}
            </button>
          </div>
        </div> : null}
      </div>
      <AlgorithmAnswerDialog
        onClose={handleNext}
        open={openAnswerDialog}
        points={points}
        allCorrect={allCorrect}
        file={game.filesURLs[0]}
      />
    </div>
  )
}
