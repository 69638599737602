import React from 'react'
import NavBar from "../components/NavBar"
import { useHistory } from 'react-router-dom'

export default function RegisterSuccessful() {
  const history = useHistory()

  return (
    <>
      <NavBar loginPage={true} />
      <div className="container-fluid py-5">
        <div className="row">
          <div className="offset-sm-3 col-sm-6">
            <h2 className="intro-title" style={{ color: "#EF160C" }}>REGISTRO COMPLETADO</h2>
          </div> 
        </div>
        <div className='row mt-2'>
          <div className='offset-sm-2 col-sm-8'>
            <p className="text-white intro-text" style={{ fontSize: "1.5rem" }}>Tu correo se ha verificado correctamente, haz clic en el siguiente botón para iniciar la sesión.</p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <button className="own-button inside-button" onClick={() => history.push('/identificacion')}>
              INICIAR SESIÓN
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
