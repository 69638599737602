import React, { useState, useEffect } from 'react'
import { alertError } from "../../../utils/logger"

export default function FMCQuestion(props) {
  const {
    question,
    indexQuestion,
    totalQuestions,
    handleNext,
    selectedAnswers,
    setSelectedAnswers
  } = props
  const [answers, setAnswers] = useState(null)

  useEffect(() => {
    if (!question || !question.answers || !question.answers.length) return
    const options = question.answers
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)
    setAnswers(options)
  }, [question])

  function handleClick(answer) {
    if (selectedAnswers[indexQuestion - 1]) selectedAnswers.pop()
    setSelectedAnswers(selectedAnswers.concat(answer))
  }

  function handleButton() {
    if (!selectedAnswers[indexQuestion - 1]) {
      alertError({ error: null, customMessage: 'Por favor, selecciona una respuesta.' })
      return
    }
    handleNext()
  }

  if (!question) return <div />
  return (
    <div className="game-container container-fluid py-5">
      <div className="container">
        <h3 className="fmc-text">Test de acreditación</h3>
        <h3 className="fmc-text">{indexQuestion + '/' + totalQuestions}</h3>
        <h5 className="fmc-text">{indexQuestion + '. ' + question.question}</h5>
        <div className="row mt-4">
          {answers ? answers.map((item, index) => (
            <div key={index} className={answers.length < 9 ? "col-12 py-2" : "col-12 col-md-4 py-2"}> {/*si length < 9 --> col-12; si length >= 9 --> col-4*/}
              <button key={'button-' + index} className={selectedAnswers[indexQuestion - 1]?.text === item.text ? "game-answer game-answer-active text-start px-2" : "game-answer text-start px-2"} onClick={() => handleClick(item)}>
                <div dangerouslySetInnerHTML={{ __html: item.text }} />
              </button>
            </div>
          )) : <></>}
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <button className="fmc-button" onClick={() => handleButton()}>
              {'SIGUIENTE >'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
