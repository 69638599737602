import React, { useState } from 'react'
import clsx from 'clsx'
import { SERVER_URL } from "../../../utils";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import './draggable.css'
import PreviewDialog from '../PreviewDialog';

/* Elementos que se pueden mover entre Bloques droppeables */
const renderDraggable = (item, provided, snapshot, image, setSrc, setOpenPreviewDialog) => {
	return <div
		ref={provided.innerRef}
		{...provided.draggableProps}
		{...provided.dragHandleProps}
		style={{
			...provided.draggableProps.style
		}}
		className={clsx(
			'drag-element',
			snapshot.isDragging ? 'drag-element--dragging' : 'drag-element--disable-dragging',
			item?.columnOrdenation?.draggableClasses,
			item.matched && 'drag-element--locked'
		)}
		onClick={() => {
			if (image) {
				setSrc(SERVER_URL + '/' + item.image)
				setOpenPreviewDialog(true)
			}
		}}
	>
		{image ? 
		<img className={'drag-and-drop-img'}
			src={SERVER_URL + '/' + item.image}
			alt={'top-icon'} />
		: <p style={{ color: 'white' }}>{item.match}</p>}
	</div>
}

const renderDroppable = (item, index, provided, snapshot, name, image, setSrc, setOpenPreviewDialog) =>
	<div
		{...provided.droppableProps}
		ref={provided.innerRef}
		style={{ cursor : image && 'pointer' }}
		className={clsx('drag-row-container', snapshot.isDraggingOver && 'drag-row-container--active')}
	>
			<Draggable
				direction="horizontal"
				draggableId={name}
				index={index}
				isDragDisabled={image || item.matched}
			>
				{(provided, snapshot) => renderDraggable(item, provided, snapshot, image, setSrc, setOpenPreviewDialog)}
			</Draggable>
	</div>


function DraggableMultiColumns({ answers, options, handleMatch }) {
	const [src, setSrc] = useState('')
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false)

	const handleDragEnd = (result) => {
		if ((!result.combine && !result.destination) || !result.source) return

		const { source, combine, destination } = result
		const _destination = combine || destination

		if (source.droppableId === _destination.droppableId) return

		const destinationIndex = _destination.index !== undefined ? _destination.index 
		: (_destination.draggableId?.includes('-') ? _destination.draggableId.split('-')[1] : null)

		if (destinationIndex === null) return

		const destinationColumn = answers[destinationIndex]
		const sourceColumn = options[source.index]

		if (!sourceColumn || !destinationColumn || sourceColumn.matched || destinationColumn.matched) return

		handleMatch({ source: sourceColumn, destination: destinationColumn })
	}

	return (
		<div className={clsx('drag-container')}>
			<DragDropContext
				onDragEnd={handleDragEnd}
			>
				<React.Fragment>
					<div className='drag-row'>
						{answers?.map((item, index) =>
							<Droppable droppableId={'answers-' + index} direction="horizontal" isCombineEnabled isDragDisabled>
								{(provided, snapshot) => renderDroppable(item, index, provided, snapshot, 'answers-' + index, true, setSrc, setOpenPreviewDialog)}
							</Droppable>
						)}
					</div>
					<div className='drag-row'>
						{options?.map((item, index) =>
							<Droppable droppableId={'options-' + index} direction="horizontal" isCombineEnabled isDropDisabled>
								{(provided, snapshot) => renderDroppable(item, index, provided, snapshot, 'options-' + index)}
							</Droppable>
						)}
					</div>
				</React.Fragment>
			</DragDropContext>
			<PreviewDialog
                open={openPreviewDialog}
                setOpen={setOpenPreviewDialog}
				src={src}
              />
		</div>
	)
}

export default DraggableMultiColumns
