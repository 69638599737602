import React from 'react'

export default function Timer(props) {
  const { seconds, pauseDialog, intermediateScreen, circularTimer, trueOrFalse } = props

  const remainingTime = intermediateScreen || trueOrFalse ? -1 : 3600 - seconds
  const minutes = remainingTime <= 0 ? (trueOrFalse ? Math.floor(seconds / 60) : 0) : Math.floor(remainingTime / 60)
  const displaySeconds = intermediateScreen ? seconds : (remainingTime <= 0 ? (trueOrFalse ? seconds - minutes * 60 : 0) : remainingTime - minutes * 60)
  const styleClass = intermediateScreen ? (displaySeconds === 0 ? "col-2 game-timer-end" : "col-2 game-timer")
    : (pauseDialog ? (remainingTime <= 0  ? "col-2 timer-end-pause-dialog" : "col-2 timer-pause-dialog") :
    (remainingTime <= 0  ? "col-2 timer-end" : "col-2 timer"))

  return (
    <div className="d-flex justify-content-center">
      {circularTimer ? 
        <div className={seconds === 0 ? "circular-timer-end" : "circular-timer"}>
          {seconds}
        </div>
      : <>
        <div className={styleClass} style={trueOrFalse ? { color: seconds === 0 && minutes === 0 ? '#EF160C' : '#49d489', backgroundColor: 'white', fontWeight: 'bold' } : {}}>
          {minutes < 10 ? 0 : Math.floor(minutes / 10)}
        </div>
        <div className={styleClass} style={trueOrFalse ? { color: seconds === 0 && minutes === 0 ? '#EF160C' : '#49d489', backgroundColor: 'white', fontWeight: 'bold' } : {}}>
          {minutes < 10 ? minutes : (minutes % 10)}
        </div>
        <div className="col-2" style={intermediateScreen ? { color: '#475C65', fontSize: '50px' } : (trueOrFalse ? { color: seconds === 0 && minutes === 0 ? '#EF160C' : '#49d489', fontSize: '30px' } : { color: 'white', fontSize: '30px' })}>
          {':'}
        </div>
        <div className={styleClass} style={intermediateScreen ? { color: 'white', backgroundColor: '#EF160C'} : (trueOrFalse ? { color: seconds === 0 && minutes === 0 ? '#EF160C' : '#49d489', backgroundColor: 'white', fontWeight: 'bold' } : {})}>
          {displaySeconds < 10 ? 0 : Math.floor(displaySeconds / 10)}
        </div>
        <div className={styleClass} style={intermediateScreen ? { color: 'white', backgroundColor: '#EF160C'} : (trueOrFalse ? { color: seconds === 0 && minutes === 0 ? '#EF160C' : '#49d489', backgroundColor: 'white', fontWeight: 'bold' } : {})}>
          {displaySeconds < 10 ? displaySeconds : (displaySeconds % 10)}
        </div>
      </>}
    </div>
  )
}
