import './App.css'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import store from "./app/redux/store"
import { Provider } from "react-redux"
import Footer from "./app/components/Footer"
import { Routes } from './Routes'

function App({basename}) {
  return (
    <Provider store={store}>
      <BrowserRouter basename={basename}>
        <div className="App">
          <Routes/>
        </div>
        <Footer/>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
