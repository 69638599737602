import React, { useEffect } from 'react'
import LockOpen from "../../assets/candado-abierto.png";

export default function ScenarioUnblocked(props) {
  const {
    scenario,
    handleButton
  } = props

  useEffect(() => {
    if (!scenario) return
    setTimeout(() => {
      handleButton()
    }, scenario.name === 'Superpregunta' ? 10000 : 3000)
  }, [scenario])

  if (!scenario) return <div/>
  return (
    <div className="py-5">
      <div className="container p-4">
        <div className="row mt-4 text-center">
          {scenario.name !== 'Superpregunta' && <h2 className="text-white">¡Has desbloqueado la siguiente sala!</h2>}
          <div className="col-12 d-flex justify-content-center my-4">
            {scenario.name !== 'Superpregunta' ?
              <div className="circle-unblock-scenario" id={scenario._id} style={{ border: '2.5px solid #49d489'}}>
                <div className="inner-circle-unblock-scenario green-inner-circle" style={{ border: '3.5px solid #49d489'}}>
                  <h3>{scenario.name}</h3>
                </div>
                <img className="lock-unblock-scenario" src={LockOpen} alt={'lock-icon'} />
              </div>
              : <div className="circle-unblock-scenario" id={scenario._id} style={{ border: '2.5px solid #36b6de' }}>
                <div className="inner-circle-unblock-scenario blue-inner-circle" style={{ border: '3.5px solid #36b6de' }}>
                    <pre>
                      <h1>?</h1>
                      <h3>Superpregunta</h3>
                    </pre>
                </div>
              </div>
            }
          </div>
          {scenario.name === 'Superpregunta' &&
            <>
              <h3 className="text-white col-12">¡Aún no hemos acabado!</h3>
              <p className="text-white col-12">¿Quieres sumar <strong>10 puntos</strong> a tu marcador?</p>
              <p className="text-white col-12">Responde correctamente a la superpregunta (solo tienes un intento):</p>
            </>
          }
        </div>
      </div>
    </div>
  )
}
