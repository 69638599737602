import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import aplauso from "../assets/sp-ico-aplauso.png"
import GameNavbar from "../components/GameNavBar"
import {shallowEqual, useSelector} from "react-redux";
import { getFullTime } from './Ranking';
import { updateUserProgress } from "../../api/user";
import { useDispatch } from 'react-redux'
import { authActions } from "../redux/actions/authActions";


export default function ClinicalCaseFinal() {
  const history = useHistory()
  const user = useSelector((store) => store.authenticationWebMistuiery?.user, shallowEqual)
  const [seconds, setSeconds] = useState(0)
  const [userPoints, setUserPoints] = useState(0)
  const id = useParams().id
  const dispatch = useDispatch()

  useEffect(() => {
    if (!user || userPoints !== 0) return
    if (user.progress && user.progress[id] && user.seconds !== 0) {
      if (user.progress[id].seconds) setSeconds(user.progress[id].seconds)
      if (user.progress[id].points !== undefined) setUserPoints(user.progress[id].points)
      const userProgress = {
          ...user.progress,
          [id]: {
            seconds: 0,
            lastGame: '',
            points: 0,
            scenariosCompleted: []
          }
        }
      updateUserProgress(user.email, userProgress).then(res => {
        if (res.status === 200) {
          const newUser = user
          newUser.progress = userProgress
          dispatch(authActions.refreshUserData(newUser))
          console.log('User progress updated')
        } else console.log('Could not update user progress')
      })
    }
  }, [id, user])
  
  return (
    <>
      <GameNavbar seconds={seconds} setSeconds={setSeconds} stopTimer={true}/>
      <div className="container-fluid py-5">
        <div className="row">
          <div className="col-12">
            <img className="top-icon mb-4"
                 src={aplauso}
                 alt={'top-icon'} />
            <h1 style={{ color: "#EF160C" }}>¡Enhorabuena!</h1>
            <h2 className="text-white">Has resuelto el caso y el misterio.</h2>
            <h2 className="text-white">Muchas gracias por participar.</h2>
            <h2 className="text-white">Esta es tu puntuación:</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2">
            <hr style={{ color: 'white', height: '2px', opacity: '1' }}/>
            <div className="row">
              <div className="col-6 text-start offset-md-1">
                <p className="text-white" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{(user?.name + ' ' + user?.firstSurname + ' ' + user?.secondSurname)}</p>
              </div>
              <div className="col-2 text-start">
                <p className="text-white" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{getFullTime(seconds)}</p>
              </div>
              <div className="col-3 text-start">
                <p className="text-white" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{userPoints + ' puntos'}</p>
              </div>
            </div>
            <hr style={{ color: 'white', height: '2px', opacity: '1' }}/>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <button className="own-button inside-button" style={{ fontStyle: 'italic' }} onClick={() => history.push({ pathname: '/casos/' + id + '/ranking', state: user })}>
              RANKING
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
