import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import NavBar from "../components/NavBar"
import { getClinicalCases } from "../../api/clinicalCases"
import gameRules from '../assets/game-rules.png'
import rankingGeneral from '../assets/ranking-general.png'
import GameRules from "../components/GameRules"
import accreditation from "../assets/accreditation.png"
import { SERVER_URL } from "../../utils"
import { shallowEqual, useSelector } from "react-redux";
import InfoDialog from "../components/InfoDialog";
import CheckCodeDialog from "../components/CheckCodeDialog"

export default function ClinicalCases() {
  const history = useHistory()
  const [clinicalCases, setClinicalCases] = useState([])
  const [open, setOpen] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [openCheckDialog, setOpenCheckDialog] = useState(false)
  const [clinicalCaseId, setClinicalCaseId] = useState(null)
  const [clinicalCaseCode, setClinicalCaseCode] = useState(null)
  const [iconClicked, setIconClicked] = useState(null)
  const user = useSelector((store) => store.authenticationWebMistuiery?.user, shallowEqual)

  useEffect(() => {
    getClinicalCases().then(res => {
      if (res.status === 200) {
        setClinicalCases(res.data)
      } else {
        console.log('Could not get any clinical case')
      }
    })
  }, [])

  function handleClickIcon(action) {
    setIconClicked(action)
    setOpen(true)
  }

  function handleClickCase(clinicalCase) {
    setClinicalCaseId(clinicalCase._id)
    setClinicalCaseCode(clinicalCase.code)
    // CASOS 5 y 6 SOLO VISIBLES PARA USUARIOS DEL DOMINIO ASTELLAS / OWIUS / VIVACTIS / MISTUIERY
    //if ((clinicalCase._id === '61a49b592aa0fd09eb3882e2' || clinicalCase._id === '61a49b7a2aa0fd09eb3882e7') && !user.email.includes('@astellas.com') && !user.email.includes('@vivactis.') && !user.email.includes('@owius.com') && !user.email.includes('@mistuiery.com')) return 
    if (clinicalCase.active) {
      if (user && user.clinicalCasesCompleted && user.clinicalCasesCompleted.includes(clinicalCase._id)) setOpenDialog(true)
      else setOpenCheckDialog(true)
    }
  }

  return (
    <>
      <NavBar casesPage={true} />
      <div className="container mb-4">
        <div className="row">
          <div className="col-12 offset-0 offset-md-8 col-md-4">
            <div className="row">
              <div className="col-4 text-center">
                <img src={rankingGeneral} className="game-rules-icon" alt="game-rules" onClick={() => history.push('/ranking-general')} />
                <p className="game-rules-text mt-2" style={{ fontStyle: 'italic' }}>Ranking</p>
              </div>
              <div className="col-4 text-center">
                <img src={accreditation} className="game-rules-icon" alt="game-rules" onClick={() => handleClickIcon('accreditation')} />
                <p className="game-rules-text mt-2">Acreditación</p>
              </div>
              <div className="col-4 text-center">
                <img src={gameRules} className="game-rules-icon" alt="game-rules" onClick={() => handleClickIcon('rules')} />
                <p className="game-rules-text mt-2">Metodología</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          {clinicalCases.map((item, index) => (
            <div key={item._id} className="col-12 col-md-4 clinical-case-img" id={item._id}
              onClick={() => handleClickCase(item)}>
              <p className="text-end mb-0" style={{ marginRight: '8%', color: item.credits && item.accreditation ? '#fff' : 'transparent', fontSize: 'small'}}><b>{item.credits && item.accreditation ? item.credits + ' créditos' : 'Pendiente'}</b></p>
              <img className="img-fluid" alt={item.name}
                src={user && user.clinicalCasesCompleted && user.clinicalCasesCompleted.includes(item._id) ? `${SERVER_URL}/${item.hoverImageURL}` : `${SERVER_URL}/${item.imageURL}`} />
              {!item.active && <span class="badge badge-WIP">En construcción</span>}
              {item.active && !item.accreditation && <span class="badge badge-pending" style={{ top: index >= 3 && '-20%'}}>Pendiente de acreditar</span>}
              {item.accreditation && <div className="accreditation" style={{ fontFamily: 'barlowCondensed', marginTop: item._id === '61a49b592aa0fd09eb3882e2' ? '26px' : 'auto' }} dangerouslySetInnerHTML={{ __html: item?.accreditation }} />}
            </div>
          ))}
        </div>
      </div>
      <GameRules
        open={open}
        icon={iconClicked === 'rules' ? gameRules : accreditation}
        gameRules={iconClicked === 'rules'}
        onClose={() => setOpen(false)}
        title={iconClicked === 'rules' ? '¿Qué es Manejo Integral de los Síntomas del Tracto Urinario Inferior. Experiencia clínica interactiva (MISTUIERY)?' : 'Acreditación'}
      />
      <InfoDialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false)
          history.push('/casos/' + clinicalCaseId)
        }}
      />
      <CheckCodeDialog
        open={openCheckDialog}
        setOpen={setOpenCheckDialog}
        clinicalCaseId={clinicalCaseId}
        clinicalCaseCode={clinicalCaseCode}
        onClose={() => history.push('/casos/' + clinicalCaseId)}
      />
    </>
  )
}
