import React, { useState } from 'react'
import { Dialog } from '@material-ui/core'
import { getFileType, SERVER_URL } from "../../utils";
import audioIcon from "../assets/audio-icon.png"
import audioPause from "../assets/pause-audio.png"
import notValidAnswer from "../assets/not-valid-answer.png"
import wrongAnswer from "../assets/wrong-answer.png"
import correctAnswerIcon from "../assets/correct-answer.png"
import bg from '../assets/background.png'
import pdfSupport from 'browser-pdf-support'
import downloadFile from '../assets/download-icon.png'
import ipss from '../assets/ipss.png'
import glucemia from '../assets/glucemia.png'
import kegel from '../assets/kegel.png'
import tratamiento from '../assets/tratamiento.png'

function getAnswerResponse(answer, attempt, maxAttempts, correctAnswer, hintAvailable, hintAccepted) {
  if (!answer) return { points: null, text: '' }
  const points = ((hintAvailable || attempt === 1) && !hintAccepted) ? (answer.firstTry ? Math.abs(answer.firstTry) : answer.firstTry) : (answer.secondTry ? Math.abs(answer.secondTry) : answer.secondTry)
  if (answer.solution === 'correcta') {
    return { points, text: attempt === 1 ? 'Has ganado ' + points + ' puntos.' : (points === 0 ? 'Como no has acertado a la primera, no ganas puntos.' : 'Como no has acertado a la primera, solo ganas ' + points + ' puntos.')}
  } else if (answer.solution === 'no-valida' || (hintAvailable && points === 0)) return { points, text: 'Te has acercado mucho, pero no es la respuesta más adecuada. No ganas ni pierdes puntos.'}
  else {
    if (!maxAttempts && !hintAccepted) {
      return { points, text: points === 0 ? 'No has perdido puntos, puedes intentarlo de nuevo.' : 'Has perdido ' + points + ' puntos, pero puedes intentarlo de nuevo.'}
    } else if (attempt <= maxAttempts) {
      const attemptsLeft = maxAttempts - attempt
      return { points, text: attemptsLeft === 0 ? (points === 0 ? '' : 'Has perdido ' + points + ' puntos, pero puedes continuar.') : (attemptsLeft === 1 ? 'Te queda ' + attemptsLeft + ' intento. Si fallas ahora...' : 'Te quedan ' + attemptsLeft + ' intentos')}
    } else return { points, text: points === 0 ? '' : 'Has perdido ' + points + ' puntos.'}
  }
}

export default function AnswerDialog(props) {
  const { open, answer, attempt, onClose, maxAttempts, correctAnswer, superquestion, hintAvailable, hintAccepted, game } = props
  const fileType = answer?.file && answer.file !== '' ? getFileType(answer?.file) : null
  const response = getAnswerResponse(answer, attempt, maxAttempts, correctAnswer, hintAvailable, hintAccepted)
  const [audioPlaying, setAudioPlaying] = useState(false)

  const playAudio = () => {
    if (!audioPlaying) {
      setAudioPlaying(true)
      document.getElementById('audio').play();
    } else {
      setAudioPlaying(false)
      document.getElementById('audio').pause();
    }
  }

  document.getElementById('audio')?.addEventListener('ended', function (e) {
    e.preventDefault();
    setAudioPlaying(false)
    document.getElementById('audio').currentTime = 0
  });

  return (
    <Dialog
      style={{ background: `url(${bg}) no-repeat`, backgroundSize: 'cover' }}
      open={open}
      fullWidth={true}
      maxWidth="md"
      onClose={() => { }}
      aria-labelledby="answer-dialog">
      <div className="container-fluid text-center px-5 py-4">
        {answer && <div className="row">
          <div className="col-12">
            {answer?.solution === 'correcta' ? (
              <>
                <img className="top-icon mb-3"
                  src={correctAnswerIcon}
                  alt={'top-icon'} />
                <h3 style={{ color: '#49d489' }}>{attempt === 1 ? '¡Correcto!' : '¡Esta sí es la respuesta correcta!'}</h3>
                <h4 style={{ color: '#004e7d' }} className="my-3">{response.text}</h4>
              </>
            ) : (answer?.solution === 'no-valida' || (hintAvailable && response.points === 0)) ? (
              <>
                <img className="top-icon mb-3"
                  src={notValidAnswer}
                  alt={'top-icon'} />
                  <h4 style={{ color: '#004e7d' }} className="my-3">{response.text}</h4>
              </>
            ) : (
              <>
                <img className="top-icon mb-3"
                  src={wrongAnswer}
                  alt={'top-icon'} />
                {superquestion ? 
                  <h3 style={{ color: '#EF160C' }}>¡Vaya! Esa no era la respuesta que había que marcar.</h3>
                 : <h3 style={{ color: '#EF160C' }}>No es una buena elección.</h3>}
                {response.points ? <h2 style={{ color: '#004e7d', fontWeigth: 'bolder' }}>{'-' + response.points + ' puntos'}</h2> : null}
                <h5 style={{ color: '#004e7d' }} className="my-3">{response.text}</h5>
              </>
            )}
            <hr style={{ backgroundColor: '#004e7d', height: '0.5px', opacity: '1' }}/>
            {(!hintAvailable || answer?.solution === 'correcta' || (answer?.solution === 'incorrecta' && hintAccepted)) && 
              game?._id === '64ac42a45771115194fb67f0' && answer.solution === 'correcta' ? 
                <div className='row'>
                  <div className='col-2 d-flex flex-column justify-content-evenly'>
                    <img src={kegel} className="top-icon-smaller mb-3"/>
                    <img src={tratamiento} className="top-icon-smaller mb-3"/>
                    <img src={glucemia} className="top-icon-smaller mb-3"/>
                    <img src={ipss} className="top-icon-smaller mb-3"/>
                  </div>
                  <div className="html col-9 mt-3" dangerouslySetInnerHTML={{ __html: answer?.justification }} />
                </div>
              : <div className="html mt-3" dangerouslySetInnerHTML={{ __html: answer?.justification }} />
            }
            {(!hintAvailable || answer?.solution === 'correcta' || (answer?.solution === 'incorrecta' && hintAccepted)) && answer?.file && answer.file !== '' ? (
              fileType === 'image' ? (
                <img
                  className="img-fluid"
                  src={SERVER_URL + '/' + answer.file}
                  alt={'intermediate-screen'} />
              ) : fileType === 'video' ? (
                <div className="embed-responsive embed-responsive-21by9">
                  <video className="embed-responsive-item" allowFullScreen controls>
                    <source src={SERVER_URL + '/' + answer.file} type="video/mp4" />
                  </video>
                </div>
              ) : fileType === 'audio' ? (
                <>
                  <audio id="audio" src={SERVER_URL + '/' + answer.file} />
                  <a href="#" id="play">
                    <img
                      className="top-icon"
                      onClick={playAudio}
                      src={audioPlaying ? audioPause : audioIcon}
                      alt={'audio-icon'} />
                  </a>
                  <p className="mt-2" style={{ color: '#004e7d' }}>Haz clic en el icono para escuchar el audio.</p>
                </>
              ) : fileType === 'file' ? (
                <>
                {pdfSupport() ? <embed className="game-file" src={SERVER_URL + '/' + answer.file} /> :               
                  <a target="_blank" rel="noreferrer" href={SERVER_URL + '/' + answer.file}><img
                  src={downloadFile}
                  alt={'file-icon'} /></a>}
                </>
              ) : <></>
            ) : <></>}
            {maxAttempts !== 1 && maxAttempts && attempt >= maxAttempts && answer?.solution !== 'correcta' && <h4 className="mt-5">La respuesta correcta es "<strong>{correctAnswer}</strong>".</h4>}
            <div className="row py-3 text-center">
              <div className="col-12">
                <button className="own-button inside-button" onClick={() => onClose()}>
                  {answer?.solution === 'correcta' || (maxAttempts && attempt >= maxAttempts) || hintAccepted ? 'CONTINUAR' : 'VOLVER A INTENTAR'}
                </button>
              </div>
            </div>
          </div>
        </div>}
      </div>
    </Dialog>
  )
}
