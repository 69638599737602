import React, { useState } from 'react'
import homepage from '../assets/homepage-title.png'
import logo from '../assets/logo-mistuiery.png'
import { useHistory } from 'react-router-dom'
import NavBar from "../components/NavBar"
import GameRules from "../components/GameRules";
import gameRules from "../assets/game-rules.png";

export default function Home() {
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [checked, setChecked] = useState(false)

  return (
    <>
      <NavBar home={true} />
      <div className="container">
        <div className="row">
          <div className="home-title col-12 offset-0 col-md-8 offset-md-2">
            <img src={homepage} className="img-fluid mt-5" alt="homepage-title" />
          </div>
          <div className="home-title col-12">
            <img src={logo} className="mt-3 img-fluid" alt="logo-mistuiery" />
          </div>
          <div className="col-12">
            <div onClick={() => localStorage.getItem('notShowAgain') ? history.push('/identificacion') : setOpen(true)} className="my-3 mb-md-0 f">
              <button className="own-button home-button" onClick={() => localStorage.getItem('notShowAgain') ? history.push('/identificacion') : setOpen(true)}>
                ¿TE ATREVES?
              </button>
            </div>
          </div>
        </div>
      </div>
      <GameRules
        open={open}
        onClose={() => {
          setOpen(false)
          if (checked) localStorage.setItem('notShowAgain', true);
          history.push('/identificacion')
        }}
        showCheckbox={true}
        checked={checked}
        setChecked={setChecked}
        icon={gameRules}
        gameRules={true}
        title={'¿Qué es Manejo Integral de los Síntomas del Tracto Urinario Inferior. Experiencia clínica interactiva (MISTUIERY)?'}
      />
    </>
  )
}
