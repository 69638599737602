import { API, authClient } from '../../utils/index'

export const updateUserProgress = (email, body) => {
  return authClient().put(`${API}/user/progress/${email}`, body)
}

export const updateUserCasesCompleted = (email, body) => {
  return authClient().put(`${API}/user/completed/${email}`, body)
}

export const updateUserAcreditationProgress = (email, body) => {
  return authClient().put(`${API}/user/acreditation/${email}`, body)
}

export const getUserById = (email, body) => {
  return authClient().get(`${API}/user/${email}`, body)
}

export const getUsers = () => {
  return authClient().get(`${API}/user`)
}

// TODO -> Check security
export const forgotPassword = (body) => {
  return authClient().post(`${API}/user/forgot-password`, body)
}

export const resetPassword = (body, id) => {
  return authClient().put(`${API}/user/forgot-password/${id}`, body)
}