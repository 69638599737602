import React, {useState} from 'react'
import NavBar from "../components/NavBar"
import {alertError, alertForgotPassword} from "../../utils/logger";
import {forgotPassword} from "../../api/user";

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false)

  function handleForgotPassword(event) {
    event.preventDefault()
    setLoading(true)
    const formData = new FormData(event.target)
    const jsonData = Object.fromEntries(formData.entries())
    forgotPassword(jsonData).then((res) => {
      if (res.status === 200) {
        alertForgotPassword()
        setLoading(false)
      } else {
        setLoading(false)
        alertError({
          error: null,
          customMessage: 'No se ha podido enviar el correo para restablecer la contraseña.'
        })
      }
    })
  }


  return (
    <>
      <NavBar/>
      <div className="container my-5">
        <div className="row">
          <div className="col-12">
            <h3 className="text-white">¿HAS OLVIDADO TU CONTRASEÑA?</h3>
            <p className="auth-title text-start">Introduce tu correo electrónico:</p>
            <form onSubmit={handleForgotPassword}>
              <div className="mb-2">
                <input type="email" className="form-control input-form" id="Email" name="email" placeholder={'E-mail*'} required />
              </div>
              <div className="text-end">
                <button type="submit" className="btn btn-primary mt-4 auth-button" disabled={loading}>
                  <span className="align-middle">Aceptar</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
