import React, { useEffect, useState } from 'react'
import { SERVER_URL } from "../../../utils";
import bg from '../../assets/bg-game-container-1.png'

export default function HTMLScreen(props) {
  const {
    game,
    handleNext,
    clinicalCase
  } = props
  const [showButton, setShowButton] = useState(false)
  let buttonsClicked = []

  useEffect(() => {
    const listener = window.addEventListener(
      "message",
      (ev) => {
        if (typeof ev.data !== "object") return
        if (!ev.data.type) return
        if (ev.data.type !== "button-click") return
        if (!ev.data.message) return
        if (!ev.data.buttons) return
        if (!buttonsClicked.includes(ev.data.message)) {
          buttonsClicked.push(ev.data.message)
          if (buttonsClicked.length >= parseInt(ev.data.buttons)) setShowButton(true)
        }
      }
    );
    return () => {
      window.removeEventListener('blur', listener);
    }
  }, [game])

  if (!game) return <div/>
  return (
    <div className="game-container py-3" style={{ background: `url(${bg}) no-repeat`, backgroundSize: 'cover'}}>
      <h1 className='html-title pb-2'>{game.description}</h1>
      <div className="intermediate-box-html container p-2">
        <div className="row mt-4">
          <div className={"col-12 " + (game._id === '6368cd1b6c6cb04d774bf0ce' || game._id === '6368daf76c6cb04d774bf0fa' ? "inline-frame-small" : "inline-frame")}>
              <iframe
                id="inlineFrame"
                title="inlineFrame"
                width="100%"
                src={clinicalCase === 1 ? 
                  SERVER_URL + '/uploads/html/clinicalCase1/index.html' 
                  : clinicalCase === 2 ? SERVER_URL + '/uploads/html/clinicalCase2/index.html' 
                  : clinicalCase === 3 ? SERVER_URL + '/uploads/html/clinicalCase3/index.html' 
                  : clinicalCase === 4 ? SERVER_URL + '/uploads/html/clinicalCase4/index.html'
                  : clinicalCase === 5 ? (
                      game._id === '6368cd1b6c6cb04d774bf0ce' ? SERVER_URL + '/uploads/html/clinicalCase5/index.html'
                      : game._id === '6368daf76c6cb04d774bf0fa' ? SERVER_URL + '/uploads/html/clinicalCase5-2/index.html'
                      : game._id === '6368e2806c6cb04d774bf141' ? SERVER_URL + '/uploads/html/clinicalCase5-3/index.html'
                      : SERVER_URL + '/uploads/html/clinicalCase5-4/index.html'
                    )
                  : (
                    game._id === '64ac3b705771115194fb66fb' ? SERVER_URL + '/uploads/html/clinicalCase6/index.html'
                    : game._id === '64ac3ce05771115194fb673f' ? SERVER_URL + '/uploads/html/clinicalCase6-2/index.html'
                    : game._id === '64ac434b5771115194fb6804' ? SERVER_URL + '/uploads/html/clinicalCase6-3/index.html'
                    : SERVER_URL + '/uploads/html/clinicalCase6-4/index.html'
                  )}/>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mt-4">
          <div className="col-12">
            {showButton && <button className="own-button inside-button" onClick={handleNext}>
              CONTINUAR
            </button>}
          </div>
        </div>
      </div>
    </div>
  )
}
