import { API, authClient } from '../../utils/index'

// Get all scenarios of specified clinical case
export const getScenarios = (clinicalCaseId) => {
    return authClient().get(`${API}/scenarios/all/${clinicalCaseId}`)
}

// Get scenario by id
export const getScenarioById = (id) => {
    return authClient().get(`${API}/scenarios/${id}`)
}

// Update scenario
export const updateScenario = async (id, scenario) => {
    return authClient().put(`${API}/scenarios/${id}`, scenario)
}

// Save scenario
export const postScenario = async (scenario) => {
    return authClient().post(`${API}/scenarios`, scenario)
}

// Delete scenario
export const deleteScenario = (id) => {
    return authClient().delete(`${API}/scenarios/${id}`)
}

