import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import GameNavbar from "../components/GameNavBar"
import {getClinicalCaseById} from "../../api/clinicalCases";
import {SERVER_URL} from "../../utils";
import {getDoctorById} from "../../api/doctors";
import download from '../assets/descargar-marco-teórico.png'
import {shallowEqual, useSelector} from "react-redux";
import pdfSupport from 'browser-pdf-support'

export default function FMCQuestions() {
  const clinicalCaseId = useParams().id
  const history = useHistory()
  const [data, setData] = useState([])
  const [doctor, setDoctor] = useState([])
  const [buttonBlocked, setButtonBlocked] = useState(false)
  const [loading, setLoading] = useState(false)
  const user = useSelector((store) => store.authenticationWebMistuiery?.user, shallowEqual)

  useEffect(() => {
    setLoading(true)
    getClinicalCaseById(clinicalCaseId).then(res => {
      if (res.status === 200) {
        setData(res.data)
        getDoctorById(res.data.doctor).then(response => {
          if (response.status === 200) {
            setDoctor(response.data)
          } else console.log('Could not get doctor')
          setLoading(false)
        })
      } else {
        setLoading(false)
        console.log('Could not get clinical case.')
      } 
    })
  }, [clinicalCaseId])

  function handleClick() {
    if (buttonBlocked) {
      history.push('/casos/' + clinicalCaseId)
      return
    }
    history.push('/casos/' + clinicalCaseId + '/test-valoracion')
  }

  useEffect(() => {
    if (!user) return
    setButtonBlocked(user.acreditation && user.acreditation[clinicalCaseId] && user.acreditation[clinicalCaseId].satisfactionTestDone)
  }, [user])

  if (loading) return <></>
  return (
    <>
      <GameNavbar stopTimer={true} hideTimer={true} hideProgress={true}/>
      <div className="game-container container-fluid py-5">
        <div className="row">
          <div className="col-12">
            <h3 className="fmc-text">MARCO TEÓRICO</h3>
            <h3 className="fmc-text">{'Caso: ' + data.name}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 offset-md-2">
            {pdfSupport() ? <embed className="game-file" style={{ minHeight: '750px'}} src={SERVER_URL + '/' + data.fileURL} /> : null}
          </div>
          <div className="col-md-2 col-12">
            <div className="row">
              <img
                className="img-fluid"
                src={SERVER_URL + '/' + doctor.imageURL}
                alt={'doctor-img'} />
              <p className="fmc-text"><strong>{doctor.name}</strong></p>
              <p className="fmc-text">{doctor.info}</p>
              <a target="_blank" rel="noreferrer" href={SERVER_URL + '/' + doctor.curriculum}><button className="own-button cv-button">
                <>Ver </><i>curriculum vitae</i>
              </button></a>
            </div>
            <div className="row">
              <a target="_blank" rel="noreferrer" href={SERVER_URL + '/' + data.fileURL}><img
                className="img-fluid mt-4"
                src={download}
                onClick={() => console.log('descargar marco teorico')}
                alt={'marco-teorico'} /></a>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <button className="own-button inside-button" onClick={() => handleClick()}>
              {buttonBlocked ? 'VOLVER AL MENÚ DEL CASO' : 'TEST DE VALORACIÓN'}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}