import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { getClinicalCaseById } from "../../api/clinicalCases"
import Navbar from '../components/NavBar';

export default function ClinicalCaseIntro() {
  const history = useHistory()
  const [clinicalCase, setClinicalCase] = useState(null)
  const id = useParams().id

  useEffect(() => {
    getClinicalCaseById(id).then(res => {
      if (res.status === 200) {
        setClinicalCase(res.data)
      } else {
        console.log('Could not get any clinical case')
      }
    })
  }, [id])

  return (
    <>
      <Navbar clinicalCase={true}/>
      <div className="container-fluid py-5">
        <div className="row">
          <div className="offset-sm-3 col-sm-6">
            <h2 className="intro-title" style={{ color: "#EF160C", fontSize: "3.5rem" }}>{clinicalCase?.description}</h2>
          </div> 
        </div>
        <div className='row mt-2'>
          <div className='offset-sm-2 col-sm-8'>
            <p className="text-white intro-text" style={{ fontSize: "1.5rem" }}><strong>Presta atención,</strong> recopila todos los datos y responde correctamente las preguntas.</p>
            <p className="text-white intro-text" style={{ fontSize: "1.5rem" }}>Ganará quien consiga una puntuación más alta y, en caso de empate, quien resuelva el <strong>misterio</strong> en menos tiempo.</p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <button className="own-button inside-button" onClick={() => history.push('/casos/' + id + '/escenarios')}>
              ¡ADELANTE!
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
