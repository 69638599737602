import React from 'react'
import { Dialog } from '@material-ui/core'
import icon from "../../../app/assets/Identific-8.png"

export default function DragAndDropCompleted(props) {
  const { open, onClose, points } = props

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="md"
      onClose={() => {}}
      aria-labelledby="answer-dialog">
      <div className="container-fluid text-center p-5">
        <div className="row">
          <div className="col-12">
            <h3 className="mb-4">{'Tu identificación es correcta y has ganado '}<span style={{ color: '#49d489'}}>{points}</span>{' puntos.'}</h3>
            <img className="mb-2"
                    src={icon}
                    alt={'top-icon'} />
            <h3 className="mt-5">{'Ya puedes acceder a los resultados de tu paciente.'}</h3>
            <div className="row py-5 text-center">
              <div className="col-12">
                <button className="own-button inside-button" onClick={() => onClose()}>
                  CONTINUAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
