import React from 'react'
import { Dialog } from '@material-ui/core'
import { getFileType, SERVER_URL } from "../../utils";
import audioIcon from "../assets/audio-icon.png"
import wrongAnswer from "../assets/wrong-answer.png"
import correctAnswerIcon from "../assets/correct-answer.png"
import pdfSupport from 'browser-pdf-support'
import downloadFile from '../assets/download-icon.png'

function getAnswerResponse(answer) {
  if (!answer) return ''
  const points = answer.firstTry ? Math.abs(answer.firstTry) : answer.firstTry
  if (answer.solution === 'correcta') {
    return 'Has ganado ' + points + ' puntos.'
  } else return points === 0 ? '' : 'Has perdido ' + points + ' puntos.'
}

export default function InfiltratedAnswerDialog(props) {
  const { open, answer, onClose } = props
  const fileType = answer?.file && answer.file !== '' ? getFileType(answer?.file) : null
  const text = getAnswerResponse(answer)

  document.getElementById('play')?.addEventListener('click', function (e) {
    e.preventDefault();
    document.getElementById('audio').play();
  });

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="md"
      onClose={() => { }}
      aria-labelledby="answer-dialog">
      <div className="container-fluid text-center p-5">
        <div className="row">
          <div className="col-12">
            {answer?.solution === 'correcta' ? (
              <>
                <img className="top-icon mb-4"
                  src={correctAnswerIcon}
                  alt={'top-icon'} />
                <h4 style={{ color: '#49d489' }}>{'¡Correcto!'}</h4>
              </>
            ) : (
              <>
                <img className="top-icon mb-4"
                  src={wrongAnswer}
                  alt={'top-icon'} />
                <h4 style={{ color: '#004e7d' }}>{'¡Vaya! La ' + answer?.text + ' es precisamente la prueba «infiltrada».'}</h4>
              </>
            )}
            <div dangerouslySetInnerHTML={{ __html: answer?.justification }} />
            {answer?.file && answer.file !== '' ? (
              fileType === 'image' ? (
                <img
                  className="img-fluid"
                  src={SERVER_URL + '/' + answer.file}
                  alt={'intermediate-screen'} />
              ) : fileType === 'video' ? (
                <div className="embed-responsive embed-responsive-21by9">
                  <video className="embed-responsive-item" allowFullScreen controls>
                    <source src={SERVER_URL + '/' + answer.file} type="video/mp4" />
                  </video>
                </div>
              ) : fileType === 'audio' ? (
                <>
                  <audio id="audio" src={SERVER_URL + '/' + answer.file} />
                  <a href="#" id="play">
                    <img
                      src={audioIcon}
                      alt={'audio-icon'} />
                  </a>
                </>
              ) : fileType === 'file' ? (
                <>
                {pdfSupport() ? <embed className="game-file" src={SERVER_URL + '/' + answer.file} /> :               
                  <a target="_blank" rel="noreferrer" href={SERVER_URL + '/' + answer.file}><img
                  src={downloadFile}
                  alt={'file-icon'} /></a>}
                </>
              ) : <></>
            ) : <></>}
            <h4 style={{ color: '#004e7d' }} className="mt-5">{text}</h4>
            <div className="row py-5 text-center">
              <div className="col-12">
                <button className="own-button inside-button" onClick={() => onClose()}>
                  CONTINUAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
