import { userConstants } from '../constants'

const initialState = { loggedIn: false }

// TO DO --> When Get credentials reducer (same as LOGIN_SUCCESS or new ?)

export function authenticationWebMistuiery(state = initialState, action) {
  switch (action.type){
    /*case userConstants.REFRESH_USER:
      return {
        ...state,
        user: action.user.user
      }*/
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state, 
        loggedIn: true,
        user: action.user.user/* ,
        accessToken: action.auth.accessToken,
        refreshToken: action.auth.refreshToken */
      }
    case userConstants.LOGIN_FAILURE: 
      return { loggedIn: false }
    case userConstants.LOGOUT: 
      return { loggedIn: false }
    default:
      return state
  }
}