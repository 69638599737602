import React from 'react'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import './preview.css'
import CloseIcon from '@material-ui/icons/Close'

const PreviewDialog = (props) => {
  const { open, src, setOpen } = props

  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="preview-dialog">
      <DialogTitle disableTypography className="dialog-title">
        <CloseIcon className="close-dialog-icon" onClick={() => setOpen(false)}/>
      </DialogTitle>
      <DialogContent className="preview-container">
          <embed className="preview-file"
            src={src}
          />
      </DialogContent>
    </Dialog>
  )
}
export default PreviewDialog
