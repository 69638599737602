import React, { useEffect, useState } from 'react'
import $ from 'jquery'; 
import './draggableAlgorithm.css'

function DraggableAlgorithm({ data, draggableOptions, draggedOptions, setDraggedOptions, groups }) {
  const [dragSrcEl, setDragSrcEl] = useState(null)

  function handleDragStart(e) {
    this.style.opacity = '0.4';

    setDragSrcEl(this);

    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', this.innerHTML);
  }

  function handleDragOver(e) {
    if (e.preventDefault) {
      e.preventDefault();
    }
    
    e.dataTransfer.dropEffect = 'move';

    return false;
  }

  function handleDragEnter(e) {
    if (this && this.draggable === true) this.classList.add('over');  
  }

  function handleDragLeave(e) {
    this.classList.remove('over');
  }

  function handleDrop(e) {
    if (e.stopPropagation) {
      e.stopPropagation(); // stops the browser from redirecting.
    }

    if (dragSrcEl && this && this.id && this.draggable === true && dragSrcEl !== this && this.className !== "fc-event over") {
      var value = $(dragSrcEl).attr('value');
      if (dragSrcEl && dragSrcEl?.className === "fc-event") {     
        this.innerHTML += e.dataTransfer.getData('text/html');
        $(this).attr("value", value);
        if (dragSrcEl && dragSrcEl.id) {
          if (this && this.draggable === true) this.setAttribute('draggable', 'false')
          let _dragged = draggedOptions
          if (_dragged.findIndex(item => item.item === dragSrcEl.id) < 0) setDraggedOptions(prev => (
            prev.concat({ item: dragSrcEl.id, group: this.id })
          ))
          document.getElementById('ptag-' + this.id).innerText = 'X'
        }
      } 
      
    }

    return false;
    
  }

  function handleDragEnd(e) {
    this.style.opacity = '1';

    items.forEach(function(item) {
      item.classList.remove('over');
    });
  }

  function removeOption(id) {
    if (!id || !document.getElementById('ptag-' + id)) return
    setDraggedOptions(prev => (
      prev.filter(item => item.group !== id)
    ))
    document.getElementById('ptag-' + id).innerText = ''
    document.getElementById(id).setAttribute('draggable', 'true')
    document.getElementById(id).innerHTML = document.getElementById(id).innerHTML.split('</p>')[0] + ''
  }


  let items = document.querySelectorAll('.grid-container .box');
  items.forEach(function(item) {
    item.addEventListener('dragstart', handleDragStart, false);
    item.addEventListener('dragenter', handleDragEnter, false);
    item.addEventListener('dragover', handleDragOver, false);
    item.addEventListener('dragleave', handleDragLeave, false);
    item.addEventListener('drop', handleDrop, false);
    item.addEventListener('dragend', handleDragEnd, false);
  });



  let items2 = document.querySelectorAll('#external-events-listing div');
  items2.forEach(function(item2) {
    item2.addEventListener('dragstart', handleDragStart, false);
    item2.addEventListener('dragenter', handleDragEnter, false);
    item2.addEventListener('dragover', handleDragOver, false);
    item2.addEventListener('dragleave', handleDragLeave, false);
    item2.addEventListener('drop', handleDrop, false);
    item2.addEventListener('dragend', handleDragEnd, false);
  });


	return (
      <div id='wrap'>
        <div id='external-events'>
            <div id='external-events-listing'>
                {draggableOptions?.map((option, index) => (
                  <div key={option.text} id={option.text} draggable={draggedOptions.findIndex(item => item.item === option.text) < 0 ? "true" : "false"} className={`fc-event ${draggedOptions.findIndex(item => item.item === option.text) < 0 ? '' : 'dragged'}`} value={index + 1}>{option.text}</div>
                ))}
            </div>
        </div>
        <div className="grid-container">
            {groups?.map((group, index) => (
                <div key={group} draggable="false" className="group" value={index + 1}>{group}</div>
            ))}
            {data?.map((group, index) => (
              <>
                {group.options?.map((option, ind) => (
                  <div 
                    key={option.text} 
                    id={option.text + '-group:' + group.group}
                    draggable={option.fixed ? "false" : "true"} 
                    className={`box ${!option.fixed ? 'box-draggable' : ''}`}
                    onClick={() => !option.fixed && removeOption(option.text + '-group:' + group.group)} 
                    style={{ gridColumn: index + 1, gridRowStart: ind + 2 }}>
                      {!option.fixed && <p id={'ptag-' + option.text + '-group:' + group.group} style={{ textAlign: 'end', float: 'right', color: '#e47b00', cursor: 'pointer' }} onClick={() => console.log('clicked')}></p>}
                      {option.fixed  ? option.text : ''}
                  </div>
                ))}
              </>
            ))}
        </div>
      </div>
	)
}

export default DraggableAlgorithm