import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { ErrorPage } from './app/pages/ErrorPage'
import Auth from "./app/pages/Auth"
import ClinicalCases from "./app/pages/ClinicalCases"
import ClinicalCaseVideo from "./app/pages/ClinicalCaseVideo"
import ClinicalCaseIntro from "./app/pages/ClinicalCaseIntro"
import ClinicalCaseFinal from "./app/pages/ClinicalCaseFinal"
import Scenarios from "./app/pages/Scenarios"
import Scenario from "./app/pages/Scenario"
import Ranking from "./app/pages/Ranking"
import RankingGeneral from "./app/pages/RankingGeneral"
import FMC from "./app/pages/FMC"
import SatisfactionTest from "./app/pages/SatisfactionTest"
import FMCQuestions from "./app/pages/FMCQuestions"
import FMCQuestionsSolution from "./app/pages/FMCQuestionsSolution"
import { Redirect } from 'react-router-dom'
import Home from "./app/pages/Home"
import { authActions } from './app/redux/actions/authActions'
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { getCredentials, refreshTokens } from "./api/auth"
import ForgotPassword from "./app/pages/ForgotPassword";
import ResetPassword from "./app/pages/ResetPassword";
import Contact from "./app/pages/Contact"
import { useRouter } from './hooks/useRouter'
import RegisterSuccessful from './app/pages/RegisterSuccessful'

export function Routes() {
  const isLogged = useSelector((store) => store.authenticationWebMistuiery?.loggedIn, shallowEqual)
  const dispatch = useDispatch()

  const { location } = useRouter()
  // console.log(location)
  // const [loading, setLoading] = useState(false)

  // Checking wether we have credentials in cookies before going to login
  useEffect(() => {
    function fetchMyCredentials() {
      // setLoading(true)
      setTimeout(async () => {
        try {
          const response = await getCredentials()
          if (response)
            dispatch(authActions.loginActionSuccess(response?.data))
          // setLoading(false)
        } catch (error) {
          // alertError({ error, customMessage: 'Cannot get credentials, please login.' })
          dispatch(authActions.logoutAction())
        }
        // setLoading(false)
      }, 1000)
    }
    fetchMyCredentials()
  }, [dispatch])

  // Refresh interval every 15 minutes if logged
  useEffect(() => {
    if (isLogged) {
      const interval = setInterval(async () => {
        try {
          await refreshTokens()
        } catch (error) {
          dispatch(authActions.logoutAction())
        }
      }, 900000)
      return () => clearInterval(interval)
    }
  }, [dispatch, isLogged])

  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/contraseña-olvidada" exact component={ForgotPassword} />
      <Route path="/recuperar-contraseña/:id" exact component={ResetPassword} />
      <Route path="/consultas" exact component={Contact}/>
      <Route path="/registro-completado" component={RegisterSuccessful} />
      {!isLogged ? (
        <>
          <Route path="/identificacion" /* exact */ component={Auth} />
          {(location?.pathname !== '/identificacion') && <Redirect to="/identificacion" />}
        </>
      ) : (
        <>
          <Redirect from="/identificacion" to="/casos" />
          {(location?.pathname === '/identificacion') && <Redirect to="/casos" />}
          <Route path="/casos" exact component={ClinicalCases} />
          <Route path="/ranking-general" exact component={RankingGeneral} />
          <Route path="/casos/:id" exact component={ClinicalCaseVideo} />
          <Route path="/casos/:id/intro" exact component={ClinicalCaseIntro} />
          <Route path="/casos/:id/escenarios" exact component={Scenarios} />
          <Route path="/casos/:id/escenarios/:scenarioId" exact component={Scenario} />
          <Route path="/casos/:id/final" exact component={ClinicalCaseFinal} />
          <Route path="/casos/:id/ranking" exact component={Ranking} />
          <Route path="/casos/:id/formacion" exact component={FMC} />
          <Route path="/casos/:id/test-valoracion" exact component={SatisfactionTest} />
          <Route path="/casos/:id/test-acreditacion" exact component={FMCQuestions} />
          <Route path="/casos/:id/respuestas-test-acreditacion" exact component={FMCQuestionsSolution} />
        </>
      )}
      <Route
        path="/error"
        component={ErrorPage} />
      <Redirect to="/error" />
    </Switch>
  )
}
