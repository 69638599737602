import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { getScenarios } from "../../api/scenarios"
import GameNavbar from "../components/GameNavBar"
import { alertWarning } from "../../utils/logger"
import BlockedIcon from '../assets/blocked-icon.png'
import {shallowEqual/*, useDispatch*/, useSelector} from "react-redux";
//import {updateUserAcreditationProgress, updateUserCasesCompleted, updateUserProgress} from "../../api/user";
//import {authActions} from "../redux/actions/authActions";
import Lock from "../assets/lock.svg";

function getData(scenarios, progress) {
  let data = []
  for (let i = 0; i < scenarios.length; ++i) {
      const elem = {}
      elem.blocked = !progress || !progress.scenariosCompleted ? i !== 0 : progress.scenariosCompleted.length < i
      elem.name = scenarios[i].name
      elem.id = scenarios[i]._id
      data = data.concat(elem)
  }
  return data
}

export default function Scenarios() {
  const history = useHistory()
  // const location = useLocation()
  const id = useParams().id
  const [scenarios, setScenarios] = useState([])
  const user = useSelector((store) => store.authenticationWebMistuiery?.user, shallowEqual)
  const [seconds, setSeconds] = useState(0)
  const [reload, setReload] = useState(false)
  //const dispatch = useDispatch()

  useEffect(() => {
    if (!id) return
    getScenarios(id).then(res => {
      if (res.status === 200) {
        setReload(false)
        setScenarios(getData(res.data, user?.progress ? user.progress[id] : null))
      } else {
        console.log('Could not get any scenario')
      }
    })
  }, [id, user, reload])

  useEffect(() => {
    if (!user) return
    if (user.progress && user.progress[id]) {
      if (user.progress[id].seconds) setSeconds(user.progress[id].seconds)
    }
  }, [id, user])

  // TODO: ELIMINAR BOTON RESET
  /*function resetProgress() {
    if (!user) return
    let userProgress = {
      ...user.progress,
      [id]: {
        seconds: 0,
        lastGame: '',
        points: 0,
        scenariosCompleted: []
      }
    }
    updateUserProgress(user.email, userProgress).then(res => {
      if (res.status === 200) {
        console.log('User progress reset')
        const newUser = user
        newUser.progress = userProgress
        dispatch(authActions.refreshUserData(newUser))
        setReload(true)
      } else console.log('Could not update user progress')
    })
    if (user.clinicalCasesCompleted.includes(id)) {
      const body = user.clinicalCasesCompleted.filter(item => item !== id)
      updateUserCasesCompleted(user.email, body).then(res => {
        if (res.status === 200) {
          console.log('User clinical cases completed reset')
        } else console.log('Could not update user clinical cases completed')
      })
    }
    let userAcreditation = {
      ...user.acreditation,
      [id]: {
        satisfactionTestDone: false,
        acreditationTest: {
          attempts: 0,
          answers: [],
          done: false
        },
        downloadDiploma: false
      }
    }
    updateUserAcreditationProgress(user.email, userAcreditation).then(res => {
      if (res.status === 200) {
        console.log('User acreditation progress reset')
      } else console.log('Could not update user acreditation progress')
    })
  }*/

  return (
    <>
      <GameNavbar seconds={seconds} setSeconds={setSeconds}/>
      <div className="container">
        {/*<div className="row">
          <button onClick={() => resetProgress()}>BORRAR PROGRESO</button>
        </div>*/}
        <div className="row">
          {scenarios.map((item) => (
            <div key={item.id} className="col-12 col-md-4 d-flex justify-content-center my-4">
              {item.name !== 'Superpregunta' ?
                <div className={!item.blocked ? "circle green-circle" : "circle red-circle"} id={item.id}
                 onClick={() => !item.blocked ?
                   (user?.progress && user?.progress[id] && user.progress[id].scenariosCompleted && user.progress[id].scenariosCompleted.includes(item.id) ?
                     alertWarning({ icon: BlockedIcon, title: "¡No puedes entrar aquí!", html: "<p style='color: white'>Ya has completado esta sala.</p>"})
                     : history.push('/casos/' + id + '/escenarios/' + item.id))
                   : alertWarning({ icon: BlockedIcon, title: "¡Aún no puedes entrar aquí!", html: "<p style='color: white'>Revisa la sala anterior: seguro que se te han pasado por alto cosas importantes.</p>"})}>
                <div className={!item.blocked ? "inner-circle green-inner-circle" : "inner-circle red-inner-circle"}>
                  <p className="scenario-name">{item.name}</p>
                </div>
                {item.blocked && <img className="lock" src={Lock} alt={'lock-icon'} />}
              </div>
              : <div className="circle blue-circle" id={item.id}
                     onClick={() => !item.blocked ?
                       (user?.progress && user?.progress[id] && user.progress[id].scenariosCompleted && user.progress[id].scenariosCompleted.includes(item.id) ?
                         alertWarning({ icon: BlockedIcon, title: "¡No puedes entrar aquí!", html: "<p style='color: white'>Ya has completado esta sala.</p>"})
                         : history.push('/casos/' + id + '/escenarios/' + item.id))
                       : alertWarning({ icon: BlockedIcon, title: "¡Aún no puedes entrar aquí!", html: "<p style='color: white'>Revisa la sala anterior: seguro que se te han pasado por alto cosas importantes.</p>"})}>
                  <div className="inner-circle blue-inner-circle">
                      <pre style={item.blocked ? { opacity: "0.6"} : {}}>
                        <h1>?</h1>
                        <p className="scenario-name">{'Superpregunta'}</p>
                      </pre>
                  </div>
                  {item.blocked && <img className="lock" src={Lock} alt={'lock-icon'} />}
                </div>
              }
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
