import React from 'react'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import CloseIcon from "@material-ui/icons/Close";

const InfoDialog = (props) => {
  const { open, onClose } = props
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      onClose={() => { }}
      aria-labelledby="info-dialog">
      <div className="container-fluid pt-3 pb-5" style={{ textAlign: 'center' }}>
        <DialogTitle disableTypography className="dialog-title">
          <CloseIcon className="close-dialog-icon" onClick={() => onClose()} />
        </DialogTitle>
        <DialogContent>
          <h4 className="game-rules-title mb-4">¡Atención!</h4>
          <p>Ya has completado este caso. Puedes volver a realizarlo tantas veces como quieras, pero esto no afectará tu posición en el <i>ranking</i>.</p>
          <button className="own-button inside-button mt-4" onClick={() => onClose()}>
            CONTINUAR
          </button>
        </DialogContent>
      </div>
    </Dialog>
  )
}
export default InfoDialog
