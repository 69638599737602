import React from 'react'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import CloseIcon from "@material-ui/icons/Close";

const GoalsDialog = (props) => {
  const { open, setOpen, goals } = props
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="md"
      onClose={() => { }}
      aria-labelledby="goals-dialog">
      <div className="container-fluid pt-3 pb-5" style={{ textAlign: 'center' }}>
        <DialogTitle disableTypography className="dialog-title">
          <CloseIcon className="close-dialog-icon" onClick={() => setOpen(false)} />
        </DialogTitle>
        <DialogContent>
          <h4 className="game-rules-title mb-4 px-3" style={{ textAlign: 'left' }}>OBJETIVOS</h4>
          <div className='px-3' style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: goals }} />
          <button className="own-button inside-button mt-4" onClick={() => setOpen(false)}>
            CERRAR
          </button>
        </DialogContent>
      </div>
    </Dialog>
  )
}
export default GoalsDialog
