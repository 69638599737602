import axios from 'axios'
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
import { updateUserCasesCompleted } from '../api/user'
import { alertError } from './logger'

export const baseClient = () => {
  return axios.create()
}

export const authClient = () => {
  return axios.create({
    withCredentials: true
  })
}

const PRO = (process.env.NODE_ENV ===  'production' || process.env.NODE_ENV === 'pro')

export const API = PRO
? 'https://backend.mistuiery.com:4000/api'
: 'http://localhost:4001/api'

export const SERVER_URL = PRO
  ? 'https://backend.mistuiery.com:4000'
  : 'http://localhost:4001'

export const WEB_URL = PRO
  ? (window.location.href.includes('www') ? 'https://www.mistuiery.com' : 'https://mistuiery.com')
  : 'http://localhost:3000'

export const getFileType = (fileName) => {
  if (!fileName) return 'unknown'
  const ext = fileName.split('.').pop()
  switch (ext){
    case 'pdf':
      return 'file'
    case 'm4a':
      return 'audio'
    case 'mp3':
      return 'audio'
    case 'mpa':
      return 'audio'
    case 'wma':
      return 'audio'
    case 'aif':
      return 'audio'
    case 'cda':
      return 'audio'
    case 'mid':
      return 'audio'
    case 'midi':
      return 'audio'
    case 'ogg':
      return 'audio'
    case 'wav':
      return 'audio'
    case 'wpl':
      return 'audio'
    case 'jpg':
      return 'image'
    case 'jpeg':
      return 'image'
    case 'png':
      return 'image'
    case 'svg':
      return 'image'
    case 'gif':
      return 'image'
    case 'avi':
      return 'video'
    case 'm4v':
      return 'video'
    case 'mkw':
      return 'video'
    case 'mp4':
      return 'video'
    case 'wmv':
      return 'video'
    case 'mov':
      return 'video'
    default:
      return 'unknown'
  }
}

const getEditionAndDates = (ccase, date) => {
  switch (ccase) {
    case 1:
      // 23 de marzo del 2022 - 22 de abril del 2022
      if (new Date('2022-03-23') <= date && date <= new Date('2022-04-22')) return { edition: ' la primera edición del curso:', dateFrom: new Date('2022-03-23'), dateTo: new Date('2022-04-22') }
      // 23 de abril del 2022 - 22 de mayo del 2022
      if (new Date('2022-04-23') <= date && date <= new Date('2022-05-22')) return { edition: ' la segunda edición del curso:', dateFrom: new Date('2022-04-23'), dateTo: new Date('2022-05-22') }
      // 23 de mayo del 2022 - 22 de junio del 2022
      if (new Date('2022-05-23') <= date && date <= new Date('2022-06-22')) return { edition: ' la tercera edición del curso:', dateFrom: new Date('2022-05-23'), dateTo: new Date('2022-06-22') }
      // 23 de junio del 2022 - 22 de julio del 2022
      if (new Date('2022-06-23') <= date && date <= new Date('2022-07-22')) return { edition: ' la cuarta edición del curso:', dateFrom: new Date('2022-06-23'), dateTo: new Date('2022-07-22') }
      // 23 de julio del 2022 - 22 de agosto del 2022
      if (new Date('2022-07-23') <= date && date <= new Date('2022-08-22')) return { edition: ' la quinta edición del curso:', dateFrom: new Date('2022-07-23'), dateTo: new Date('2022-08-22') }
      // 23 de agosto del 2022 - 22 de septiembre del 2022
      if (new Date('2022-08-23') <= date && date <= new Date('2022-09-22')) return { edition: ' la sexta edición del curso:', dateFrom: new Date('2022-08-23'), dateTo: new Date('2022-09-22') }
      // 23 de septiembre del 2022 - 22 de octubre del 2022
      if (new Date('2022-09-23') <= date && date <= new Date('2022-10-22')) return { edition: ' la séptima edición del curso:', dateFrom: new Date('2022-09-23'), dateTo: new Date('2022-10-22') }
      // 23 de octubre del 2022 - 22 de noviembre del 2022
      if (new Date('2022-10-23') <= date && date <= new Date('2022-11-22')) return { edition: ' la octava edición del curso:', dateFrom: new Date('2022-10-23'), dateTo: new Date('2022-11-22') }
      // 23 de noviembre del 2022 - 22 de diciembre del 2022
      if (new Date('2022-11-23') <= date && date <= new Date('2022-12-22')) return { edition: ' la novena edición del curso:', dateFrom: new Date('2022-11-23'), dateTo: new Date('2022-12-22') }
      // 23 de diciembre del 2022 - 22 de enero del 2023
      if (new Date('2022-12-23') <= date && date <= new Date('2023-01-22')) return { edition: ' la décima edición del curso:', dateFrom: new Date('2022-12-22'), dateTo: new Date('2023-01-22') }
      // 23 de marzo del 2023 - 22 de abril del 2023
      if (new Date('2023-03-23') <= date && date <= new Date('2023-04-22')) return { edition: ' la undécima edición del curso:', dateFrom: new Date('2023-03-23'), dateTo: new Date('2023-04-22') }
      // 23 de abril del 2023 - 22 de mayo del 2023
      if (new Date('2023-04-23') <= date && date <= new Date('2023-05-22')) return { edition: ' la duodécima edición del curso:', dateFrom: new Date('2023-04-23'), dateTo: new Date('2023-05-22') }
      // 23 de mayo del 2023 - 22 de junio del 2023
      if (new Date('2023-05-23') <= date && date <= new Date('2023-06-22')) return { edition: ' la decimotercera edición del curso:', dateFrom: new Date('2023-05-23'), dateTo: new Date('2023-06-22') }
      // 23 de junio del 2023 - 22 de julio del 2023
      if (new Date('2023-06-23') <= date && date <= new Date('2023-07-22')) return { edition: ' la decimocuarta edición del curso:', dateFrom: new Date('2023-06-23'), dateTo: new Date('2023-07-22') }
      // 23 de julio del 2023 - 22 de agosto del 2023
      if (new Date('2023-07-23') <= date && date <= new Date('2023-08-22')) return { edition: ' la decimoquinta edición del curso:', dateFrom: new Date('2023-07-23'), dateTo: new Date('2023-08-22') }
      // 23 de agosto del 2023 - 22 de septiembre del 2023
      if (new Date('2023-08-23') <= date && date <= new Date('2023-09-22')) return { edition: ' la decimosexta edición del curso:', dateFrom: new Date('2023-08-23'), dateTo: new Date('2023-09-22') }
      // 23 de septiembre del 2023 - 22 de octubre del 2023
      if (new Date('2023-09-23') <= date && date <= new Date('2023-10-22')) return { edition: ' la decimoséptima edición del curso:', dateFrom: new Date('2023-09-23'), dateTo: new Date('2023-10-22') }
      // 23 de octubre del 2023 - 22 de noviembre del 2023
      if (new Date('2023-10-23') <= date && date <= new Date('2023-11-22')) return { edition: ' la decimoctava edición del curso:', dateFrom: new Date('2023-10-23'), dateTo: new Date('2023-11-22') }
      // 23 de noviembre del 2023 - 22 de diciembre del 2023
      if (new Date('2023-11-23') <= date && date <= new Date('2023-12-22')) return { edition: ' la decimonovena edición del curso:', dateFrom: new Date('2023-11-23'), dateTo: new Date('2023-12-22') }
      // 23 de diciembre del 2023 - 22 de enero del 2024
      if (new Date('2023-12-23') <= date && date <= new Date('2024-01-22')) return { edition: ' la vigésima edición del curso:', dateFrom: new Date('2023-12-23'), dateTo: new Date('2024-01-22') }
      return { edition: 'l curso:', dateFrom: '-----', dateTo: '-----'}
    case 2:
      // 15 de marzo del 2022 - 14 de enero del 2023
      if (new Date('2022-03-15') <= date && date <= new Date('2023-01-22')) return { edition: ' la primera edición del curso:', dateFrom: new Date('2022-03-15'), dateTo: new Date('2023-01-14') }
      // 15 de marzo del 2023 - 14 de abril del 2023
      if (new Date('2023-03-15') <= date && date <= new Date('2023-04-14')) return { edition: ' la segunda edición del curso:', dateFrom: new Date('2023-03-15'), dateTo: new Date('2023-04-14') }
      // 15 de abril del 2023 - 14 de mayo del 2023
      if (new Date('2023-04-15') <= date && date <= new Date('2023-05-14')) return { edition: ' la tercera edición del curso:', dateFrom: new Date('2023-04-15'), dateTo: new Date('2023-05-14') }
      // 15 de mayo del 2023 - 14 de junio del 2023
      if (new Date('2023-05-15') <= date && date <= new Date('2023-06-14')) return { edition: ' la cuarta edición del curso:', dateFrom: new Date('2023-05-15'), dateTo: new Date('2023-06-14') }
      // 15 de junio del 2023 - 14 de julio del 2023
      if (new Date('2023-06-15') <= date && date <= new Date('2023-07-14')) return { edition: ' la quinta edición del curso:', dateFrom: new Date('2023-06-15'), dateTo: new Date('2023-07-14') }
      // 15 de julio del 2022 - 14 de agosto del 2022
      if (new Date('2023-07-15') <= date && date <= new Date('2023-08-14')) return { edition: ' la sexta edición del curso:', dateFrom: new Date('2023-07-15'), dateTo: new Date('2023-08-14') }
      // 15 de agosto del 2023 - 14 de septiembre del 2023
      if (new Date('2023-08-15') <= date && date <= new Date('2023-09-14')) return { edition: ' la séptima edición del curso:', dateFrom: new Date('2023-08-15'), dateTo: new Date('2023-09-14') }
      // 15 de septiembre del 2023 - 14 de octubre del 2023
      if (new Date('2023-09-15') <= date && date <= new Date('2023-10-14')) return { edition: ' la octava edición del curso:', dateFrom: new Date('2023-09-15'), dateTo: new Date('2023-10-14') }
      // 15 de octubre del 2023 - 14 de noviembre del 2023
      if (new Date('2023-10-15') <= date && date <= new Date('2023-11-14')) return { edition: ' la novena edición del curso:', dateFrom: new Date('2023-10-15'), dateTo: new Date('2023-11-14') }
      // 15 de noviembre del 2023 - 14 de diciembre del 2023
      if (new Date('2023-11-15') <= date && date <= new Date('2023-12-14')) return { edition: ' la décima edición del curso:', dateFrom: new Date('2023-11-15'), dateTo: new Date('2023-12-14') }
      // 15 de diciembre del 2023 - 14 de enero del 2024
      if (new Date('2023-12-15') <= date && date <= new Date('2024-01-14')) return { edition: ' la undécima edición del curso:', dateFrom: new Date('2023-12-15'), dateTo: new Date('2024-01-14') }
      return { edition: 'l curso:', dateFrom: '-----', dateTo: '-----'}
    case 3:
      // 30 de septiembre de 2022 - 29 de octubre de 2022
      if (new Date('2022-09-30') <= date && date <= new Date('2022-10-29')) return { edition: ' la primera edición del curso:', dateFrom: new Date('2022-09-30'), dateTo: new Date('2022-10-29') }
      // 30 de octubre de 2022 - 29 de noviembre de 2022
      if (new Date('2022-10-30') <= date && date <= new Date('2022-11-29')) return { edition: ' la segunda edición del curso:', dateFrom: new Date('2022-10-30'), dateTo: new Date('2022-11-29') }
      // 30 de noviembre de 2022 - 29 de diciembre de 2022
      if (new Date('2022-11-30') <= date && date <= new Date('2022-12-29')) return { edition: ' la tercera edición del curso:', dateFrom: new Date('2022-11-30'), dateTo: new Date('2022-12-29') }
      // 30 de diciembre de 2022 - 29 de enero de 2023
      if (new Date('2022-12-30') <= date && date <= new Date('2023-01-29')) return { edition: ' la cuarta edición del curso:', dateFrom: new Date('2022-12-30'), dateTo: new Date('2023-01-29') }
      // 30 de enero de 2023 - 28 de febrero de 2023
      if (new Date('2023-01-30') <= date && date <= new Date('2023-02-28')) return { edition: ' la quinta edición del curso:', dateFrom: new Date('2023-01-30'), dateTo: new Date('2023-02-28') }
      // 1 de marzo del 2023 - 29 de marzo del 2023
      if (new Date('2023-03-01') <= date && date <= new Date('2023-03-29')) return { edition: ' la sexta edición del curso:', dateFrom: new Date('2023-03-01'), dateTo: new Date('2023-03-29') }
      // 30 de marzo de 2023 - 29 de abril de 2023
      if (new Date('2023-03-30') <= date && date <= new Date('2023-04-29')) return { edition: ' la séptima edición del curso:', dateFrom: new Date('2023-03-30'), dateTo: new Date('2023-04-29') }
      // 30 de abril de 2023 - 29 de mayo de 2023
      if (new Date('2023-04-30') <= date && date <= new Date('2023-05-29')) return { edition: ' la octava edición del curso:', dateFrom: new Date('2023-04-30'), dateTo: new Date('2023-05-29') }
      // 30 de mayo de 2023 - 29 de junio de 2023
      if (new Date('2023-05-30') <= date && date <= new Date('2023-06-29')) return { edition: ' la novena edición del curso:', dateFrom: new Date('2023-05-30'), dateTo: new Date('2023-06-29') }
      // 30 de junio de 2023 - 29 de julio de 2023
      if (new Date('2023-06-30') <= date && date <= new Date('2023-07-29')) return { edition: ' la décima edición del curso:', dateFrom: new Date('2023-06-30'), dateTo: new Date('2023-07-29') }
      // 30 de julio de 2023 - 29 de agosto de 2023
      if (new Date('2023-07-30') <= date && date <= new Date('2023-08-29')) return { edition: ' la undécima edición del curso:', dateFrom: new Date('2023-07-30'), dateTo: new Date('2023-08-29') }
      // 30 de agosto de 2023 - 29 de septiembre de 2023
      if (new Date('2023-08-30') <= date && date <= new Date('2023-09-29')) return { edition: ' la duodécima edición del curso:', dateFrom: new Date('2023-08-30'), dateTo: new Date('2023-09-29') }
      // 30 de septiembre de 2023 - 29 de octubre de 2023
      if (new Date('2023-09-30') <= date && date <= new Date('2023-10-29')) return { edition: ' la decimotercera edición del curso:', dateFrom: new Date('2023-09-30'), dateTo: new Date('2023-10-29') }
      // 30 de octubre de 2023 - 29 de noviembre de 2023
      if (new Date('2023-10-30') <= date && date <= new Date('2023-11-29')) return { edition: ' la decimocuarta edición del curso:', dateFrom: new Date('2023-10-30'), dateTo: new Date('2023-11-29') }
      // 30 de noviembre de 2023 - 29 de diciembre de 2023
      if (new Date('2023-11-30') <= date && date <= new Date('2023-12-29')) return { edition: ' la decimoquinta edición del curso:', dateFrom: new Date('2023-11-30'), dateTo: new Date('2023-12-29') }
      // 30 de diciembre de 2023 - 29 de enero de 2024
      if (new Date('2023-12-30') <= date && date <= new Date('2024-01-29')) return { edition: ' la decimosexta edición del curso:', dateFrom: new Date('2023-12-30'), dateTo: new Date('2024-01-29') }
      return { edition: 'l curso:', dateFrom: '-----', dateTo: '-----'}
    case 4:
      // 5 de octubre del 2022 - 4 de noviembre del 2022
      if (new Date('2022-10-05') <= date && date <= new Date('2022-11-04')) return { edition: ' la primera edición del curso:', dateFrom: new Date('2022-10-05'), dateTo: new Date('2022-11-04') }
      // 5 de noviembre del 2022 - 4 de diciembre del 2022
      if (new Date('2022-11-05') <= date && date <= new Date('2022-12-04')) return { edition: ' la segunda edición del curso:', dateFrom: new Date('2022-11-05'), dateTo: new Date('2022-12-04') }
      // 5 de diciembre del 2022 - 4 de enero del 2022
      if (new Date('2022-12-05') <= date && date <= new Date('2023-01-04')) return { edition: ' la tercera edición del curso:', dateFrom: new Date('2022-12-05'), dateTo: new Date('2023-01-04') }
      // 5 de enero del 2022 - 4 de febrero del 2023
      if (new Date('2023-01-05') <= date && date <= new Date('2023-02-04')) return { edition: ' la cuarta edición del curso:', dateFrom: new Date('2023-01-05'), dateTo: new Date('2023-02-04') }
      // 5 de febrero del 2023 - 4 de marzo del 2023
      if (new Date('2023-02-05') <= date && date <= new Date('2023-03-04')) return { edition: ' la quinta edición del curso:', dateFrom: new Date('2023-02-05'), dateTo: new Date('2023-03-04') }
      // 5 de marzo del 2023 - 4 de abril del 2023
      if (new Date('2023-03-05') <= date && date <= new Date('2023-04-04')) return { edition: ' la sexta edición del curso:', dateFrom: new Date('2023-03-05'), dateTo: new Date('2023-04-04') }
      // 5 de abril del 2023 - 4 de mayo del 2023
      if (new Date('2023-04-05') <= date && date <= new Date('2023-05-04')) return { edition: ' la séptima edición del curso:', dateFrom: new Date('2023-04-05'), dateTo: new Date('2023-05-04') }
      // 5 de mayo del 2023 - 4 de junio del 2023
      if (new Date('2023-05-05') <= date && date <= new Date('2023-06-04')) return { edition: ' la octava edición del curso:', dateFrom: new Date('2023-05-05'), dateTo: new Date('2023-06-04') }
      // 5 de junio del 2023 - 4 de julio del 2023
      if (new Date('2023-06-05') <= date && date <= new Date('2023-07-04')) return { edition: ' la novena edición del curso:', dateFrom: new Date('2023-06-05'), dateTo: new Date('2023-07-04') }
      // 5 de julio del 2023 - 4 de agosto del 2023
      if (new Date('2023-07-05') <= date && date <= new Date('2023-08-04')) return { edition: ' la décima edición del curso:', dateFrom: new Date('2023-07-05'), dateTo: new Date('2023-08-04') }
      // 5 de agosto del 2023 - 4 de septiembre del 2023
      if (new Date('2023-08-05') <= date && date <= new Date('2023-09-04')) return { edition: ' la undécima edición del curso:', dateFrom: new Date('2023-08-05'), dateTo: new Date('2023-09-04') }
      // 5 de septiembre del 2023 - 4 de octubre del 2023
      if (new Date('2023-09-05') <= date && date <= new Date('2023-10-04')) return { edition: ' la duodécima edición del curso:', dateFrom: new Date('2023-09-05'), dateTo: new Date('2023-10-04') }
      // 5 de octubre del 2023 - 4 de noviembre del 2023
      if (new Date('2023-10-05') <= date && date <= new Date('2023-11-04')) return { edition: ' la decimotercera edición del curso:', dateFrom: new Date('2023-10-05'), dateTo: new Date('2023-11-04') }
      // 5 de noviembre del 2023 - 4 de diciembre del 2023
      if (new Date('2023-11-05') <= date && date <= new Date('2023-12-04')) return { edition: ' la decimocuarta edición del curso:', dateFrom: new Date('2023-11-05'), dateTo: new Date('2023-12-04') }
      // 5 de diciembre del 2023 - 4 de enero del 2024
      if (new Date('2023-12-05') <= date && date <= new Date('2024-01-04')) return { edition: ' la decimoquinta edición del curso:', dateFrom: new Date('2023-12-05'), dateTo: new Date('2024-01-04') }
      // 5 de enero de 2024 - 4 de febrero del 2024
      if (new Date('2024-01-05') <= date && date <= new Date('2024-02-04')) return { edition: ' la decimosexta edición del curso:', dateFrom: new Date('2024-01-05'), dateTo: new Date('2024-02-04') }
      return { edition: 'l curso:', dateFrom: '-----', dateTo: '-----'}
    case 5:
      // 24 de mayo de 2023 - 23 de junio de 2023
      if (new Date('2022-05-24') <= date && date <= new Date('2022-06-23')) return { edition: ' la primera edición del curso:', dateFrom: new Date('2022-05-24'), dateTo: new Date('2022-06-23') }
      // 24 de junio de 2023 - 23 de julio de 2023
      if (new Date('2022-06-24') <= date && date <= new Date('2022-07-23')) return { edition: ' la segunda edición del curso:', dateFrom: new Date('2022-06-24'), dateTo: new Date('2022-07-23') }
      // 24 de julio de 2023 - 23 de agosto de 2023
      if (new Date('2022-07-24') <= date && date <= new Date('2023-08-23')) return { edition: ' la tercera edición del curso:', dateFrom: new Date('2022-07-24'), dateTo: new Date('2023-08-23') }
      // 24 de agosto de 2023 - 23 de septiembre de 2023
      if (new Date('2023-08-24') <= date && date <= new Date('2023-09-23')) return { edition: ' la cuarta edición del curso:', dateFrom: new Date('2023-08-24'), dateTo: new Date('2023-09-23') }
      // 24 de septiembre de 2023 - 23 de octubre de 2023
      if (new Date('2023-09-24') <= date && date <= new Date('2023-10-23')) return { edition: ' la quinta edición del curso:', dateFrom: new Date('2023-09-24'), dateTo: new Date('2023-10-23') }
      // 24 de octubre de 2023 - 23 de noviembre de 2023
      if (new Date('2023-10-24') <= date && date <= new Date('2023-11-23')) return { edition: ' la sexta edición del curso:', dateFrom: new Date('2023-10-24'), dateTo: new Date('2023-11-23') }
      // 24 de noviembre de 2023 - 23 de diciembre de 2023
      if (new Date('2023-11-24') <= date && date <= new Date('2023-12-23')) return { edition: ' la séptima edición del curso:', dateFrom: new Date('2023-11-24'), dateTo: new Date('2023-12-23') }
      return { edition: 'l curso:', dateFrom: '-----', dateTo: '-----'}
    default:
      return { edition: 'l curso:', dateFrom: '-----', dateTo: '-----'}
  }
}

export const modifyPdf = async (url, ccase, user, clinicalCaseId) => {
  if (!url) return
  if (!user || !ccase || !clinicalCaseId) alertError({ error: null, customMessage: 'Se ha producido un error inesperado al intentar descargar el certificado, por favor inténtelo de nuevo más tarde.' })
  const existingPdfBytes = await fetch(url).then((response) => response.arrayBuffer())
  const bytes = new Uint8Array(existingPdfBytes);
  const pdfDoc = await PDFDocument.load(bytes);
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];

  let dateCompleted = new Date()
  let userUpdated = null
  if (user?.clinicalCasesCompletedDates && user?.clinicalCasesCompletedDates[clinicalCaseId]) dateCompleted = new Date(user?.clinicalCasesCompletedDates[clinicalCaseId])
  else {
    const _completed =  user?.clinicalCasesCompleted 
      ? ( user.clinicalCasesCompleted.includes(clinicalCaseId) ? user.clinicalCasesCompleted : user.clinicalCasesCompleted.concat(clinicalCaseId))
      : [clinicalCaseId]
    const _dates =  user?.clinicalCasesCompletedDates 
      ? (user.clinicalCasesCompletedDates[clinicalCaseId] ? user.clinicalCasesCompletedDates : { ...user.clinicalCasesCompletedDates, [clinicalCaseId]: new Date() }) 
      : { [clinicalCaseId]: new Date() }
    const body = { clinicalCasesCompleted: _completed, clinicalCasesCompletedDates: _dates}
    const res = await updateUserCasesCompleted(user.email, body)
    if (res.status === 200) {
      userUpdated = user
      userUpdated.clinicalCasesCompleted = body.clinicalCasesCompleted
      userUpdated.clinicalCasesCompletedDates = body.clinicalCasesCompletedDates
      console.log('User clinical cases completed updated', body)
    } else console.log('Could not update user clinical cases completed')
  }
  
  // Get the width and height of the first page
  const { height } = firstPage.getSize();
  const username = user && user.name ? user.name + ' ' + user.firstSurname + ' ' + user.secondSurname : 'ERROR'
  firstPage.drawText(username, {
    x: 160,
    y: height - 4 * 59,
    size: 15,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  let { edition, dateFrom, dateTo } = getEditionAndDates(ccase, dateCompleted)
  firstPage.drawText(edition, {
    x: 525,
    y: height - 4 * 70,
    size: 11,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  dateFrom = dateFrom.toLocaleString('es', { day: 'numeric', month: 'long', year: 'numeric' });
  dateTo = dateTo.toLocaleString('es', { day: 'numeric', month: 'long', year: 'numeric' });

  firstPage.drawText(dateFrom.toString(), {
    x: 188,
    y: height - 4 * 90.5,
    size: 11,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(dateTo.toString(), {
    x: 395,
    y: height - 4 * 90.5,
    size: 11,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  const today = new Date()
  const month = today.toLocaleString('es', { month: 'long' });
  const day = today.getUTCDate();

  firstPage.drawText(day.toString(), {
    x: 440,
    y: height - 4 * 123,
    size: 10,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(month.toString(), {
    x: 500,
    y: height - 4 * 123,
    size: 10,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  const year = today.getFullYear()
  firstPage.drawText(year.toString().slice(-1), {
    x: 632,
    y: height - 4 * 124.2,
    size: 11,
    font: helveticaFont,
    color: rgb(0, 0, 0),
  });

  const pdfBytes = await pdfDoc.save();
  const docUrl = URL.createObjectURL(
    new Blob([pdfBytes], { type: "application/pdf" })
  );
  let a = document.createElement("a");
  a.href = docUrl;
  a.download = "Mistuiery_C" + ccase + '_' + username.replaceAll(' ', '_');
  a.click();
  return userUpdated;
};