import Swal from 'sweetalert2'
import '../App.css'

export const alertError = ({ error, customMessage }) => {
	const message =
		error?.response?.data?.message ||
		error?.response?.statusText ||
		error ||
		customMessage ||
		'Se ha producido un error al realizar la operación'

	const params = {
		icon: 'error',
		iconColor: '#EF160C',
		title: 'Ups...',
		text: message,
		confirmButtonText: 'ACEPTAR',
		confirmButtonColor: '#EF160C',
	}

	Swal.fire(params).then()
}

export const alertSuccess = ({ title, customMessage } = {}) => {
	const message =
		customMessage ||
		'Operación completada con éxito!'

	const params = {
		icon: 'success',
		title,
		text: message,
		confirmButtonText: 'Aceptar'
	}

	Swal.fire(params).then()
}

export const alertWarning = ({icon, title, html}) => {
	const params = {
		customClass: {
			title: 'swal-title-red',
			confirmButton: 'home-button'
		},
		imageUrl: icon,
		title,
		html,
		background: 'transparent',
		confirmButtonText: 'CONTINUAR',
		confirmButtonColor: '#EF160C',
	}

	Swal.fire(params).then()
}

export const alertForgotPassword = () => {
	const params = {
		title: 'Correo enviado',
		text: 'Por favor, revisa tu correo. Te hemos enviado un enlace para poder recuperar tu contraseña.',
		confirmButtonText: 'ACEPTAR',
		confirmButtonColor: '#EF160C',
	}

	Swal.fire(params).then()
}

export const alertMailSent = () => {
	const params = {
		title: 'Enviada!',
		text: 'Tu consulta se ha enviado correctamente.',
		confirmButtonText: 'ACEPTAR',
		confirmButtonColor: '#EF160C',
	}

	Swal.fire(params).then()
}

export const alertRegister = () => {
	const params = {
		title: 'Ya casi has terminado...',
		text: 'Por favor, revisa tu correo. Te hemos enviado un enlace para poder verificar el registro.',
		confirmButtonText: 'ACEPTAR',
		confirmButtonColor: '#EF160C',
	}

	Swal.fire(params).then()
}