import React from 'react'
import {Dialog, DialogContent, DialogTitle} from '@material-ui/core'
import CloseIcon from "@material-ui/icons/Close";

const ConditionsDialog = (props) => {
  const { open, setOpen } = props
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="lg"
      onClose={() => {}}
      aria-labelledby="conditions-dialog">
      <div className="container-fluid pt-3 pb-5" style={{ textAlign: 'center' }}>
        <DialogTitle disableTypography className="dialog-title">
          <CloseIcon className="close-dialog-icon" onClick={() => setOpen(null)}/>
        </DialogTitle>
        <DialogContent>
          <h4 className="game-rules-title mb-4">CONDICIONES DE USO</h4>
          <p><strong>Aceptación del usuario</strong></p>
          <p>Este documento (a partir de aquí llamado “Condiciones de uso”) tiene como objetivo regular el uso de la aplicación que Euromedice, Ediciones Médicas, S.L. pone a disposición del usuario.</p>
          <p>Los derechos de propiedad intelectual de la aplicación, su código fuente, las bases de datos y los distintos elementos que contiene son propiedad de Euromedice, Ediciones Médicas, S.L., a quien corresponde el ejercicio exclusivo de explotación de los derechos de la propia aplicación y, en especial, los de reproducción, distribución, comunicación pública, mantenimiento y transformación.</p>
          <p>La utilización de la aplicación por un tercero le atribuye la condición de usuario y supone la completa aceptación de este usuario de todas y cada una de las disposiciones que establecen estas Condiciones de uso.</p>
          <p><strong>Uso correcto de la aplicación</strong></p>
          <p>El usuario se compromete a utilizar la aplicación, los contenidos y los servicios de acuerdo con la Ley, estas Condiciones de uso, las buenas prácticas y el orden público. De la misma manera, el usuario se obliga a no utilizar la aplicación o los servicios que se presten a través de ella con finalidades o efectos ilícitos o contrarios al contenido de estas Condiciones de uso, que lesionen los intereses o derechos de terceros, o que de cualquier forma se pueda dañar, inutilizarla o deteriorar la propia aplicación o sus servicios o impedir un uso satisfactorio de la aplicación a otros usuarios.</p>
          <p>Asimismo, el usuario se compromete expresamente a no destruir, alterar, inutilizar o, de cualquier otra forma, dañar los datos, los programas o los documentos electrónicos y otros que se encuentren en la presente aplicación.</p>
          <p>El usuario se compromete a no obstaculizar el acceso de otros usuarios al servicio mediante el consumo masivo de los recursos informáticos a través de los cuales Euromedice, Ediciones Médicas, S.L. presta el servicio, así como a no realizar acciones que dañen, interrumpan o generen errores en los sistemas citados.</p>
          <p>El usuario se compromete a no introducir programas, virus, macroinstrucciones, miniaplicaciones, controles de cualquier tipo o cualquier otro dispositivo lógico o secuencia de caracteres que causen o puedan causar cualquier tipo de alteración en los sistemas informáticos de Euromedice, Ediciones Médicas, S.L. o de terceros o, en su caso, violar los derechos de la propiedad intelectual o industrial.</p>
          <p>El usuario se compromete a no hacer un mal uso de las informaciones, mensajes, gráficos, dibujos, archivos de sonido y/o imagen, fotografías, programas y, en general, cualquier clase de material accesible a través de la presente aplicación o de los servicios que ofrece.</p>
          <p>Euromedice, Ediciones Médicas, S.L., consiguientemente, declina toda responsabilidad derivada de los daños y perjuicios que pueda sufrir cualquier visitante de esta aplicación en sus recursos tecnológicos (informáticos o telemáticos) a consecuencia de la producción de cualquiera de las circunstancias o hechos mencionados en el párrafo anterior. Igualmente, Euromedice, Ediciones Médicas, S.L. no garantiza que la aplicación y el servidor estén libres de virus y no se hace responsable de los daños causados por el acceso a la aplicación o por la imposibilidad de acceder.</p>
          <p>Euromedice, Ediciones Médicas, S.L. no es responsable de la información que se puede obtener a través de enlaces a sistemas externos desde esta aplicación.</p>
          <p><strong>Propiedad intelectual e industrial</strong></p>
          <p>Esta aplicación y todos sus contenidos, incluidos los textos, los documentos, las fotografías, los dibujos, las representaciones gráficas, las bases de datos, los programas informáticos, así como los logotipos, las marcas, los nombres comerciales y otros signos distintivos son propiedad de Euromedice, Ediciones Médicas, S.L. o de terceros que le han autorizado para su uso. Todos los derechos están reservados.</p>
          <p><strong>Régimen de responsabilidad</strong></p>
          <p>Responsabilidad de Euromedice, Ediciones Médicas, S.L. por la utilización de la aplicación:</p>
          <p>El usuario es el único responsable de las infracciones en que pueda incurrir y de los perjuicios que pueda causar o causarse por la utilización de la presente aplicación. Por su parte, Euromedice, Ediciones Médicas, S.L. está exonerada de cualquier responsabilidad que se pudiese derivar por las acciones del usuario.</p>
          <p>El usuario será el único responsable para cualquier reclamación o acción legal, judicial o extrajudicial iniciada por terceras personas contra Euromedice, Ediciones Médicas, S.L. basada en la utilización de la aplicación por el usuario. En este caso, el usuario asumirá todos los gastos, los costes y las indemnizaciones que se reclamen a Euromedice, Ediciones Médicas, S.L. con motivo de reclamaciones o de acciones legales que le sean atribuidas.</p>
          <p>Responsabilidad de Euromedice, Ediciones Médicas, S.L. por el funcionamiento de la aplicación:</p>
          <p>Euromedice, Ediciones Médicas, S.L. declina cualquier responsabilidad que se pudiese derivar por transferencias, omisiones, interrupciones, intromisiones indeseables, deficiencias de telecomunicaciones, virus informáticos, averías telefónicas o desconexiones en el funcionamiento operativo del sistema electrónico, motivados por causas ajenas a Euromedice, Ediciones Médicas, S.L.</p>
          <p>Responsabilidad de Euromedice, Ediciones Médicas, S.L. por enlaces desde la aplicación:</p>
          <p>Euromedice, Ediciones Médicas, S.L. declina toda responsabilidad respecto de la información (contenidos) (perjuicios técnicos, morales o económicos) que haya fuera de la aplicación y al que se pueda acceder por link desde esta, ya que la función de los enlaces que aparecen es únicamente de informar sobre un tema concreto.</p>
          <p><strong>Compromiso de protección de datos y de cumplimiento de la LSSICE</strong></p>
          <p>En cumplimiento del RGPD (Reglamento General de Protección de datos), Euromedice, Ediciones Médicas, S.L. informa a los usuarios de que los datos de carácter personal que proporcionen al emplear los formularios o requerimientos de contrato se recogerán en ficheros responsabilidad de Euromedice, Ediciones Médicas, S.L. y serán tratados con la finalidad de gestionar la inscripción y el desarrollo en el programa funcional de la aplicación y permitir poder prestar e informar sobre los servicios o sobre los productos que Euromedice, Ediciones Médicas, S.L. ofrece. El hecho de rellenar un formulario de esta aplicación implica que el usuario reconoce que la información y los datos personales que nos indica son suyos, exactos y ciertos.</p>
          <p>El usuario registrado conserva en todo momento la posibilidad de ejercitar sus derechos de acceso, rectificación, oposición, supresión, tratamiento limitado, portabilidad u oposición al tratamiento de sus datos. Asimismo, y de conformidad con la Ley 34/ 2002, de 1 de julio, de servicios de la sociedad de la información y comercio electrónico, puede revocar en cualquier momento el consentimiento prestado a la recepción de comunicaciones comerciales. En caso de duda, así como para ejercitar los derechos mencionados, puede dirigirse a Euromedice, Ediciones Médicas, S.L. por correo electrónico, euromedice@euromedice.net, o por correo postal, Passeig de Gràcia 101; 1-3. 08008 Barcelona</p>
          <p><strong>Legislación aplicable y competencia judicial</strong></p>
          <p>Cualquier controversia surgida en la interpretación o la ejecución de estas Condiciones de uso se interpretará a partir de la legislación española. Además, Euromedice, Ediciones Médicas, S.L. a través de su representación legal y el usuario renuncian a cualquier otra jurisdicción y se someten a la de los juzgados y tribunales del domicilio del usuario para cualquier controversia que pudiese suceder. En el caso de que el usuario tenga su domicilio fuera de España, Euromedice, Ediciones Médicas, S.L. y el usuario se someterán a los juzgados y tribunales de la ciudad de Barcelona.</p>
          <button className="own-button inside-button mt-4" onClick={() => setOpen(null)}>
            CERRAR
          </button>
        </DialogContent>
      </div>
    </Dialog>
  )
}
export default ConditionsDialog
