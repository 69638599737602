import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import GameNavbar from "../components/GameNavBar"
import { getScenarioById } from "../../api/scenarios"
import { getGames, getGameTypes } from "../../api/games"
import GameContainer from "./cards/GameContainer"
import TrueOrFalseContainer from "./cards/TrueOrFalseContainer"
import IntermediateScreen from "./cards/IntermediateScreen"
import HTMLScreen from "./cards/HTMLScreen"
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {updateUserCasesCompleted, updateUserProgress} from "../../api/user";
import {authActions} from "../redux/actions/authActions";
import ScenarioUnblocked from "./cards/ScenarioUnblocked";
import {getClinicalCaseById} from "../../api/clinicalCases";
import {postScore} from "../../api/ranking";
import LinkConcepts from "./cards/LinkConcepts";
import LinkImagesAndConcepts from "./cards/LinkImagesAndConcepts";
import TrueOrFalseContainerFeedback from './cards/TrueOrFalseContainerFeedback'
import TrueOrFalseContainerAllAtOnce from './cards/TrueOrFalseContainerAllAtOnce'
import IntermediateScreenInteractive from "./cards/IntermediateScreenInteractive" 
import AlgorithmPuzzle from './cards/AlgorithmPuzzle'
import GameContainerComputerScreen from './cards/GameContainerComputerScreen'

function getData(games, types) {
  return games.map(item => {
    return {...item, type: types.find(type => type._id === item.type).name}
  })
}

export default function Scenario() {
  const scenarioId = useParams().scenarioId
  const clinicalCaseId = useParams().id
  const history = useHistory()
  const dispatch = useDispatch()
  const [nextScenario, setNextScenario] = useState(null)
  const [games, setGames] = useState([])
  const [actualGame, setActualGame] = useState({})
  const [progress, setProgress] = useState([])
  const user = useSelector((store) => store.authenticationWebMistuiery?.user, shallowEqual)
  const [seconds, setSeconds] = useState(0)
  const [stopTimer, setStopTimer] = useState(false)
  const [points, setPoints] = useState(0)
  const [unblockScenario, setUnblockScenario] = useState(false)

  useEffect(() => {
    getClinicalCaseById(clinicalCaseId).then(response => {
      if (response.status === 200) {
        const scenarios = response.data.scenarios
        const index = scenarios.findIndex(item => item === scenarioId)
        if (index !== -1) {
          getScenarioById(scenarios[index + 1]).then(resp => {
            if (resp.status === 200) {
              setNextScenario(resp.data)
            } else console.log('Could not get next scenario.')
          })
        }
      } else console.log('Could not get scenarios.')
    })
    getGameTypes().then(response => {
      if (response.status === 200) {
        getGames(scenarioId).then(res => {
          if (res.status === 200) {
            const allGames = getData(res.data, response.data)
            setGames(allGames)
            setActualGame(allGames[0])
          } else {
            console.log('Could not get games.')
          }
        })
      } else {
        console.log('Could not get game types.')
      }
    })
  }, [scenarioId, clinicalCaseId])


  useEffect(() => {
    if (!user) return
    if (user.progress && user.progress[clinicalCaseId]) {
      if (user.progress[clinicalCaseId].seconds) setSeconds(user.progress[clinicalCaseId].seconds)
    }
  }, [clinicalCaseId, user])

  useEffect(() => {
    if (!user || !games.length) return
    if (user.progress && user.progress[clinicalCaseId]) {
      if (user.progress[clinicalCaseId].lastGame && user.progress[clinicalCaseId].lastGame !== '') {
        const index = games.findIndex(item => item._id === user.progress[clinicalCaseId].lastGame)
        if (index !== -1) setActualGame(games[index + 1])
      }
    }
  }, [games, user])

  function handleNext(skip) {
    let _actualGame = actualGame
    const newProgress = progress.concat(_actualGame)
    if (skip && _actualGame.type === 'Respuesta infiltrada') {
      _actualGame = games[newProgress.length]
      newProgress.push(_actualGame)
    } /*else if (skip && _actualGame.type === 'Test verdadero o falso') {
      _actualGame = games[newProgress.length]
      newProgress.push(_actualGame)
      _actualGame = games[newProgress.length + 1]
      newProgress.push(_actualGame)
    }*/
    setProgress(newProgress)
    if (!user) return
    let userProgress = user?.progress ? user?.progress : {}
    if (!userProgress[clinicalCaseId]) {
      userProgress = {...userProgress, [clinicalCaseId]: {}}
    }
    userProgress[clinicalCaseId].seconds = seconds
    userProgress[clinicalCaseId].lastGame = _actualGame._id
    if (!isNaN(points) && points !== 0) {
      if (isNaN(userProgress[clinicalCaseId].points)) {
        userProgress[clinicalCaseId].points = 0
      }
      userProgress[clinicalCaseId].points = userProgress[clinicalCaseId].points + points
    }
    const index = games.findIndex(item => item._id === _actualGame?._id)
    if (!games[index + 1] || games.length <= newProgress.length) {
      if (nextScenario) {
        setUnblockScenario(true)
        userProgress[clinicalCaseId].scenariosCompleted = userProgress[clinicalCaseId].scenariosCompleted ?
        (userProgress[clinicalCaseId].scenariosCompleted.includes(scenarioId) ? userProgress[clinicalCaseId].scenariosCompleted : userProgress[clinicalCaseId].scenariosCompleted.concat(scenarioId))
        : [scenarioId]
      } else {
        setStopTimer(true)
        const _completed =  user?.clinicalCasesCompleted 
          ? ( user.clinicalCasesCompleted.includes(clinicalCaseId) ? user.clinicalCasesCompleted : user.clinicalCasesCompleted.concat(clinicalCaseId))
          : [clinicalCaseId]
        const _dates =  user?.clinicalCasesCompletedDates 
          ? (user.clinicalCasesCompletedDates[clinicalCaseId] ? user.clinicalCasesCompletedDates : { ...user.clinicalCasesCompletedDates, [clinicalCaseId]: new Date() }) 
          : { [clinicalCaseId]: new Date() }
        const body = { clinicalCasesCompleted: _completed, clinicalCasesCompletedDates: _dates}
        updateUserCasesCompleted(user.email, body).then(res => {
          if (res.status === 200) {
            const newUser = user
            newUser.clinicalCasesCompleted = body.clinicalCasesCompleted
            newUser.clinicalCasesCompletedDates = body.clinicalCasesCompletedDates
            dispatch(authActions.refreshUserData(newUser))
            console.log('User clinical cases completed updated', body)
          } else console.log('Could not update user clinical cases completed')
        })
        const newScore = {
          clinicalCase: clinicalCaseId,
          user: user._id,
          points: userProgress[clinicalCaseId].points,
          time: userProgress[clinicalCaseId].seconds
        }
        postScore(newScore).then(res => {
          if (res.status === 201 || res.status === 200) {
            history.push('/casos/' + clinicalCaseId + '/final')
          } else console.log('Could not add score.')
        })
        userProgress[clinicalCaseId].scenariosCompleted = []
      }
      userProgress[clinicalCaseId].lastGame = ''
    } else setActualGame(games[index + 1])
    updateUserProgress(user.email, userProgress).then(res => {
      if (res.status === 200) {
        const newUser = user
        newUser.progress = userProgress
        dispatch(authActions.refreshUserData(newUser))
        console.log('User progress updated')
        setPoints(0)
      } else console.log('Could not update user progress')
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [actualGame])

  return (
    <>
      {!actualGame.noHeader && <GameNavbar seconds={seconds} setSeconds={setSeconds} stopTimer={stopTimer} hideProgress={unblockScenario} home={unblockScenario} hideTimer={unblockScenario}/>}
      {unblockScenario ?
        <ScenarioUnblocked
        handleButton={() => {
          setUnblockScenario(false)
          history.replace('/casos/' + clinicalCaseId + '/escenarios')
        }}
        scenario={nextScenario}
      /> : (
        !actualGame ? <></>
        : actualGame.type === 'Pantalla intermedia' ?
          <IntermediateScreen
            game={actualGame}
            handleNext={handleNext}
          />
          : actualGame.type === 'Pantalla informativa interactiva' ?
          <IntermediateScreenInteractive
            game={actualGame}
            handleNext={handleNext}
          />
          : actualGame.type === 'Puzle de algoritmo' ?
          <AlgorithmPuzzle
            game={actualGame}
            handleNext={handleNext}
            points={points}
            setPoints={setPoints}
          />
          : actualGame.type === 'HTML' ?
          <HTMLScreen
            game={actualGame}
            handleNext={handleNext}
            clinicalCase={clinicalCaseId === '615ed2dbe033ca1285e0b89f' ? 1 : (clinicalCaseId === '61a0dc581137d44c9265b738' ? 2 : (clinicalCaseId ===  '61a499ba2aa0fd09eb3882cd' ? 3 : (clinicalCaseId === '61a49af22aa0fd09eb3882da' ? 4 : (clinicalCaseId === '61a49b592aa0fd09eb3882e2' ? 5 : 6))))}
          />
          : actualGame.type === 'Relacionar conceptos' ?
            <LinkConcepts
              game={actualGame}
              handleNext={handleNext}
              points={points}
              setPoints={setPoints}
            />
          : actualGame.type === 'Relacionar imágenes y conceptos' ?
            <LinkImagesAndConcepts
              game={actualGame}
              handleNext={handleNext}
              points={points}
              setPoints={setPoints}
            />
          : actualGame.type === 'Test verdadero o falso' ?
            (actualGame.computerScreen ? <TrueOrFalseContainer
              game={actualGame}
              handleNext={handleNext}
            />
            : actualGame.answerAllAtOnce ? 
              <TrueOrFalseContainerAllAtOnce
                game={actualGame}
                handleNext={handleNext}
                points={points}
                setPoints={setPoints}
              />
            : <TrueOrFalseContainerFeedback
              game={actualGame}
              handleNext={handleNext}
              points={points}
              setPoints={setPoints}
            />)
          : actualGame.computerScreen ? 
            <GameContainerComputerScreen
              game={actualGame}
              handleNext={handleNext}
              points={points}
              setPoints={setPoints}
            />          
          : <GameContainer
            game={actualGame}
            handleNext={handleNext}
            points={points}
            setPoints={setPoints}
            infiltrated={actualGame.type === 'Respuesta infiltrada'}
            twoOptions={actualGame.type === 'Pregunta verdadera o falsa'}
          />
        )}
    </>
  )
}