import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import GameNavbar from "../components/GameNavBar"
import { getQuestionsFMC } from "../../api/fmc";
import FMCQuestion from "./cards/FMCQuestion"
import FMCDialog from "../components/FMCDialog";
import { updateUserAcreditationProgress } from "../../api/user";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { getClinicalCaseById } from '../../api/clinicalCases';
import { authActions } from '../redux/actions/authActions';

export default function FMCQuestions() {
  const clinicalCaseId = useParams().id
  const history = useHistory()
  const [questions, setQuestions] = useState([])
  const [actualQuestion, setActualQuestion] = useState({})
  const [indexQuestion, setIndexQuestion] = useState(1)
  const [progress, setProgress] = useState([])
  const [selectedAnswers, setSelectedAnswers] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [certificate, setCertificate] = useState(null)
  const [result, setResult] = useState(0)
  const [attempt, setAttempt] = useState(0)
  const user = useSelector((store) => store.authenticationWebMistuiery?.user, shallowEqual)
  const dispatch = useDispatch()

  useEffect(() => {
    getQuestionsFMC(clinicalCaseId).then(res => {
      if (res.status === 200) {
        setQuestions(res.data)
        setActualQuestion(res.data[0])
      } else console.log('Could not get questions.')
    })
    getClinicalCaseById(clinicalCaseId).then(resp => {
      if (resp.status === 200) {
        setCertificate(resp.data?.certificateURL)
      } else console.log('Could not get clinical case.')
    })
  }, [clinicalCaseId])

  function handleNext() {
    const newProgress = progress.concat(actualQuestion)
    setProgress(newProgress)

    if (questions.length <= newProgress.length) {
      // TODO: CHECH IF SCORE >= 80% && DOWNLOAD DIPLOMA
      setOpenDialog(true)
      setAttempt(attempt + 1)
      const aux = selectedAnswers
      const answers = aux.filter(item => item.correct === true)
      const result = answers.length / questions.length
      if (user) {
        let userAcreditation = user?.acreditation ? user?.acreditation : {}
        if (!userAcreditation[clinicalCaseId]) {
          userAcreditation = { ...userAcreditation, [clinicalCaseId]: {} }
        }
        userAcreditation[clinicalCaseId].satisfactionTestDone = userAcreditation[clinicalCaseId].satisfactionTestDone ? userAcreditation[clinicalCaseId].satisfactionTestDone : false
        userAcreditation[clinicalCaseId].acreditationTest = {
          attempts: attempt + 1,
          answers: selectedAnswers,
          done: attempt + 1 >= 2 || result >= 0.8
        }
        userAcreditation[clinicalCaseId].downloadDiploma = result >= 0.8
        updateUserAcreditationProgress(user.email, userAcreditation).then(res => {
          if (res.status === 200) {
            console.log('User acreditation progress updated')
            const newUser = user
            newUser.acreditation = userAcreditation
            dispatch(authActions.refreshUserData(newUser))
          } else console.log('Could not update user acreditation progress')
        })
      }
      setResult(result)
      setActualQuestion(questions[0])
      setIndexQuestion(1)
      setSelectedAnswers([])
      setProgress([])
    }
    else {
      setActualQuestion(questions[newProgress.length])
      setIndexQuestion(indexQuestion + 1)
    }
  }

  useEffect(() => {
    if (!user || !user.acreditation || !user.acreditation[clinicalCaseId] || !user.acreditation[clinicalCaseId].acreditationTest) return
    if (isNaN(user.acreditation[clinicalCaseId].acreditationTest.attempts)) return
    setAttempt(user.acreditation[clinicalCaseId].acreditationTest.attempts)
  }, [user])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [actualQuestion])

  return (
    <>
      <GameNavbar stopTimer={true} hideTimer={true} hideProgress={true}/>
      <FMCQuestion
        question={actualQuestion}
        totalQuestions={questions?.length}
        indexQuestion={indexQuestion}
        handleNext={handleNext}
        selectedAnswers={selectedAnswers}
        setSelectedAnswers={setSelectedAnswers}
      />
      <FMCDialog
        open={openDialog}
        onClose={(redirect) => {
          if (redirect) history.push('/casos')
          setOpenDialog(false)
        }}
        certificate={certificate}
        clinicalCaseId={clinicalCaseId}
        user={user}
        result={result}
        attempt={attempt}
        seeCorrectAnswers={() => history.push('/casos/' + clinicalCaseId + '/respuestas-test-acreditacion')}
      />
    </>
  )
}