import { API, authClient } from '../../utils/index'

// Get all specialties
export const getSpecialties = () => {
    return authClient().get(`${API}/specialties`)
}

// Get specialty by id
export const getSpecialtyById = (id) => {
    return authClient().get(`${API}/specialties/${id}`)
}

// Update specialty
export const updateSpecialty = (id, specialty) => {
    return authClient().put(`${API}/specialties/${id}`, specialty)
}

// Save specialty
export const postSpecialty = (specialty) => {
    return authClient().post(`${API}/specialties`, specialty)
}

// Delete specialty
export const deleteSpecialty = (id) => {
    return authClient().delete(`${API}/specialties/${id}`)
}

