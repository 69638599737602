import React from 'react'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import CloseIcon from "@material-ui/icons/Close";

const HintDialog = (props) => {
  const { open, setOpen, onConfirm, hint } = props
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      onClose={() => { }}
      aria-labelledby="confirm-dialog">
      <div className="container-fluid pt-3 pb-5" style={{ textAlign: 'center' }}>
        <DialogTitle disableTypography className="dialog-title">
          <CloseIcon className="close-dialog-icon" onClick={() => setOpen(false)} />
        </DialogTitle>
        <DialogContent>
          {hint?.text ? <div dangerouslySetInnerHTML={{ __html: hint.text }} /> : <p>¿Estás seguro de que quieres acceptar la ayuda?</p>}
          <button className="own-button inside-button m-4" onClick={() => {
            setOpen(false)
            onConfirm()
          }}>
            SÍ
          </button>
          <button className="own-button inside-button m-4" onClick={() => {
            setOpen(false)
          }}>
            NO
          </button>
        </DialogContent>
      </div>
    </Dialog>
  )
}
export default HintDialog
