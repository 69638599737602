import React from 'react'
import {Dialog, DialogContent, DialogTitle} from '@material-ui/core'
import CloseIcon from "@material-ui/icons/Close";

const CookiesDialog = (props) => {
  const { open, setOpen } = props
  return (
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="lg"
        onClose={() => {}}
        aria-labelledby="cookies-dialog">
        <div className="container-fluid pt-3 pb-5" style={{ textAlign: 'center' }}>
          <DialogTitle disableTypography className="dialog-title">
            <CloseIcon className="close-dialog-icon" onClick={() => setOpen(null)}/>
          </DialogTitle>
          <DialogContent>
            <h4 className="game-rules-title mb-4">POLÍTICA DE COOKIES</h4>
            <p>Este sitio web, como la mayoría de los sitios en internet, utiliza cookies para mejorar y optimizar la experiencia del usuario. A continuación encontrarás información detallada sobre qué son las “cookies”, qué tipología utiliza este sitio web, cómo puedes desactivarlas en tu navegador y cómo bloquear específicamente la instalación de cookies de terceros.</p>
            <p><strong>¿Qué son las cookies?</strong></p>
            <p>Una cookie es un fichero que se descarga en su ordenador al acceder a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario.</p>
            <p><strong>¿Qué tipos de cookies utiliza esta página web?</strong></p>
            <p>Cookies de análisis: Son aquéllas que bien tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.</p>
            <p>Cookies publicitarias: Son aquéllas que, bien tratadas por nosotros o por terceros, nos permiten gestionar de la forma más eficaz posible la oferta de los espacios publicitarios que hay en la página web, adecuando el contenido del anuncio al contenido del servicio solicitado o al uso que realice de nuestra página web. Para ello podemos analizar sus hábitos de navegación en Internet y podemos mostrarle publicidad relacionada con su perfil de navegación.</p>
            <p>El uso de Cookies nos permite optimizar tu navegación, adaptando la información y los servicios ofrecidos a tus intereses, para proporcionarte una mejor experiencia siempre que nos visites.</p>
            <p>Cookie UTMC: El código javascript actual que utiliza Google Analytics no requiere esta cookie. Antes esta cookie se utilizaba junto con la cookie _utmb para determinar si transcurridos más de 30 minutos en la misma página, procedía o no establecer una nueva sesión para el usuario. Esta cookie se sigue escribiendo para asegurar la compatibilidad con las webs donde está instalado el antiguo código de seguimiento urchin.js.</p>
            <p><strong>¿Cómo puedo eliminar o desactivar las cookies?</strong></p>
            <p>Al navegar y continuar en nuestro Sitio web estará consintiendo el uso de las cookies en las condiciones contenidas en la presente Política de cookies. Proporcionamos acceso a esta Política de cookies en el momento del registro con el objetivo de que el usuario esté informado, y sin perjuicio de que éste pueda ejercer su derecho a bloquear, eliminar y rechazar el uso de cookies en todo momento.</p>
            <p>En cualquier caso le informamos de que, dado que las cookies no son necesarias para el uso de nuestro Sitio web, puede bloquearlas o deshabilitarlas activando la configuración de su navegador, que le permite rechazar la instalación de todas las cookies o de algunas de ellas.</p>
            <p>La práctica mayoría de los navegadores permiten advertir de la presencia de cookies o rechazarlas automáticamente. Si las rechaza Si las rechaza podrá seguir usando nuestro Sitio web, aunque el uso de algunos de sus servicios podrá ser limitado y por tanto su experiencia en nuestro Sitio web menos satisfactoria.</p>
            <p>Puede permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador.</p>
            <p>Internet Explorer: Herramientas -> Opciones de Internet -> Privacidad -> Configuración.</p>
            <p>Para más información, puede consultar el soporte de Microsoft o la Ayuda del navegador.</p>
            <p>Firefox: Herramientas -> Opciones -> Privacidad -> Historial -> Configuración Personalizada.</p>
            <p>Para más información, puede consultar el soporte de Mozilla o la Ayuda del navegador.</p>
            <button className="own-button inside-button mt-4" onClick={() => setOpen(null)}>
              CERRAR
            </button>
          </DialogContent>
        </div>
      </Dialog>
  )
}
export default CookiesDialog
