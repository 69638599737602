import React, { useState } from 'react'
import { Dialog } from '@material-ui/core'
import { getFileType, SERVER_URL } from "../../utils";
import audioIcon from "../assets/audio-icon.png"
import audioPause from "../assets/pause-audio.png"
import notValidAnswer from "../assets/not-valid-answer.png"
import correctAnswerIcon from "../assets/correct-answer.png"
import bg from '../assets/background.png'
import pdfSupport from 'browser-pdf-support'
import downloadFile from '../assets/download-icon.png'

export default function AlgorithmAnswerDialog(props) {
  const { open, onClose, points, allCorrect, file } = props
  const fileType = file && file !== '' ? getFileType(file) : null
  const [audioPlaying, setAudioPlaying] = useState(false)

  const playAudio = () => {
    if (!audioPlaying) {
      setAudioPlaying(true)
      document.getElementById('audio').play();
    } else {
      setAudioPlaying(false)
      document.getElementById('audio').pause();
    }
  }

  document.getElementById('audio')?.addEventListener('ended', function (e) {
    e.preventDefault();
    setAudioPlaying(false)
    document.getElementById('audio').currentTime = 0
  });

  return (
    <Dialog
      style={{ background: `url(${bg}) no-repeat`, backgroundSize: 'cover' }}
      open={open}
      fullWidth={true}
      maxWidth="md"
      onClose={() => { }}
      aria-labelledby="answer-dialog">
      <div className="container-fluid text-center px-5 py-4">
        <div className="row">
          <div className="col-12">
            {allCorrect ? (
              <>
                <img className="top-icon mb-2"
                  src={correctAnswerIcon}
                  alt={'top-icon'} />
                <h3 style={{ color: '#49d489' }}>¡Perfecto!</h3>
                <h4 style={{ color: '#004e7d' }} className="my-3">Este es el algoritmo correcto.</h4>
              </>
            ) : (
              <>
                <img className="top-icon mb-4"
                  src={notValidAnswer}
                  alt={'top-icon'} />
                <h4 style={{ color: '#004e7d' }} className="my-3">No es del todo correcto.</h4>
                <h4 style={{ color: '#004e7d' }} className="my-3">Este es el algoritmo completo:</h4>
              </>
            )}
            {file && file !== '' ? (
              fileType === 'image' ? (
                <img
                  className="img-fluid"
                  src={SERVER_URL + '/' + file}
                  alt={'intermediate-screen'} />
              ) : fileType === 'video' ? (
                <div className="embed-responsive embed-responsive-21by9">
                  <video className="embed-responsive-item" allowFullScreen controls>
                    <source src={SERVER_URL + '/' + file} type="video/mp4" />
                  </video>
                </div>
              ) : fileType === 'audio' ? (
                <>
                  <audio id="audio" src={SERVER_URL + '/' + file} />
                  <a href="#" id="play">
                    <img
                      className="top-icon"
                      onClick={playAudio}
                      src={audioPlaying ? audioPause : audioIcon}
                      alt={'audio-icon'} />
                  </a>
                  <p className="mt-2" style={{ color: '#004e7d' }}>Haz clic en el icono para escuchar el audio.</p>
                </>
              ) : fileType === 'file' ? (
                <>
                {pdfSupport() ? <embed className="game-file" src={SERVER_URL + '/' + file} /> :               
                  <a target="_blank" rel="noreferrer" href={SERVER_URL + '/' + file}><img
                  src={downloadFile}
                  alt={'file-icon'} /></a>}
                </>
              ) : <></>
            ) : <></>}
            <h4 style={{ color: '#000' }} className="my-3">{'Has ganado ' + points + ' puntos.'}</h4>
            <div className="row py-3 text-center">
              <div className="col-12">
                <button className="own-button inside-button" onClick={() => onClose()}>
                  CONTINUAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
