import {API, authClient} from '../../utils/index'

// Get general ranking
export const getGeneralRanking = () => {
    return authClient().get(`${API}/ranking`)
}

// Get ranking of specified clinical case
export const getRanking = (id) => {
    return authClient().get(`${API}/ranking/${id}`)
}

// Add new score to ranking
export const postScore = async (score) => {
    return authClient().post(`${API}/ranking`, score)
}

