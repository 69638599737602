import React, {useEffect, useState} from 'react'
import NavBar from "../components/NavBar"
import {alertError, alertMailSent} from "../../utils/logger";
import { getClinicalCases } from '../../api/clinicalCases';
import { newContact } from '../../api/contact'
import {shallowEqual, useSelector} from "react-redux";

export default function Contact() {
  const [loading, setLoading] = useState(false)
  const [clinicalCases, setClinicalCases] = useState([])
  const user = useSelector((store) => store.authenticationWebMistuiery?.user, shallowEqual) 

  useEffect(() => {
    getClinicalCases().then(res => {
      if (res.status === 200) setClinicalCases(res.data)
      else console.log('No se han podido obtener los casos clínicos')
    })
  }, [])

  function handleSubmit(event) {
    event.preventDefault()
    setLoading(true)
    const formData = new FormData(event.target)
    const jsonData = Object.fromEntries(formData.entries())
    if (jsonData.clinicalCase === 'default') {
      alertError({ error: null, customMessage: 'Por favor, selecciona un caso clínico.' })
      setLoading(false)
      return
    }
    if (jsonData.message === '' || jsonData.message === ' ') {
      alertError({ error: null, customMessage: 'Por favor, escriba su consulta.' })
      setLoading(false)
      return
    }
    newContact(jsonData).then((res) => {
      if (res.status === 200) {
        alertMailSent()
        setLoading(false)
      } else {
        setLoading(false)
        alertError({
          error: null,
          customMessage: 'No se ha podido enviar la consulta.'
        })
      }
    })
  }


  return (
    <>
      <NavBar/>
      <div className="container my-5">
        <div className="row">
          <div className="col-12">
            <h3 className="text-white">TIENES UNA CONSULTA?</h3>
            <form onSubmit={handleSubmit} id="contact-form">
            <div className="mb-2">
                <input type="text" className="form-control input-form" id="Name" name="name" placeholder={'Nombre*'} required defaultValue={user && user.name ? user.name + ' ' + user.firstSurname + ' ' + user.secondSurname : ''} />
              </div>
              <div className="mb-2">
                <input type="email" className="form-control input-form" id="Email" name="email" placeholder={'E-mail*'} required defaultValue={user && user.email ? user.email : ''}/>
              </div>
              <div className="mb-2">
                <select className="form-select input-form input-select" name="clinicalCase" required>
                  <option value={'default'}>Caso clínico (Selecciona un caso clínico)</option>
                  {clinicalCases.filter(item => item.active).map((option) => (
                    <option value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-2">
                <textarea id="Message" name="message" rows="8" style={{width: '100%'}}></textarea>
              </div>
              <div className="text-end">
                <button type="submit" className="btn btn-primary mt-4 auth-button" disabled={loading}>
                  <span className="align-middle">Enviar</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
